import { createAsyncThunk, unwrapResult } from '@reduxjs/toolkit';

export const createNonConcurrentAsyncThunk = (
  typePrefix,
  payloadCreator,
  options,
) => {
  const pending = {
    payloadPromise: undefined,
    actionAbort: undefined,
  };

  const wrappedPayloadCreator = (arg, thunkAPI) => {
    const run = () => {
      if (thunkAPI.signal.aborted) {
        return thunkAPI.rejectWithValue({ name: 'AbortError', message: 'Aborted' });
      }
      const promise = Promise.resolve(payloadCreator(arg, thunkAPI)).finally(() => {
        if (pending.payloadPromise === promise) {
          pending.payloadPromise = null;
        }
      });
      
      pending.payloadPromise = promise;

      return pending.payloadPromise;
    };
    
    if (pending.payloadPromise) {
      pending.payloadPromise = pending.payloadPromise.then(run, run);

      return pending.payloadPromise;
    }

    return run();
  };
  
  const internalThunk = createAsyncThunk(`${typePrefix}-protected`, wrappedPayloadCreator);
  
  return createAsyncThunk(
    typePrefix,
    async (arg, thunkAPI) => {
      if (pending.actionAbort) {
        pending.actionAbort();
      }

      const internalPromise = thunkAPI.dispatch(internalThunk(arg));
      const { abort } = internalPromise;

      pending.actionAbort = abort;

      return internalPromise
        .then(unwrapResult)
        .finally(() => {
          if (pending.actionAbort === abort) {
            pending.actionAbort = null;
          }
        });
    },
    options
  );
};

export default createNonConcurrentAsyncThunk;
