import Router from 'next/router';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../services/axios';
import { acceptMilestoneAsync, rejectMilestoneAsync } from '../milestoneInfo/thunks';
import { LIST_SIZE_PER_PAGE } from '../../constants/values';
import { formatStringDate } from '../../utils/helpers';
import { newValidateErrors, validateMilestone, validateErrors } from '../../utils/validators';
import { parseAttachedFiles } from '../../utils/sendFiles';

const date = formatStringDate(new Date());

export const initialState = {
  isLoading: true,
  isSubmit: false,
  error: undefined,
  data: [],
  meta: {},
  query: {
    filters: {
      name: '',
      id: [],
      client_id: [],
      client_product_id: [],
      budgeting: '',
    },
    page: null,
    per_page: LIST_SIZE_PER_PAGE
  },
  modalFields: {
    name: '',
    startDate: date,
    dateFinishPlan: null,
    dateFinishReal: null,
    milestonePeriod: null,
    type: null,
    billingPeriod: null,
    comments: '',
    isCopy: false,
  },
  addMilestoneModal: {
    modalId: 'addMilestoneModal',
    isModalOpen: false,
    isStartDateOpen: false,
    isFinishDateOpen: false,
    isFinishDateRealOpen: false,
    isBudgetingOpen: false,
    isBillingPeriodOpen: false,
    isTypeOpen: false,
    milestoneId: null,
  },
  deleteMilestoneModal: {
    modalId: 'deleteMilestoneModal',
    isModalOpen: false,
    milestone: null,
  },
  fieldsErrors: {},
};

export const fetchMilestonesAsync = createAsyncThunk(
  'milestones/fetchMilestones',
  async (arg, { getState, rejectWithValue }) => {
    try {
      const { query } = getState().milestones;
      const {
        data,
        status
      } = await axios.get('/product_milestones', { params: query });

      if (status === 200) {
        const newData = data.data
          .map((e) => ({
            ...e,
            client_acceptance_files: e.client_acceptance_files
              .map((file) => parseAttachedFiles(file))
          }));

        return {
          ...data,
          data: newData
        };
      }

      return rejectWithValue(data.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const addOrEditMilestoneAsync = createAsyncThunk(
  'milestones/addOrEditMilestone',
  async (arg, { getState, rejectWithValue, dispatch }) => {
    try {
      const {
        modalFields: {
          name,
          startDate,
          dateFinishPlan,
          dateFinishReal,
          milestonePeriod,
          type,
          billingPeriod,
          comments,
          isCopy,
        },
        addMilestoneModal
      } = getState().milestones;

      const errors = validateErrors({
        name,
        startDate,
        dateFinishPlan,
        dateFinishReal,
        milestonePeriod,
        type,
        billingPeriod,
        comments
      }, validateMilestone);

      if (errors) {
        return rejectWithValue({ isFormValidation: true, errors });
      }

      let method = 'POST';
      let url = '/product_milestones';

      if (addMilestoneModal.milestoneId && !isCopy) {
        method = 'PUT';
        url += `/${addMilestoneModal.milestoneId}`;
      }

      const { query } = Router;
      const body = {
        name,
        comments,
        type,
        preferred_billing_period: billingPeriod,
        start_date: formatStringDate(new Date(startDate), 'y-MM-dd'),
        planned_completion_date: dateFinishPlan
          ? formatStringDate(new Date(dateFinishPlan), 'y-MM-dd') : null,
        real_completion_date: dateFinishReal
          ? formatStringDate(new Date(dateFinishReal), 'y-MM-dd') : null,
        client_product_id: query.product,
        client_id: query.id,
      };

      if (isCopy) {
        body.copy_product_milestone_id = addMilestoneModal.milestoneId;
      }

      const { data, status } = await axios({ method, url, data: body });

      if (status === 201 || status === 200) {
        if (isCopy) {
          await Router.push({
            pathname: '/clients/[id]/products/[product]/milestones/[milestone]',
            query: {
              id: data.product_milestone.client_id,
              product: data.product_milestone.client_product_id,
              milestone: data.product_milestone.id,
            }
          });
        } else {
          dispatch(fetchMilestonesAsync());
        }

        return data;
      }

      return '';
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteMilestoneAsync = createAsyncThunk(
  'milestones/deleteDocuments',
  async ({ milestoneId }, { dispatch, rejectWithValue }) => {
    try {
      const { data, status } = await axios.delete(`/product_milestones/${milestoneId}`);

      if (status === 204) {
        dispatch(fetchMilestonesAsync());
        return data;
      }

      return rejectWithValue(data.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const milestonesSlice = createSlice({
  name: 'milestones',
  initialState,
  reducers: {
    resetState: () => initialState,
    setQueryFilter: (state, action) => {
      state.query.filters = { ...state.query.filters, ...action.payload };
    },
    setQuery: (state, action) => {
      state.query = { ...state.query, ...action.payload };
    },
    setData: (state, action) => {
      return ({ ...state, ...action.payload });
    },
    setModalData: (state, action) => {
      state[action.payload.key] = { ...state[action.payload.key], ...action.payload.data };
    },
    setModalFields: (state, action) => {
      state.modalFields = { ...state.modalFields, ...action.payload };
      state.fieldsErrors = {
        ...state.fieldsErrors,
        ...newValidateErrors({ ...action.payload }, validateMilestone)
      };
    },
    resetModalFields: (state) => {
      state.modalFields = initialState.modalFields;
      state.fieldsErrors = {};
      state.addMilestoneModal = initialState.addMilestoneModal;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchMilestonesAsync.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchMilestonesAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload.data;
        state.meta = action.payload.meta;
      })
      .addCase(fetchMilestonesAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
    builder
      .addCase(addOrEditMilestoneAsync.pending, (state) => {
        state.isSubmit = true;
        state.error = undefined;
        state.fieldsErrors = {};
      })
      .addCase(addOrEditMilestoneAsync.fulfilled, (state) => {
        state.isSubmit = false;
        state.addMilestoneModal = initialState.addMilestoneModal;
        state.modalFields = initialState.modalFields;
        state.fieldsErrors = {};
      })
      .addCase(addOrEditMilestoneAsync.rejected, (state, action) => {
        if (action.payload?.isFormValidation) {
          state.fieldsErrors = action.payload.errors;
        } else {
          state.error = action.payload;
        }
        state.isSubmit = false;
      });
    builder
      .addCase(deleteMilestoneAsync.pending, (state) => {
        state.isSubmit = true;
      })
      .addCase(deleteMilestoneAsync.fulfilled, (state) => {
        state.isSubmit = false;
        state.deleteMilestoneModal = initialState.deleteMilestoneModal;
      })
      .addCase(deleteMilestoneAsync.rejected, (state, action) => {
        state.isSubmit = false;
        state.error = action.payload;
      });
    builder
      .addCase(acceptMilestoneAsync.pending, (state) => {
        state.isSubmit = true;
      })
      .addCase(acceptMilestoneAsync.fulfilled, (state, action) => {
        const { data } = state;
        const milestone = action.payload;

        state.data = data.map((item) => (item.id === milestone.id ? milestone : item));
        state.isSubmit = false;
      })
      .addCase(acceptMilestoneAsync.rejected, (state, action) => {
        state.error = action.payload;
        state.isSubmit = false;
      });
    builder
      .addCase(rejectMilestoneAsync.pending, (state) => {
        state.isSubmit = true;
      })
      .addCase(rejectMilestoneAsync.fulfilled, (state, action) => {
        const { data } = state;
        const milestone = action.payload;

        state.data = data.map((item) => (item.id === milestone.id ? milestone : item));
        state.isSubmit = false;
      })
      .addCase(rejectMilestoneAsync.rejected, (state, action) => {
        state.error = action.payload;
      });
  },
});

export const {
  setQueryFilter,
  setQuery,
  setModalData,
  setModalFields,
  setData,
  resetModalFields,
} = milestonesSlice.actions;

export default milestonesSlice.reducer;
