export const TECHNOLOGIES_TREE_SET_DATA = 'TECHNOLOGIES_TREE_SET_DATA';
export const TECHNOLOGIES_TREE_SET_MODAL_DATA = 'TECHNOLOGIES_TREE_SET_MODAL_DATA';
export const TECHNOLOGIES_TREE_SET_MODAL_ERROR = 'SYSTEMS_TYPE_SET_MODAL_ERROR';
export const TECHNOLOGIES_TREE_RESET_MODAL = 'TECHNOLOGIES_TREE_RESET_MODAL';
export const TECHNOLOGIES_TREE_SET_SUBMIT = 'TECHNOLOGIES_TREE_SET_SUBMIT';
export const TECHNOLOGIES_TREE_SET_FILTER_DATA = 'TECHNOLOGIES_TREE_SET_FILTER_DATA';
export const TECHNOLOGIES_TREE_SET_EXPANDED_KEYS = 'TECHNOLOGIES_TREE_SET_EXPANDED_KEYS';
export const TECHNOLOGIES_TREE_SET_DRAG_DATA = 'TECHNOLOGIES_TREE_SET_DRAG_DATA';
export const TECHNOLOGIES_TREE_SET_USERS = 'TECHNOLOGIES_TREE_SET_USERS';
export const TECHNOLOGIES_TREE_SET_TECHNOLOGY = 'TECHNOLOGIES_TREE_SET_TECHNOLOGY';
export const TECHNOLOGIES_TREE_SET_NEW_TECHNOLOGY = 'TECHNOLOGIES_TREE_SET_NEW_TECHNOLOGY';
export const TECHNOLOGY_USERS_SET_INACTIVE = 'TECHNOLOGY_USERS_SET_INACTIVE';
