import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../services/axios';
import { LIST_SIZE_PER_PAGE } from '../../constants/values';
import { STATUS_APPROVED, STATUS_REJECTED } from '../../constants/status';
import { ALERT_ERROR, ALERT_SUCCESS } from '../../constants/types';
import { setSubmitType } from '../../utils/vacation';
import { sortByCondition } from '../../utils/arrayHelpers';
import { addNotification } from '../notifications/slice';

export const initialState = {
  isLoading: true,
  requests: undefined,
  isRequestSubmit: [],
  numberManagersToShow: 0,
  errors: {},
  moreProps: {
    isOpen: false,
    id: '',
    style: {}
  },
  queryParams: {
    with: ['confirmations.user', 'user', 'user.manager'],
    filters: {
      show_inactive: 1,
      confirmations: { user_id: [] },
    },
    per_page: LIST_SIZE_PER_PAGE
  },
  tableHeadData: {
    showAll: false,
    employeeInputValue: '',
  },
};

export const fetchRequests = createAsyncThunk(
  'vacationRequests/requests',
  async ({ profileId, page }, { rejectWithValue, getState }) => {
    const { queryParams, tableHeadData: { employeeInputValue, showAll } } = getState().vacationRequests;
    const showAllParams = showAll ? {} : { confirmations: { user_id: [profileId] } };
    const params = {
      ...queryParams,
      page,
      filters: {
        ...queryParams.filters,
        ...showAllParams,
        user: { search: employeeInputValue },
      }
    };

    try {
      const { data, status } = await axios.get('/vacations', { params });

      const condition = (request) => !request.trashed && request.permissions.confirm;
      const filteredRequests = sortByCondition(data?.data, (e) => condition(e));

      return status === 200 ? {
        showAll,
        profileId,
        data: { ...data, data: filteredRequests },
      } : rejectWithValue(data.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const requestVacationAction = createAsyncThunk(
  'vacationRequests/action',
  async (arg, { rejectWithValue, getState }) => {
    const { tableHeadData: { showAll } } = getState().vacationRequests;
    const {
      requestId,
      profileId,
      confirm,
      isForce
    } = arg;

    try {
      const { data, status } = await axios
        .post(
          `/vacations/${requestId}/${isForce ? 'force_confirm' : 'confirm'}`,
          { confirm }
        );

      return status === 200 ? {
        ...data,
        showAll,
        profileId,
      } : rejectWithValue(data.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const requestVacationFromEmail = createAsyncThunk(
  'requestVacationFromEmail/action',
  async (arg, { rejectWithValue, dispatch }) => {
    const {
      requestId,
      confirm,
      navigateTo = () => {},
    } = arg;

    try {
      const { data, status } = await axios
        .post(
          `/vacations/${requestId}/confirm`,
          { confirm }
        );

      dispatch(
        addNotification({
          message: `Vacation ${confirm ? STATUS_APPROVED : STATUS_REJECTED}`,
          type: confirm ? ALERT_SUCCESS : ALERT_ERROR,
        })
      );

      navigateTo();

      return status === 200 ? data : rejectWithValue(data.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const vacationRequests = createSlice({
  name: 'vacationRequests',
  initialState,
  reducers: {
    setNumberManagersToShow: (state, action) => {
      state.numberManagersToShow = action.payload;
    },
    toggleMore: (state, action) => {
      state.moreProps = action.payload.style ? action.payload : initialState.moreProps;
    },
    toggleShowAll: (state, { payload }) => {
      state.tableHeadData.showAll = payload;
    },
    setFilterValue: (state, { payload }) => {
      state.tableHeadData.employeeInputValue = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchRequests.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchRequests.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.requests = payload.data;
      })
      .addCase(fetchRequests.rejected, (state, action) => {
        state.isLoading = false;
        state.errors = action.payload;
      });
    builder
      .addCase(requestVacationAction.pending, (state, action) => {
        state.isRequestSubmit.push({
          id: action.meta.arg.requestId,
          type: setSubmitType(action.meta.arg.isForce, action.meta.arg.confirm)
        });
      })
      .addCase(requestVacationAction.rejected, (state, action) => {
        state.isRequestSubmit = state.isRequestSubmit.filter(({ id }) => id !== action.meta.arg.requestId);
        state.errors = action.payload;
      })
      .addCase(requestVacationAction.fulfilled, (state, action) => {
        state.isRequestSubmit = state.isRequestSubmit.filter(({ id }) => id !== action.payload.id);
        state.requests.data = state.requests.data.map((item) => {
          if (item.id === action.payload.id) {
            return action.payload;
          }
          return item;
        });
      });
    builder
      .addCase(requestVacationFromEmail.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(requestVacationFromEmail.rejected, (state, action) => {
        state.errors = action.payload;
      });
  },
});

export const {
  setNumberManagersToShow,
  toggleShowAll,
  setFilterValue,
} = vacationRequests.actions;

export default vacationRequests.reducer;
