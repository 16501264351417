export const SKILLS_SET_ABORT = 'SKILLS_SET_ABORT';
export const SKILLS_FETCH_USER_TECHNOLOGIES = 'SKILLS_FETCH_USER_TECHNOLOGIES';
export const SKILLS_FETCH_TECHNOLOGIES = 'SKILLS_FETCH_TECHNOLOGIES';
export const SKILLS_SET_QUERY_PARAMS = 'SKILLS_SET_QUERY_PARAMS';
export const SKILLS_SET_MODAL_DATA = 'SKILLS_SET_MODAL_DATA';
export const SKILLS_SET_MODAL_FIELDS = 'SKILLS_SET_MODAL_FIELDS';
export const SKILLS_SET_LOG_ERROR = 'SKILLS_SET_LOG_ERROR';
export const SKILLS_LIST_SET_SUBMIT = 'SKILLS_LIST_SET_SUBMIT';
export const SKILLS_RESET_MODAL = 'SKILLS_RESET_MODAL';
export const SKILLS_SET_LOADING = 'SKILLS_SET_LOADING';
export const SKILLS_SET_PAGE = 'SKILLS_SET_PAGE';
export const SKILLS_SET_TOGGLE_TABLE_MORE = 'SKILLS_SET_TOGGLE_TABLE_MORE';
export const EMPLOYEE_SKILLS_SET_TABLE_HEAD_INPUT = 'EMPLOYEE_SKILLS_SET_TABLE_HEAD_INPUT';
export const SET_IS_SUBMIT_IDS = 'SET_IS_SUBMIT_IDS';
export const SKILLS_SET_DISABLED = 'SKILLS_SET_DISABLED';
