import { useMemo } from 'react';

const usePlatform = () => useMemo(() => {
  if (typeof window !== 'undefined') {
    const { userAgent } = navigator;

    return {
      isMac: userAgent.includes('Mac'),
      isLinux: userAgent.includes('Linux'),
      isWindows: userAgent.includes('Windows'),
    };
  }

  return {
    isMac: false,
    isLinux: false,
    isWindows: false,
  };
}, []);

export default usePlatform;
