const getArticleId = (url) => {
  let articleId = null;

  if (url.length > 0) {
    articleId = url.substring(url.indexOf('/doc/') + 5);
  }

  return articleId;
};

export default getArticleId;
