import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../services/axios';
import { NO_CONTENT, OK } from '../../constants/httpStatusCodes';
import {
  NOTIFICATIONS_CHIPS,
  NOTIFICATIONS_TABS,
  NOTIFICATIONS_TYPES,
  NOTIFICATIONS_TYPES_LINK
} from '../../constants/notifications';
import httpRequestMethods from '../../constants/httpRequestMethods';
import { SORT_BY_CREATED_AT } from '../../constants/common';
import { LIST_SIZE_PER_PAGE_25 } from '../../constants/values';

export const initialState = {
  meta: {},
  notificationsData: [],
  isLoading: false,
  isLoadingScroll: false,
  queryParams: {
    filters: {
      only_unread: true,
      group: '',
    },
    sort: `-${SORT_BY_CREATED_AT}`,
    page: 1,
    per_page: LIST_SIZE_PER_PAGE_25,
  },
  hasMore: true,
  modalProps: {
    modalId: 'notificationsModal',
    isModalOpen: false,
  },
  isUnreadNotification: false,
  activeTab: NOTIFICATIONS_TABS.NEW,
  activeChip: NOTIFICATIONS_CHIPS.ALL,
  notificationIds: [],
};

export const fetchNotificationsAsync = createAsyncThunk(
  'notificationsModal/fetchNotificationsAsync',
  async (arg, { rejectWithValue, getState }) => {
    const { notificationsModal: { queryParams } } = getState();

    try {
      const { data, status } = await axios.get('/notifications', { params: queryParams });

      if (status !== OK) {
        return rejectWithValue(data.message);
      }

      const getLink = (type = null, userId = null, projectId = null) => {
        let link = NOTIFICATIONS_TYPES_LINK?.[type] || '';

        if (type === NOTIFICATIONS_TYPES.MANAGER_PROJECT_DOCUMENTATION_REMINDER) {
          link = projectId ? `/projects/${projectId}/documentation` : '/projects';
        }

        if (type === NOTIFICATIONS_TYPES.PROJECT_STATUS_INACTIVE) {
          link = projectId ? `/projects/${projectId}` : '/projects';
        }

        if (type === NOTIFICATIONS_TYPES.USER_CHANGED_MENTORS) {
          link = userId ? `/employees/${userId}` : '/employees';
        }

        return link;
      };

      const parseData = data.data.reduce((acc, item) => {
        let { message } = item.data;

        let title = message;
        const dotIndex = message.indexOf('.');

        if (dotIndex !== -1) {
          title = message.slice(0, dotIndex + 1);
          message = message.slice(dotIndex + 1).trim();
        } else {
          message = null;
        }

        const internalLink = getLink(item?.type, item.data?.user_id, item.data?.project_id);
        const externalLink = item?.data?.link || '';

        acc.push({
          ...item,
          data: {
            ...item.data,
            title,
            message,
            internalLink,
            externalLink
          }
        });

        return acc;
      }, []);

      return { ...data, data: parseData };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const markAsReadNotificationAsync = createAsyncThunk(
  'notificationsModal/markAsReadNotificationAsync',
  async (arg, { dispatch, rejectWithValue, getState }) => {
    const { notificationsModal: { notificationIds, notificationsData } } = getState();

    try {
      const url = '/notifications/mark_read';
      const method = httpRequestMethods.POST;

      const { data, status } = await axios({
        url,
        method,
        data: { ids: notificationIds }
      });

      let newData = notificationsData;

      if (status === OK || status === NO_CONTENT) {
        if (notificationIds.length === 1) {
          newData = newData.reduce((acc, item) => {
            if (item.id === notificationIds[0]) {
              acc.push({ ...item, is_read: true });
            } else {
              acc.push(item);
            }
            return acc;
          }, []);
        } else {
          dispatch(fetchNotificationsAsync());
        }

        return newData;
      }

      return rejectWithValue(data.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const notificationsModalSlice = createSlice({
  name: 'notificationsModal',
  initialState,
  reducers: {
    resetModal: (state) => {
      state.modalProps = initialState.modalProps;
    },
    setQueryParams: (state, action) => {
      state.queryParams = { ...state.queryParams, ...action.payload };
    },
    setQueryFilters: (state, action) => {
      state.queryParams.filters = {
        ...state.queryParams.filters,
        ...action.payload
      };
      state.queryParams.page = 1;
    },
    setPage: (state, action) => {
      state.queryParams.page = action.payload;
    },
    setModalData: (state, action) => {
      state.modalProps = { ...state.modalProps, ...action.payload };
    },
    setTabs: (state, action) => {
      state.activeTab = action.payload;
      state.queryParams.page = 1;
      state.queryParams.filters = {
        only_unread: action.payload === NOTIFICATIONS_TABS.NEW,
        only_read: action.payload === NOTIFICATIONS_TABS.READ,
      };
    },
    setChips: (state, action) => {
      state.activeChip = action.payload;
    },
    setIsUnreadNotification: (state, action) => {
      state.isUnreadNotification = action.payload;
    },
    setNotificationIds: (state, action) => {
      state.notificationIds = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchNotificationsAsync.pending, (state) => {
        if (state.queryParams.page > 1) {
          state.isLoadingScroll = true;
        } else {
          state.isLoading = true;
        }
      })
      .addCase(fetchNotificationsAsync.fulfilled, (state, action) => {
        state.notificationsData = state.queryParams.page === 1
          ? action.payload.data
          : [...state.notificationsData, ...action.payload.data];
        state.hasMore = action.payload.meta.current_page < action.payload.meta.last_page;
        state.meta = action.payload.meta;
        state.isLoading = false;
        state.isLoadingScroll = false;
        state.isUnreadNotification = action.payload.data.length > 0 && state.queryParams.filters.only_unread;
      })
      .addCase(fetchNotificationsAsync.rejected, (state, action) => {
        state.error = action.payload;
        state.isLoading = false;
        state.isLoadingScroll = false;
      });
    builder
      .addCase(markAsReadNotificationAsync.fulfilled, (state, action) => {
        state.notificationsData = action.payload;
      })
      .addCase(markAsReadNotificationAsync.rejected, (state, action) => {
        state.error = action.payload;
      });
  },
});

export const {
  resetModal,
  setModalData,
  setTabs,
  setChips,
  setPage,
  setQueryParams,
  setQueryFilters,
  setIsUnreadNotification,
  setNotificationIds
} = notificationsModalSlice.actions;

export default notificationsModalSlice.reducer;
