import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import Router from 'next/router';
import axios from '../../services/axios';
import {
  newValidateErrors,
  validateTechnologiesTreeModal,
  validateCVGeneratorForm,
  validateCVGeneratorProjectForm,
  validateErrors,
} from '../../utils/validators';
import { urlToFile } from '../../utils/helpers';
import { sendFiles } from '../../utils/sendFiles';
import { sortBy } from '../../utils/arrayHelpers';
import { MAX_FETCH_DATA_PER_PAGE } from '../../constants/values';
import { CREATED, OK } from '../../constants/httpStatusCodes';

export const initialState = {
  previousRenderValue: null,
  isFormHidden: false,
  isLoading: true,
  isSaving: false,
  isSubmit: false,
  isProjectLoading: false,
  isQualitiesLoading: false,
  savingError: undefined,
  error: undefined,
  cvsPdfData: null,
  query: {
    filters: { project_name: '', type: '' },
    page: null,
  },
  formInputData: {
    employeeRole: '',
    colorTemplate: '',
    summary: '',
    experience: '',
    education: '',
    githubLink: ''
  },
  employeesProps: {
    employee: null,
    employeeId: null,
  },
  technologiesProps: {
    technologyName: '',
    technologyList: [],
    currentTechnology: null,
    isModalOpen: false,
  },
  technologiesModal: {
    modalId: 'technologiesModal',
    isModalOpen: false,
    technologyId: null,
    name: '',
    parent: {},
    parenId: '',
    link: '',
    description: '',
    isHaveParent: false,
    uploadedImage: null,
  },
  technologyCropModal: {
    modalId: 'technologyCropModal',
    isModalOpen: false,
  },
  languagesProps: {
    languageList: [],
    languageDropdownList: ['ukrainian', 'english', 'german', 'french'],
  },
  projectsProps: {
    projectName: '',
    projectList: [],
    projectEmptyDescriptionIds: [],
    isSelectProject: false,
    employeeProjects: [],
    projectNamesNDA: []
  },
  qualitiesProps: {
    id: '',
    type: '',
    name: '',
    qualities: [],
  },
  strengthsProps: {
    strengthList: [],
  },
  formErrors: {},
  projectErrors: {}
};

export const addEmptyDescriptionIds = (projects) => {
  return projects.filter((item) => !item.short_description).map((item) => item.id);
};

export const saveCVGeneratorAsync = createAsyncThunk(
  'systemsCVGenerator/saveCVGeneratorAsync',
  async (newData, { rejectWithValue, getState }) => {
    try {
      const { query: { user_id: userId } } = Router;
      const {
        formInputData: {
          employeeRole, colorTemplate, summary, experience, education, githubLink
        },
        technologiesProps, projectsProps, strengthsProps, languagesProps
      } = getState().systemsCVGenerator;

      const body = newData || {
        role: employeeRole,
        template: colorTemplate,
        summary,
        experience,
        education,
        languages: languagesProps.languageList,
        technologies: technologiesProps.technologyList.map((item) => item.id),
        projects: projectsProps.projectList,
        strengths: strengthsProps.strengthList.map((item) => item.id),
        github: githubLink,
      };

      const errors = validateErrors(
        body,
        validateCVGeneratorForm,
        { projectNamesNDA: projectsProps.projectNamesNDA }
      );

      if (errors) {
        return rejectWithValue({ isFormValidation: true, errors });
      }

      const { data, status } = await axios.post(
        `${process.env.NEXT_PUBLIC_API_BACKEND_URL}/users/${userId}/cvs`,
        { data: body }
      );

      return (status === CREATED || status === OK) ? data : rejectWithValue(data.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchUserTechnologiesAsync = createAsyncThunk(
  'systemsCVGenerator/fetchUserTechnologiesAsync',
  async (arg, { rejectWithValue }) => {
    const { query: { user_id: userId } } = Router;
    const params = {
      filters: {
        user: { id: [userId] },
        technology: { show_active: true },
      },
      with: ['technology'],
    };

    try {
      const { data, status } = await axios.get('/user_technologies', { params });

      const technologyList = data.data.reduce((acc, { technology }) => {
        return technology ? [...acc, { name: technology.name, id: technology.id }] : acc;
      }, []);

      return status === 200 ? technologyList : rejectWithValue(data.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchEmployeeProjectsAsync = createAsyncThunk(
  'systemsCVGenerator/fetchEmployeeProjectsAsync',
  async ({ isSelect = false, isNewCV = false }, { rejectWithValue, getState }) => {
    try {
      const { query: { user_id: userId } } = Router;
      const {
        systemsCVGenerator: {
          query,
          projectsProps: { projectList, projectEmptyDescriptionIds }
        }
      } = getState();

      const params = { ...query, per_page: MAX_FETCH_DATA_PER_PAGE };

      const { data: { data }, status } = await axios.get(
        `${process.env.NEXT_PUBLIC_API_BACKEND_URL}/users/${userId}/projects`,
        { params }
      );

      let newData = {};

      if (isSelect) {
        const projects = data
          .filter((item) => projectList.every(({ id }) => id !== item.project.id))
          .reduce((acc, item) => [...acc, { ...item.project, roles: item.roles }], []);

        newData = {
          employeeProjects: sortBy(projects, 'name'),
          projectEmptyDescriptionIds: [...projectEmptyDescriptionIds, ...addEmptyDescriptionIds(projects || [])]
        };
      } else {
        const projectNamesNDA = data.filter((obj) => obj.project.under_nda).map((item) => item.project.name);

        newData = { projectNamesNDA };

        if (isNewCV) {
          const newProjects = data.length > 3 ? data.slice(0, 3) : data;

          const projects = newProjects.map((item) => {
            return ({
              id: item.project.id,
              name: item.project.name,
              short_description: item.project.short_description,
              role: item.roles?.join(', '),
              technologies: item.project_technologies?.map((technology) => technology.name).join(', '),
              link: '',
              underNda: item.project?.under_nda
            });
          });

          newData = {
            ...newData,
            projectList: [...projectList, ...projects],
            projectEmptyDescriptionIds: [...projectEmptyDescriptionIds, ...addEmptyDescriptionIds(projects || [])]
          };
        }
      }

      return status === 200 ? newData : rejectWithValue(data.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchCVGeneratorAsync = createAsyncThunk(
  'systemsCVGenerator/fetchCVGeneratorAsync',
  async ({ isManagement }, { rejectWithValue, getState, dispatch }) => {
    try {
      const {
        projectsProps: { projectEmptyDescriptionIds },
        languagesProps: { languageDropdownList },
      } = getState().systemsCVGenerator;
      const { query } = Router;
      const { data, status } = await axios.get(`${process.env.NEXT_PUBLIC_API_BACKEND_URL}/users/${query.user_id}/cvs`);

      const {
        data: dataCV, technologies, projects, user, strengths
      } = data;

      let projectList = projects || [];
      let technologyList = technologies || [];

      const projectsRes = await dispatch(fetchEmployeeProjectsAsync(projects?.length === 0));

      if ((technologies?.length === 0 || projects?.length === 0) && isManagement) {
        let technologiesRes;

        if (technologies?.length === 0) {
          technologiesRes = await dispatch(fetchUserTechnologiesAsync());
        }

        if (technologiesRes?.payload?.length > 0) {
          technologyList = technologiesRes.payload;
        }
        if (projectsRes?.payload?.projectList?.length > 0) {
          projectList = projectsRes.payload.projectList;
        }
      }

      let languageList = user.languages;

      if (dataCV?.languages) {
        languageList = dataCV.languages;
      } else {
        languageList = Object.keys(languageList).map((key) => ({
          id: key,
          name: key,
          level: languageList[key]
        }));
      }

      const languageDropdown = languageDropdownList.filter((item) => languageList.every(({ id }) => id !== item));

      if (dataCV?.projects?.length > 0) {
        projectList = dataCV.projects.reduce((acc, item) => {
          const findProject = projects.find((project) => item.id === project.id);

          const updateProject = {
            ...item,
            underNda: findProject.under_nda,
            name: findProject.under_nda ? 'Under NDA' : findProject.name,
          };

          if (findProject.under_nda) {
            delete updateProject.link;
          }

          acc.push(updateProject);

          return acc;
        }, []);
      }

      const sharedData = {
        role: dataCV?.role || '',
        template: dataCV?.template || 'gray',
        summary: dataCV?.summary || '',
        experience: dataCV?.experience || '',
        education: dataCV?.education || '',
        github: dataCV?.github || '',
      };

      const newData = {
        ...sharedData,
        user: user || {},
        languageList: languageList || [],
        languageDropdownList: languageDropdown,
        strengthList: strengths || [],
        technologyList,
        projectList,
        projectEmptyDescriptionIds: [
          ...projectEmptyDescriptionIds,
          ...addEmptyDescriptionIds(dataCV?.projects || [])
        ]
      };

      const saveCVData = {
        ...sharedData,
        languages: languageList || [],
        technologies: technologyList.map((item) => item.id),
        projects: projectList,
        strengths: strengths.map((item) => item.id) || [],
      };

      if ((technologies?.length === 0 || projects?.length === 0) && isManagement) {
        if (projectList.length > 0 || technologyList.length > 0) {
          await dispatch(saveCVGeneratorAsync(saveCVData));
        }
      }

      return status === 200 ? newData : rejectWithValue(data.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const createTechnologyAsync = createAsyncThunk(
  'systemsCVGenerator/createTechnologyAsync',
  async (arg, { rejectWithValue, getState }) => {
    try {
      const {
        technologiesModal: {
          name, parent, link, description, uploadedImage
        }
      } = getState().systemsCVGenerator;

      const body = {
        name,
        parent_id: parent?.id || null,
        link,
        description,
      };

      if (uploadedImage) {
        const file = await urlToFile(uploadedImage?.src, uploadedImage?.name);
        const fileId = await sendFiles([file]);
        body.file_id = fileId?.data[0] || null;
      }

      const { data, status } = await axios.post(
        `${process.env.NEXT_PUBLIC_API_BACKEND_URL}/technologies`,
        body
      );

      return (status === 200 || status === 201) ? data : rejectWithValue(data.message);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const editTechnologyAsync = createAsyncThunk(
  'systemsCVGenerator/editTechnologyAsync',
  async (arg, { rejectWithValue, getState }) => {
    try {
      const {
        technologiesModal: {
          technologyId, name, uploadedImage
        }
      } = getState().systemsCVGenerator;

      const body = { name, file_id: null };

      if (uploadedImage) {
        const file = await urlToFile(uploadedImage?.src, uploadedImage?.name);
        const fileId = await sendFiles([file]);
        body.file_id = fileId?.data[0];
      }

      const { data, status } = await axios.put(
        `${process.env.NEXT_PUBLIC_API_BACKEND_URL}/technologies/${technologyId}`,
        body
      );

      return (status === 200 || status === 201) ? data : rejectWithValue(data.message);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const addCVQualitiesAsync = createAsyncThunk(
  'systemsCVGenerator/addCVQualitiesAsync',
  async (arg, { rejectWithValue, getState }) => {
    try {
      const { qualitiesProps } = getState().systemsCVGenerator;

      const { data, status } = await axios.post(
        `${process.env.NEXT_PUBLIC_API_BACKEND_URL}/cv_qualities`,
        qualitiesProps
      );

      return (status === 200 || status === 201) ? data : rejectWithValue(data.message);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const editCVQualitiesAsync = createAsyncThunk(
  'systemsCVGenerator/editCVQualitiesAsync',
  async (arg, { rejectWithValue, getState }) => {
    try {
      const {
        qualitiesProps,
        strengthsProps: { strengthList },
        technologiesProps: { technologyList }
      } = getState().systemsCVGenerator;
      const isStrength = qualitiesProps.type === 'strength';
      const route = isStrength ? 'cv_qualities' : 'technologies';

      const { data, status } = await axios.put(
        `${process.env.NEXT_PUBLIC_API_BACKEND_URL}/${route}/${qualitiesProps.id}`,
        qualitiesProps,
      );

      const list = isStrength ? strengthList : technologyList;

      const newList = list.reduce((acc, item) => {
        if (item.id === data.id) {
          const newItem = { ...item, name: data.name };
          acc.push(newItem);
        } else {
          acc.push(item);
        }

        return acc;
      }, []);

      const newData = { newList, isStrength };

      return status === 200 ? newData : rejectWithValue(data.message);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const systemsCVGeneratorSlice = createSlice({
  name: 'systemsCVGenerator',
  initialState,
  reducers: {
    resetState: () => initialState,
    setPreviousRenderValue: (state, action) => {
      state.previousRenderValue = action.payload;
    },
    setQuery: (state, action) => {
      state.query = { ...state.query, ...action.payload };
    },
    setQueryFilter: (state, action) => {
      state.query.filters = { ...state.query.filters, ...action.payload };
    },
    setFormInput: (state, action) => {
      state.formInputData = { ...state.formInputData, ...action.payload };
      state.formErrors = {
        ...state.formErrors,
        ...newValidateErrors({ ...action.payload }, validateCVGeneratorForm)
      };
    },
    setEmployeesProps: (state, action) => {
      state.employeesProps = { ...state.employeesProps, ...action.payload };
    },
    setTechnologiesProps: (state, action) => {
      state.technologiesProps = { ...state.technologiesProps, ...action.payload };
    },
    setLanguagesProps: (state, action) => {
      state.languagesProps = { ...state.languagesProps, ...action.payload };
    },
    setProjectsProps: (state, action) => {
      state.projectsProps = { ...state.projectsProps, ...action.payload };
      state.formErrors = {
        ...state.formErrors,
        ...newValidateErrors({ ...action.payload }, validateCVGeneratorProjectForm)
      };
    },
    setProjectErrors: (state, action) => {
      state.projectErrors = { ...state.projectErrors, ...action.payload };
    },
    setQualitiesProps: (state, action) => {
      state.qualitiesProps = { ...state.qualitiesProps, ...action.payload };
    },
    setStrengthsProps: (state, action) => {
      state.strengthsProps = { ...state.strengthsProps, ...action.payload };
    },
    setModalData: (state, action) => {
      state.technologiesModal = { ...state.technologiesModal, ...action.payload };
      state.formErrors = {
        ...state.formErrors,
        ...newValidateErrors({ ...action.payload }, validateTechnologiesTreeModal)
      };
    },
    setCropModalData: (state, action) => {
      state.technologyCropModal = { ...state.technologyCropModal, ...action.payload };
    },
    resetModal: (state) => {
      state.technologiesModal = initialState.technologiesModal;
      state.qualitiesProps = initialState.qualitiesProps;
    },
    setData: (state, action) => {
      return { ...state, ...action.payload };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCVGeneratorAsync.pending, (state) => {
        state.isLoading = true;
        state.cvsPdfData = null;
      })
      .addCase(fetchCVGeneratorAsync.fulfilled, (state, action) => {
        state.isLoading = false;

        const {
          user, template, role, summary, experience, education, languageList, languageDropdownList, strengthList,
          github, technologyList, projectList, projectEmptyDescriptionIds
        } = action.payload;

        state.cvsPdfData = action.payload;

        state.employeesProps.employee = user;
        state.formInputData.employeeRole = role;
        state.formInputData.colorTemplate = template;
        state.formInputData.summary = summary;
        state.formInputData.experience = experience;
        state.formInputData.education = education;
        state.formInputData.githubLink = github;
        state.languagesProps.languageList = languageList;
        state.languagesProps.languageDropdownList = languageDropdownList;
        state.strengthsProps.strengthList = strengthList;
        state.technologiesProps.technologyList = technologyList;
        state.projectsProps.projectList = projectList;
        state.projectsProps.projectEmptyDescriptionIds = projectEmptyDescriptionIds;
      })
      .addCase(fetchCVGeneratorAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });

    builder
      .addCase(fetchUserTechnologiesAsync.pending, (state) => {
        state.isTechnologyLoading = true;
      })
      .addCase(fetchUserTechnologiesAsync.fulfilled, (state, action) => {
        state.isTechnologyLoading = false;
        state.technologiesProps.technologyList = action.payload;
      })
      .addCase(fetchUserTechnologiesAsync.rejected, (state, action) => {
        state.isTechnologyLoading = false;
        state.error = action.payload;
      });

    builder
      .addCase(fetchEmployeeProjectsAsync.pending, (state) => {
        state.isProjectLoading = true;
      })
      .addCase(fetchEmployeeProjectsAsync.fulfilled, (state, action) => {
        state.isProjectLoading = false;

        if (action.payload?.employeeProjects) {
          state.projectsProps.employeeProjects = action.payload.employeeProjects;
        } else if (action.payload?.projectNamesNDA && Object.keys(action.payload).length === 1) {
          state.projectsProps.projectNamesNDA = action.payload.projectNamesNDA;
        } else {
          state.projectsProps.projectList = action.payload.projectList;
          state.projectsProps.projectEmptyDescriptionIds = action.payload.projectEmptyDescriptionIds;
        }
      })
      .addCase(fetchEmployeeProjectsAsync.rejected, (state, action) => {
        state.isProjectLoading = false;
        state.error = action.payload;
      });

    builder
      .addCase(saveCVGeneratorAsync.pending, (state) => {
        state.isSaving = true;
      })
      .addCase(saveCVGeneratorAsync.fulfilled, (state, action) => {
        state.isSaving = false;

        const {
          data: dataCV, technologies, user, strengths
        } = action.payload;

        state.cvsPdfData = {
          user: user || {},
          role: dataCV?.role || '',
          template: dataCV?.template || 'gray',
          summary: dataCV?.summary || '',
          experience: dataCV?.experience || '',
          education: dataCV?.education || '',
          github: dataCV?.github || '',
          languageList: dataCV?.languages || [],
          strengthList: strengths || [],
          technologyList: technologies || [],
          projectList: dataCV?.projects || []
        };
      })
      .addCase(saveCVGeneratorAsync.rejected, (state, action) => {
        state.isSaving = false;

        if (action.payload?.errors) {
          state.formErrors = action.payload.errors;
        }
      });

    builder
      .addCase(addCVQualitiesAsync.pending, (state) => {
        state.isQualitiesLoading = true;
      })
      .addCase(addCVQualitiesAsync.fulfilled, (state) => {
        state.isQualitiesLoading = false;
      })
      .addCase(addCVQualitiesAsync.rejected, (state, action) => {
        state.isQualitiesLoading = false;
        state.error = action.payload;
      });

    builder
      .addCase(createTechnologyAsync.pending, (state) => {
        state.isSubmit = true;
      })
      .addCase(createTechnologyAsync.fulfilled, (state, action) => {
        state.technologiesProps.technologyList = [...state.technologiesProps.technologyList, action.payload];
        state.technologiesModal = initialState.technologiesModal;
        state.isSubmit = false;
      })
      .addCase(createTechnologyAsync.rejected, (state, action) => {
        state.isSubmit = false;
        state.error = action.payload;
      });

    builder
      .addCase(editTechnologyAsync.pending, (state) => {
        state.isSubmit = true;
      })
      .addCase(editTechnologyAsync.fulfilled, (state, action) => {
        const findIndex = state.technologiesProps.technologyList.findIndex(({ id }) => id === action.payload.id);
        state.technologiesProps.technologyList[findIndex] = action.payload;
        state.technologiesModal = initialState.technologiesModal;
        state.isSubmit = false;
      })
      .addCase(editTechnologyAsync.rejected, (state, action) => {
        state.isSubmit = false;
        state.error = action.payload;
      });

    builder
      .addCase(editCVQualitiesAsync.pending, (state) => {
        state.isQualitiesLoading = true;
      })
      .addCase(editCVQualitiesAsync.fulfilled, (state, action) => {
        const { newList, isStrength } = action.payload;

        if (isStrength) {
          state.strengthsProps.strengthList = newList;
        } else {
          state.technologiesProps.technologyList = newList;
        }
        state.isQualitiesLoading = false;
      })
      .addCase(editCVQualitiesAsync.rejected, (state, action) => {
        state.isQualitiesLoading = false;
        state.error = action.payload;
      });
  }
});

export const {
  setData,
  setPreviousRenderValue,
  setQueryFilter,
  setQuery,
  setFormInput,
  setEmployeesProps,
  setProjectsProps,
  setTechnologiesProps,
  setLanguagesProps,
  setStrengthsProps,
  setQualitiesProps,
  setModalData,
  setCropModalData,
  resetModal,
  resetState,
  setProjectErrors
} = systemsCVGeneratorSlice.actions;

export default systemsCVGeneratorSlice.reducer;
