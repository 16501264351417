import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../services/axios';
import httpRequestMethods from '../../constants/httpRequestMethods';
import { validateErrors, validateSalaryPaymentBankAccountForm } from '../../utils/validators';
import { SORT_BY_NAME } from '../../constants/common';
import { CREATED, OK } from '../../constants/httpStatusCodes';
import { STATUS_ACTIVE } from '../../constants/status';
import { LIST_SIZE_PER_PAGE } from '../../constants/values';

export const initialState = {
  data: [],
  links: {},
  meta: {},
  isLoading: false,
  isSubmit: false,
  queryParams: {
    filters: {
      id: [],
      xero_account_id: [],
      currency: null,
      status: [STATUS_ACTIVE],
    },
    page: null,
    per_page: LIST_SIZE_PER_PAGE,
    sort: SORT_BY_NAME,
  },
  modal: {
    modalId: 'salaryPaymentBankAccount',
    isModalOpen: false,
  },
  formFields: {
    id: null,
    name: '',
    xero_account_id: '',
    currency: '',
    use_for_import_payments: false,
    sync_to_quickbooks: false,
    status: STATUS_ACTIVE,
  },
  formErrors: {},
};

export const getSalaryPaymentBankAccount = createAsyncThunk(
  'salaryPaymentBankAccount/getSalaryPaymentBankAccount',
  async (arg, { getState, rejectWithValue }) => {
    try {
      const { queryParams: params } = getState().salaryPaymentBankAccount;
      const { data, status } = await axios.get('/salary_payment_bank_accounts', { params });

      return status === 200 ? data : rejectWithValue(data.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const createOrEditSalaryPaymentBankAccount = createAsyncThunk(
  'salaryPaymentBankAccount/createOrEditSalaryPaymentBankAccount',
  async ({ id }, { dispatch, getState, rejectWithValue }) => {
    try {
      const { formFields } = getState().salaryPaymentBankAccount;
      const errors = validateErrors(formFields, validateSalaryPaymentBankAccountForm);

      if (errors) {
        return rejectWithValue({ isFormValidation: true, errors });
      }

      let url = '/salary_payment_bank_accounts';
      let method = httpRequestMethods.POST;

      if (id) {
        url += `/${id}`;
        method = httpRequestMethods.PUT;
      }

      const body = {
        name: formFields.name,
        xero_account_id: formFields.xero_account_id,
        use_for_import_payments: formFields.use_for_import_payments,
        sync_to_quickbooks: formFields.sync_to_quickbooks,
        currency: formFields.currency,
        status: formFields.status,
      };

      const { data, status } = await axios({ url, method, data: body });

      if (status === CREATED || status === OK) {
        dispatch(getSalaryPaymentBankAccount());
        return data;
      }

      return rejectWithValue(data.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const salaryPaymentBankAccountSlice = createSlice({
  name: 'salaryPaymentBankAccount',
  initialState,
  reducers: {
    setModalData: (state, action) => {
      state.modal = { ...state.modal, ...action.payload };
    },
    setFormErrors: (state, action) => {
      state.formErrors = action.payload;
    },
    setFormFields: (state, action) => {
      state.formFields = action.payload;
    },
    setQueryParamsFilters: (state, action) => {
      state.queryParams.filters = { ...state.queryParams.filters, ...action.payload };
      state.queryParams.page = null;
    },
    setPage: (state, action) => {
      state.queryParams.page = action.payload;
    },
    resetModal: (state) => {
      state.formErrors = initialState.formErrors;
      state.modal = initialState.modal;
      state.formFields = initialState.formFields;
    },
    setValidateFormField: (state, action) => {
      const fieldError = validateSalaryPaymentBankAccountForm(action.payload.fieldName, action.payload.fieldData);

      state.formErrors = {
        ...state.formErrors,
        [action.payload.fieldName]: fieldError,
      };
      state.formFields = {
        ...state.formFields,
        [action.payload.fieldName]: action.payload.fieldData,
      };
    },
    resetState: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSalaryPaymentBankAccount.pending, (state) => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(getSalaryPaymentBankAccount.fulfilled, (state, action) => {
        state.data = action.payload.data;
        state.meta = action.payload.meta;
        state.link = action.payload.link;
        state.isLoading = false;
      })
      .addCase(getSalaryPaymentBankAccount.rejected, (state, action) => {
        state.error = action.payload;
        state.isLoading = false;
      });

    builder
      .addCase(createOrEditSalaryPaymentBankAccount.pending, (state) => {
        state.isSubmit = true;
      })
      .addCase(createOrEditSalaryPaymentBankAccount.fulfilled, (state) => {
        state.formFields = initialState.formFields;
        state.formErrors = initialState.formErrors;
        state.modal = initialState.modal;
        state.isSubmit = false;
      })
      .addCase(createOrEditSalaryPaymentBankAccount.rejected, (state, action) => {
        if (action.payload.isFormValidation) {
          state.formErrors = action.payload.errors;
        }
        state.isSubmit = false;
      });
  }
});

export const {
  setModalData,
  setPage,
  setQueryParamsFilters,
  resetModal,
  setFormFields,
  setFormErrors,
  setValidateFormField,
  resetState,
} = salaryPaymentBankAccountSlice.actions;

export default salaryPaymentBankAccountSlice.reducer;
