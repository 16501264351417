import PropTypes from 'prop-types';

import classNames from '../../utils/classNames';

import styles from './sass/TextInput.module.scss';

const TextInput = ({
  id = undefined,
  value = undefined,
  onChange = () => null,
  onBlur = () => null,
  onClick = () => null,
  placeholder = undefined,
  disabled = false,
  autoFocus = false,
  className = '',
  children = undefined
}) => {
  const classes = classNames(
    styles.wrapper,
    {
      [styles.withControls]: children,
    }
  );

  return (
    <div className={classes}>
      <input
        type="text"
        id={id}
        value={value}
        className={`${styles.input} ${className}`}
        onBlur={onBlur}
        onChange={onChange}
        onClick={onClick}
        placeholder={placeholder}
        disabled={disabled}
        autoFocus={autoFocus}
      />
      {children && (
        <div className={styles.controls}>
          {children}
        </div>
      )}
    </div>
  );
};

TextInput.propTypes = {
  id: PropTypes.string,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  autoFocus: PropTypes.bool,
  children: PropTypes.node,
};

export default TextInput;
