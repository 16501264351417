import PropTypes from 'prop-types';

const Phone = ({ width = 16, height = 16 }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M5 0H11C12.1 0 13 0.9 13 2V14C13 15.1 12.1 16 11 16H5C3.9 16 3 15.1 3 14V2C3 0.9 3.9 0 5 0ZM5.5 2C4.9 2 4.5 2.4 4.5 3V11C4.5 11.6 4.9 12 5.5 12H10.5C11.1 12 11.5 11.6 11.5 11V3C11.5 2.4 11.1 2 10.5 2H5.5ZM8 15C8.6 15 9 14.6 9 14C9 13.4 8.6 13 8 13C7.4 13 7 13.4 7 14C7 14.6 7.4 15 8 15Z" fill="#8E9AB2" />
    </svg>
  );
};

Phone.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default Phone;
