import fetchInstance from '../services/fetchInstance';

export const sendFiles = (files) => {
  const promises = [];

  files.forEach((file) => {
    const formData = new FormData();
    formData.append('file', file, file.name);

    const requestOptions = {
      method: 'POST',
      headers: { Accept: 'application/json' },
      body: formData,
    };

    promises.push(
      fetchInstance(`${process.env.NEXT_PUBLIC_API_BACKEND_URL}/media`, requestOptions)
        .then((res) => res.json())
        .then((res) => ({ ...res, name: file.name }))
        .catch((err) => new Error(err))
    );
  });

  return Promise.all(promises).then((res) => {
    const result = { error: false, data: [] };

    if (res.some((item) => item.message)) {
      result.error = true;
      result.data = res
        .map((item) => item.message && {
          id: item.name,
          message: `${item.name}: ${item.message}`,
          type: 'error',
        })
        .filter((item) => item !== undefined);
    } else {
      result.data = res.map((item) => item.id).filter((item) => item !== undefined);
    }

    return result;
  });
};

export const getNewImages = async (newFiles) => {
  const res = await Promise.all(
    newFiles.map((item) => fetch(item.src)
      .then((response) => response.blob())
      .then((blob) => {
        return new File([blob], item.name, { type: blob.type });
      }))
  );

  return res.map((item) => ({ file: item }));
};

export const parseAttachedFiles = (file) => ({
  file,
  src: file.original_url,
  name: file.name
});
