import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { startOfMonth, endOfMonth, parseISO } from 'date-fns';
import { approveSingleWorklogAsync } from '../review/slice';
import axios from '../../services/axios';
import { formatStringDate, parseHolidaysData } from '../../utils/helpers';
import {
  generationRows,
  getDays,
  workingHoursFromDays,
  getTotalTimesFromRows,
  getAllExpandedRows,
  expandedRow,
  createNewWorklogs,
  getRowIds,
  mergeUsers,
} from '../../utils/timesheet';
import { isEmpty } from '../../utils/arrayHelpers';
import { validateErrors, validateTimesheetLogTimeForm } from '../../utils/validators';
import { REGULAR } from '../../constants/common';
import * as httpStatusCodes from '../../constants/httpStatusCodes';

const date = new Date();
const startOfMonthDate = startOfMonth(date);
const endOfMonthDate = endOfMonth(date);
const startDate = formatStringDate(startOfMonthDate, 'y-MM-dd');
const endDate = formatStringDate(endOfMonthDate, 'y-MM-dd');
const defaultDays = getDays(startOfMonthDate, endOfMonthDate);

export const initialState = {
  isLoading: false,
  error: undefined,
  settings: {
    isShowOnlyActiveUsers: true,
    isShowOnlyWithWorklogs: false,
    isCellDisabled: false,
    isNotLoggedCellDisabled: false,
    isShowToday: true,
    isShowErrorBorder: false,
    isShowMonthSeparator: false,
    isShowTotalRows: true,
    isShowReportedTime: true,
    isShowBilledTime: true,
    isShowApprovedTime: true,
    isSaveToStorage: true,
    isExpandedAll: false,
    isShowExpanded: true,
    tableMaxWidth: undefined,
    disabledSearchVariant: undefined,
    defaultProject: undefined,
    initialDeepExpanded: 0,
    useBuilder: false
  },
  query: {
    filters: {
      id: [],
      start_date: startDate,
      end_date: endDate,
      user: {
        id: [],
        project: { id: [] },
      },
      project: { id: [] },
      client: { id: [] },
      department: { id: [] },
      ticket: { id: [], key: [] },
    },
    group_by: ['user_id', 'project_id', 'ticket_id'],
  },
  projectUsers: [],
  days: defaultDays,
  selectedIds: {
    user: [],
    project: [],
    ticket: [],
    client: [],
    department: [],
  },
  holidays: [],
  rows: [],
  dailyTotal: [],
  weeklyTotal: [],
  monthlyTotal: [],
  totalWorkingHours: 0,
  isWorklogDetailsLoading: false,
  worklogDetails: [],
  detailsCell: null,
  detailsModalId: null,
  deleteWorklog: null,
  isLogTimeSubmit: false,
  isWorklogDeleting: false,
  modal: {
    modalId: 'timesheetLogTime',
    isModalOpen: false,
    isUpdateTimesheet: false,
  },
  formFields: {
    worklogId: null,
    user: null,
    project: null,
    ticket: null,
    type: REGULAR,
    isLogPeriod: false,
    time: '',
    comment: '',
  },
  formErrors: {},
  formDatepicker: date,
  datepicker: {
    from: startOfMonthDate,
    to: endOfMonthDate,
  },
  expandedRows: [],
  allExpandedRows: [],
  useBuilder: false,
  useBuilderDataRanges: {
    startDate: null,
    endDate: null,
  },
};

const fetchTimesheet = async (query) => {
  return axios.post('/timesheet/2', query);
};

const fetchHolidays = async (query) => {
  return axios.get('/holidays', { params: query });
};

export const getTimesheet = createAsyncThunk(
  'timesheet/getTimesheet',
  async (arg, { rejectWithValue, getState }) => {
    try {
      const { query, projectUsers, selectedIds } = getState().timesheet;
      const timesheetQuery = { ...query };

      if (isEmpty(query.group_by)) {
        timesheetQuery.group_by = ['user_id'];
      }

      const [resTimesheet, resHolidays] = await Promise.all([
        fetchTimesheet(timesheetQuery),
        fetchHolidays({
          filters: {
            start_date: timesheetQuery.filters.start_date,
            end_date: timesheetQuery.filters.end_date
          }
        })
      ]);

      const { data: timesheetData, status: timesheetStatus } = resTimesheet;
      const { data: holidaysData, status: holidaysStatus } = resHolidays;
      let days = defaultDays;
      let holidays = [];

      if (holidaysStatus === 200) {
        holidays = parseHolidaysData(
          holidaysData.data,
          parseISO(timesheetQuery.filters.start_date),
          parseISO(timesheetQuery.filters.end_date),
        );
        days = getDays(
          parseISO(timesheetQuery.filters.start_date),
          parseISO(timesheetQuery.filters.end_date),
          holidays
        );
      }

      if (timesheetStatus === httpStatusCodes.OK) {
        const data = { ...timesheetData };

        if (projectUsers.length > 0 && selectedIds.user.length === 0) {
          data.users = mergeUsers(data.users, projectUsers);
        }

        return { days, data, holidays };
      }

      return rejectWithValue(timesheetData.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getUsersByProjectId = createAsyncThunk(
  'timesheet/getUsersByProjectId',
  async ({ projectId }, { rejectWithValue }) => {
    const params = {
      filters: {
        show_inactive: true,
        without_users_assigned_via_group: true,
        without_groups: true,
        project: { id: [projectId] }
      },
      per_page: 500,
      sort: 'name',
    };

    try {
      const { data, status } = await axios.get('/project_people', { params });

      return status === httpStatusCodes.OK ? data.data : rejectWithValue(data.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getWorklogDetails = createAsyncThunk(
  'timesheet/getWorklogDetails',
  async ({ filters }, { rejectWithValue }) => {
    const params = {
      filters,
      per_page: 500,
      with: ['user', 'approver', 'reporter', 'milestone_item'],
    };

    try {
      const { data, status } = await axios.get('/worklogs', { params });

      return status === httpStatusCodes.OK ? data.data : rejectWithValue(data.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteWorklog = createAsyncThunk(
  'timesheet/deleteWorklog',
  async ({ id }, { dispatch, rejectWithValue }) => {
    try {
      const { data, status } = await axios.delete(`/worklogs/${id}`);

      if (status === httpStatusCodes.NO_CONTENT) {
        dispatch(getTimesheet());
        return data;
      }

      return rejectWithValue(data.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const createWorklogs = createAsyncThunk(
  'timesheet/createWorklogs',
  async (arg, { getState, dispatch, rejectWithValue }) => {
    const { formFields, formDatepicker } = getState().timesheet;
    const errors = validateErrors({ ...formFields, datepicker: formDatepicker }, validateTimesheetLogTimeForm);

    if (errors) {
      return rejectWithValue({ isFormValidation: true, errors });
    }

    try {
      const { data, status } = await createNewWorklogs(formFields, formDatepicker);

      if (status === httpStatusCodes.OK || status === httpStatusCodes.CREATED) {
        dispatch(getTimesheet());
        return data.data;
      }

      return rejectWithValue(data.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const timesheetSlice = createSlice({
  name: 'timesheet',
  initialState,
  reducers: {
    resetState: () => initialState,
    resetDatepicker: (state) => {
      state.datepicker.from = state.query.filters.start_date;
      state.datepicker.to = state.query.filters.end_date;
    },
    setDatepickerData: (state, action) => {
      state.datepicker = action.payload;
    },
    setSelectedIds: (state, action) => {
      state.selectedIds = { ...state.selectedIds, ...action.payload };
    },
    resetSelectedIds: (state) => {
      state.selectedIds = {
        project: state.query.filters.project.id,
        user: state.query.filters.user.id,
        ticket: state.query.filters.ticket.id,
        client: state.query.filters.client.id,
        department: state.query.filters.department.id,
      };
    },
    setQueryFilter: (state, action) => {
      const { user } = state.query.filters;
      const updateUsers = action.payload.user?.id && Object.keys(action.payload.user).length === 1 ? {
        user: { ...user, id: action.payload.user?.id || [] }
      } : {};

      state.query.filters = {
        ...state.query.filters,
        ...action.payload,
        ...updateUsers,
      };
    },
    setQuery: (state, action) => {
      state.query = { ...state.query, ...action.payload };
    },
    setGroupBy: (state, action) => {
      state.query.group_by = action.payload;
    },
    setData: (state, action) => {
      return ({ ...state, ...action.payload });
    },
    setDetailsModalId: (state, action) => {
      state.detailsModalId = action.payload;
    },
    setDetailsCell: (state, action) => {
      state.detailsCell = action.payload;
    },
    setSettings: (state, action) => {
      state.settings = { ...state.settings, ...action.payload };
    },
    setExpandedRow: (state, action) => {
      state.expandedRows = expandedRow(action.payload.rowId, state.expandedRows, state.rows);
    },
    expandedAllRows: (state, action) => {
      if (action.payload === true) {
        state.expandedRows = state.allExpandedRows;
        state.settings.isExpandedAll = true;
      } else {
        state.expandedRows = [];
        state.settings.isExpandedAll = false;
      }
    },
    setFormErrors: (state, action) => {
      state.formErrors = action.payload;
    },
    setFormFields: (state, action) => {
      state.formFields = { ...state.formFields, ...action.payload };
    },
    setFormDatepicker: (state, action) => {
      const fieldError = validateTimesheetLogTimeForm('datepicker', action.payload);

      state.formDatepicker = action.payload;
      state.formErrors = { ...state.formErrors, datepicker: fieldError, };
    },
    setModalData: (state, action) => {
      state.modal = { ...state.modal, ...action.payload };
    },
    resetModal: (state) => {
      state.modal = initialState.modal;
      state.formFields = initialState.formFields;
      state.formErrors = initialState.formErrors;
      state.formDatepicker = initialState.formDatepicker;
    },
    setValidateFormField: (state, action) => {
      const fieldError = validateTimesheetLogTimeForm(action.payload.fieldName, action.payload.fieldData);

      state.formErrors = {
        ...state.formErrors,
        [action.payload.fieldName]: fieldError,
      };
      state.formFields = {
        ...state.formFields,
        [action.payload.fieldName]: action.payload.fieldData,
      };
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUsersByProjectId.fulfilled, (state, action) => {
        state.projectUsers = action.payload.map((item) => ({
          id: item.user.id,
          name: item.user.name,
          on_vacation: item.user.on_vacation,
          photo48_link: item.user.photo48_link,
          status: item.user.status,
          removed_from_project: item.trashed,
        }));
      });

    builder
      .addCase(createWorklogs.pending, (state) => {
        state.isLogTimeSubmit = true;
      })
      .addCase(createWorklogs.fulfilled, (state) => {
        state.isLogTimeSubmit = false;
        state.modal = initialState.modal;
        state.formFields = initialState.formFields;
        state.formErrors = initialState.formErrors;
        state.formDatepicker = initialState.formDatepicker;
      })
      .addCase(createWorklogs.rejected, (state, action) => {
        if (action.payload.isFormValidation) {
          state.formErrors = action.payload.errors;
        }
        state.isLogTimeSubmit = false;
      });

    builder
      .addCase(getTimesheet.pending, (state) => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(getTimesheet.fulfilled, (state, action) => {
        const { data, days, holidays } = action.payload;
        const rows = generationRows(data, state.query.group_by, days, state.useBuilder, state.useBuilderDataRanges, {});
        const { daily, weekly, monthly } = getTotalTimesFromRows(rows);
        const totalWorkingHours = workingHoursFromDays(days);
        const allExpandedRows = getAllExpandedRows(rows);

        if (state.expandedRows.length === 0) {
          const expandedRows = getRowIds(rows, state.settings.initialDeepExpanded);
          state.expandedRows = state.settings.isExpandedAll ? allExpandedRows : expandedRows;
        }

        state.isLoading = false;
        state.days = days;
        state.holidays = holidays;
        state.rows = rows;
        state.allExpandedRows = allExpandedRows;
        state.dailyTotal = daily;
        state.weeklyTotal = weekly;
        state.monthlyTotal = monthly;
        state.totalWorkingHours = totalWorkingHours;
      })
      .addCase(getTimesheet.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });

    builder
      .addCase(getWorklogDetails.pending, (state) => {
        state.isWorklogDetailsLoading = true;
      })
      .addCase(getWorklogDetails.fulfilled, (state, action) => {
        state.isWorklogDetailsLoading = false;
        state.worklogDetails = action.payload;
      })
      .addCase(getWorklogDetails.rejected, (state) => {
        state.isWorklogDetailsLoading = false;
      });

    builder
      .addCase(deleteWorklog.pending, (state) => {
        state.isWorklogDeleting = true;
      })
      .addCase(deleteWorklog.fulfilled, (state, action) => {
        const filtered = state.worklogDetails.filter(({ id }) => id !== action.meta.arg.id);

        state.isWorklogDeleting = false;
        state.deleteWorklog = null;
        state.worklogDetails = filtered;

        if (filtered.length === 0) {
          state.detailsModalId = null;
        }
      })
      .addCase(deleteWorklog.rejected, (state) => {
        state.isWorklogDeleting = false;
      });

    builder
      .addCase(approveSingleWorklogAsync.fulfilled, (state) => {
        state.modal.isUpdateTimesheet = true;
      });
  },
});

export const {
  setDetailsCell,
  resetSelectedIds,
  setSelectedIds,
  setQueryFilter,
  setQuery,
  setGroupBy,
  setData,
  resetState,
  setDetailsModalId,
  setSettings,
  expandedAllRows,
  setExpandedRow,
  setFormErrors,
  setFormFields,
  setModalData,
  setFormDatepicker,
  setValidateFormField,
  resetModal,
  setDatepickerData,
  resetDatepicker,
} = timesheetSlice.actions;

export default timesheetSlice.reducer;
