export const SALARY_BASE_FETCH_DATA = 'SALARY_BASE_FETCH_DATA';
export const SALARY_BASE_SET_PAGE = 'SALARY_BASE_SET_PAGE';
export const SALARY_BASE_SET_ABORT = 'SALARY_BASE_SET_ABORT';
export const SALARY_BASE_SET_QUERY_PARAMS = 'SALARY_BASE_SET_QUERY_PARAMS';
export const SALARY_BASE_SET_TABLE_HEAD_DATA = 'SALARY_BASE_SET_TABLE_HEAD_DATA';
export const SALARY_BASE_LIST_SET_SUBMIT = 'SALARY_BASE_LIST_SET_SUBMIT';
export const SALARY_BASE_SET_PAGE_FILTER_DATA = 'SALARY_BASE_SET_PAGE_FILTER_DATA';
export const SALARY_BASE_SET_TABLE_COLUMNS = 'SALARY_BASE_SET_TABLE_COLUMNS';
export const SALARY_BASE_SET_MODAL_DATA = 'SALARY_BASE_SET_MODAL_DATA';
export const SALARY_BASE_SET_MODAL_FIELDS = 'SALARY_BASE_SET_MODAL_FIELDS';
export const SALARY_BASE_SET_SORT = 'SALARY_BASE_SET_SORT';
export const SALARY_BASE_SET_LOG_ERROR = 'SALARY_BASE_SET_LOG_ERROR';
export const SALARY_BASE_RESET_STATE = 'SALARY_BASE_RESET_STATE';
export const SALARY_BASE_RESET_MODAL = 'SALARY_BASE_RESET_MODAL';
export const SALARY_BASE_RESET_FILTERS = 'SALARY_BASE_RESET_FILTERS';
export const SALARY_BASE_RESET_DATEPICKER = 'SALARY_BASE_RESET_DATEPICKER';
export const SALARY_BASE_SET_DATEPICKER_DATA = 'SALARY_BASE_SET_DATEPICKER_DATA';
