import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../services/axios';
import { validateErrors, validateHelpCenter } from '../../utils/validators';
import getArticleId from '../../utils/outline';
import { CREATED, NO_CONTENT, OK } from '../../constants/httpStatusCodes';

export const initialState = {
  data: [],
  meta: [],
  isEmpty: true,
  isLoading: false,
  error: undefined,
  query: {
    filters: {
      show_inactive: false,
      hydra_url: '',
    },
  },
  article: {
    title: '',
    text: ''
  },
  nameError: undefined,
  helpCenterModal: {
    modalId: 'helpCenterModal',
    isModalOpen: false,
    referenceVisible: false,
    isArticleOpen: false,
    helpUrl: '',
    isEdit: false,
    isSaving: false
  },
  helpCenterDeleteModal: {
    modalId: 'helpCenterDeleteModal',
    isModalOpen: false,
    deleteId: '',
    deleteTitle: '',
  },
  modalErrors: {},
  modalFields: {
    link: '',
    name: '',
    currentPath: '',
    id: '',
  }
};

export const fetchReferencesAsync = createAsyncThunk(
  'helpCenter/fetchReferencesAsync',
  async (arg, { rejectWithValue, getState }) => {
    try {
      const { query } = getState().helpCenter;
      const { data, status } = await axios.get('/help_articles', { params: query });

      return status === OK ? data : rejectWithValue(data.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteReferenceAsync = createAsyncThunk(
  'helpCenter/deleteReferenceAsync',
  async (id, { rejectWithValue }) => {
    try {
      const { data, status } = await axios.delete(`/help_articles/${id}`);

      return status === NO_CONTENT ? data : rejectWithValue(data.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchArticleAsync = createAsyncThunk(
  'helpCenter/fetchArticleAsync',
  async (args, { rejectWithValue }) => {
    try {
      const articleId = getArticleId(args.url);

      if (!articleId) {
        return rejectWithValue({ isFormValidation: true, error: 'test' });
      }

      const resp = await fetch(
        '/api/documents.info',
        {
          method: 'POST',
          body: JSON.stringify({ id: articleId }),
          headers: { 'content-type': 'application/json' }
        }
      );

      const article = await resp.json();

      if (article.ok) {
        const { data } = article;
        const text = data.text.replace(/\]\(\/doc\//g, `](${args.url.split('/doc/')[0]}/doc/`);

        return { title: data.title, text };
      }

      return rejectWithValue({ message: article.error });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const addOrEditReferencesAsync = createAsyncThunk(
  'helpCenter/addOrEditReferencesAsync',
  async (id, { rejectWithValue, dispatch, getState }) => {
    try {
      const { modalFields, query } = getState().helpCenter;
      const errors = validateErrors(modalFields, validateHelpCenter);

      if (errors) {
        return rejectWithValue({ isFormValidation: true, errors });
      }

      let method = 'POST';
      let url = '/help_articles';

      if (id) {
        method = 'PATCH';
        url = `/help_articles/${id}`;
      }

      const { data, status } = await axios({
        method,
        url,
        data: {
          help_url: modalFields.link,
          hydra_url: query.filters.hydra_url,
          name: modalFields.name
        },
      });

      if (status === OK || status === CREATED) {
        dispatch(fetchReferencesAsync());
        return data;
      }

      return rejectWithValue(data.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const helpCenterSlice = createSlice({
  name: 'helpCenter',
  initialState,
  reducers: {
    setModalData: (state, action) => {
      state.helpCenterModal = { ...state.helpCenterModal, ...action.payload };
    },
    setDeleteModalData: (state, action) => {
      state.helpCenterDeleteModal = { ...state.helpCenterDeleteModal, ...action.payload };
    },
    setModalFields: (state, action) => {
      state.modalFields = { ...state.modalFields, ...action.payload };
    },
    setQueryParamsFilters: (state, action) => {
      state.query.filters = { ...state.query.filters, ...action.payload };
    },
    resetModal: (state) => {
      state.helpCenterModal = initialState.helpCenterModal;
      state.helpCenterDeleteModal = initialState.helpCenterDeleteModal;
      state.modalErrors = initialState.modalErrors;
      state.modalFields = initialState.modalFields;
    },
    resetModalErrors: (state) => {
      state.modalErrors = initialState.modalErrors;
    },
    setModalErrors: (state, action) => {
      state.modalErrors = { ...state.modalErrors, ...action.payload };
    },
    resetState: () => initialState
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchReferencesAsync.pending, (state) => {
        state.isLoading = true;
        state.helpCenterModal.referenceVisible = true;
        state.error = undefined;
      })
      .addCase(fetchReferencesAsync.fulfilled, (state, action) => {
        state.data = action.payload.data || [];
        state.article.id = action.payload.data?.[0]?.id;
        state.meta = action.payload.meta;

        if (action.payload.data.length > 0) {
          state.helpCenterModal.helpUrl = action.payload.data[0].help_url;
        } else {
          state.helpCenterModal.referenceVisible = true;
          state.isLoading = false;
        }
      })
      .addCase(fetchReferencesAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
    builder
      .addCase(fetchArticleAsync.pending, (state) => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(fetchArticleAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        state.helpCenterModal.isArticleOpen = true;
        state.helpCenterModal.referenceVisible = false;
        state.article = { ...state.article, ...action.payload };
        state.modalFields.name = action.payload.title;
      })
      .addCase(fetchArticleAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
    builder
      .addCase(addOrEditReferencesAsync.pending, (state) => {
        state.isSaving = true;
        state.error = undefined;
      })
      .addCase(addOrEditReferencesAsync.fulfilled, (state, action) => {
        state.isSaving = false;
        state.data = action.payload;
        state.helpCenterModal = initialState.helpCenterModal;
        state.helpCenterDeleteModal = initialState.helpCenterDeleteModal;
        state.modalErrors = initialState.modalErrors;
        state.modalFields = initialState.modalFields;
      })
      .addCase(addOrEditReferencesAsync.rejected, (state, action) => {
        state.isSaving = false;
        state.error = action.payload;
      });
    builder
      .addCase(deleteReferenceAsync.pending, (state) => {
        state.isDeleting = true;
        state.error = undefined;
      })
      .addCase(deleteReferenceAsync.fulfilled, (state) => {
        state.isDeleting = false;
        state.helpCenterModal = initialState.helpCenterModal;
        state.helpCenterDeleteModal = initialState.helpCenterDeleteModal;
        state.modalErrors = initialState.modalErrors;
        state.modalFields = initialState.modalFields;
        state.data = [];
      })
      .addCase(deleteReferenceAsync.rejected, (state, action) => {
        state.isDeleting = false;
        state.error = action.payload;
      });
  },
});

export const {
  setModalData,
  setModalFields,
  setQueryParamsFilters,
  resetState,
  resetModal,
  setDeleteModalData,
  setModalErrors,
  resetModalErrors
} = helpCenterSlice.actions;

export default helpCenterSlice.reducer;
