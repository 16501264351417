export const INVOICES_FETCH_DATA = 'INVOICES_FETCH_DATA';
export const INVOICES_SET_EXPANDED_ROW = 'INVOICES_SET_EXPANDED_ROW';
export const INVOICES_SET_MODAL_DATA = 'INVOICES_SET_MODAL_DATA';
export const INVOICES_SET_TABLE_HEAD_DATA = 'INVOICES_SET_TABLE_HEAD_DATA';
export const INVOICES_SET_ABORT = 'INVOICES_SET_ABORT';
export const INVOICES_FETCH_NUMBER_OF = 'INVOICES_FETCH_NUMBER_OF';
export const INVOICES_FETCH_INVOICE = 'INVOICES_FETCH_INVOICE';
export const INVOICES_SET_QUERY_PARAMS = 'INVOICES_SET_QUERY_PARAMS';
export const INVOICES_FETCH_CATEGORIES = 'INVOICES_FETCH_CATEGORIES';
export const INVOICES_SET_MODAL_ERROR = 'INVOICES_SET_MODAL_ERROR';
export const INVOICES_SET_DATEPICKER_DATA = 'INVOICES_SET_DATEPICKER_DATA';
export const INVOICES_RESET_CUSTOM_MODAL_DATA = 'INVOICES_RESET_CUSTOM_MODAL_DATA';
export const INVOICES_SET_SELECTED_INVOICES = 'INVOICES_SET_SELECTED_INVOICES';
export const INVOICES_TOGGLE_TABLE_MORE = 'INVOICES_TOGGLE_TABLE_MORE';
export const INVOICES_SET_SENDING_INVOICES = 'INVOICES_SET_SENDING_INVOICES';
export const INVOICES_SET_INITIAL_STATE = 'INVOICES_SET_INITIAL_STATE';
export const INVOICES_SET_LOADING = 'INVOICES_SET_LOADING';
