import styles from './sass/Logo.module.scss';

const Logo = () => (
  <span className={styles.wrapper}>
    <strong>HYDRA.</strong>
    projects
  </span>
);

export default Logo;
