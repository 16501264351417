export const CLIENT_INFO_FETCH_CLIENT = 'CLIENT_INFO_FETCH_CLIENT';
export const CLIENT_INFO_SET_EDIT = 'CLIENT_INFO_SET_EDIT';
export const CLIENT_INFO_SEARCH_MANAGER = 'CLIENT_INFO_SEARCH_MANAGER';
export const CLIENT_INFO_SET_DATA = 'CLIENT_INFO_SET_DATA';
export const CLIENT_INFO_SET_SUBMIT = 'CLIENT_INFO_SET_SUBMIT';
export const CLIENT_INFO_FETCH_PAYMENT_METHODS = 'CLIENT_INFO_FETCH_PAYMENT_METHODS';
export const CLIENT_INFO_FETCH_CLIENT_STATISTIC = 'CLIENT_INFO_FETCH_CLIENT_STATISTIC';
export const CLIENT_INFO_SET_ERROR = 'CLIENT_INFO_SET_ERROR';
export const CLIENT_INFO_SET_ERRORS = 'CLIENT_INFO_SET_ERRORS';
export const CLIENT_INFO_RESET_CLIENT = 'CLIENT_INFO_RESET_CLIENT';
export const CLIENT_INFO_SET_MODAL_DATA = 'CLIENT_INFO_SET_MODAL_DATA';
