export const ASSETS_SET_ABORT = 'ASSETS_SET_ABORT';
export const ASSETS_SET_QUERY_PARAMS = 'ASSETS_SET_QUERY_PARAMS';
export const ASSETS_SET_TABLE_HEAD_INPUT = 'ASSETS_SET_TABLE_HEAD_INPUT';
export const ASSETS_SET_MODAL_DATA = 'ASSETS_SET_MODAL_DATA';
export const ASSETS_RESET_MODAL = 'ASSETS_RESET_MODAL';
export const ASSETS_SET_MODAL_FIELDS = 'ASSETS_SET_MODAL_FIELDS';
export const ASSETS_LIST_SET_SUBMIT = 'ASSETS_LIST_SET_SUBMIT';
export const ASSETS_SET_LOG_ERROR = 'ASSETS_SET_LOG_ERROR';
export const ASSETS_SET_PAGE_FILTER_DATA = 'ASSETS_SET_PAGE_FILTER_DATA';
export const ASSETS_SET_PAGE = 'ASSETS_SET_PAGE';
export const ASSETS_SET_SORT = 'ASSETS_SET_SORT';
export const ASSETS_SET_ASSETS = 'ASSETS_SET_ASSETS';
export const ASSETS_SET_LOADING = 'ASSETS_SET_LOADING';
export const ASSETS_RESET_FILTERS = 'ASSETS_RESET_FILTERS';
export const ASSETS_SET_TABLE_COLUMNS = 'ASSETS_SET_TABLE_COLUMNS';
export const ASSETS_SET_EXPORT_LOADING = 'ASSETS_SET_EXPORT_LOADING';
