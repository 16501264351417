import axios from 'axios';
import Router from 'next/router';

import { assignNullToUndefined, filterQueryParams, queryStringify } from '../utils/helpers';
import { NOT_AUTHORIZED } from '../constants/httpStatusCodes';
import { REFRESH_ACCESS_TOKEN_ERROR } from '../constants/errors';

let isRefreshing = false;
let failedRequestsQueue = [];

const processQueue = (error, token = null) => {
  failedRequestsQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedRequestsQueue = [];
};

const instance = axios.create({
  baseURL: process.env.NEXT_PUBLIC_API_BACKEND_URL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  paramsSerializer: (params) => queryStringify(params),
  transformRequest: [(data) => {
    return filterQueryParams(typeof data === 'string' ? JSON.parse(data) : data);
  }, ...axios.defaults.transformRequest]
});

instance.interceptors.request.use(
  (config) => {
    const newConfig = config;

    if (newConfig.method === 'put' || newConfig.method === 'patch') {
      newConfig.transformRequest = [(data) => {
        if (data) {
          return assignNullToUndefined((typeof data === 'string' ? JSON.parse(data) : data));
        }

        return null;
      }, ...axios.defaults.transformRequest];
    }

    return newConfig;
  }
);

instance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    if (error.response.status === NOT_AUTHORIZED && !originalRequest.retry) {
      originalRequest.retry = true;

      if (isRefreshing) {
        return new Promise((resolve, reject) => {
          failedRequestsQueue.push({ resolve, reject });
        })
          .then((token) => {
            originalRequest.headers.Authorization = `Bearer ${token}`;
            return instance(originalRequest);
          })
          .catch((err) => {
            return Promise.reject(err);
          });
      }

      isRefreshing = true;

      try {
        const session = await fetch('/api/auth/session').then((res) => res.json());

        if (session?.error === REFRESH_ACCESS_TOKEN_ERROR) {
          throw REFRESH_ACCESS_TOKEN_ERROR;
        }

        if (session?.accessToken) {
          instance.defaults.headers.common['Authorization'] = `Bearer ${session.accessToken}`;
          originalRequest.headers.Authorization = `Bearer ${session.accessToken}`;
          processQueue(null, session?.accessToken);
          isRefreshing = false;
          return instance(originalRequest);
        }
      } catch (err) {
        if (err === REFRESH_ACCESS_TOKEN_ERROR) {
          await Router.push({ pathname: '/auth/sign-in', query: { callbackUrl: Router.router.asPath } });
        } else {
          processQueue(error, null);
        }

        isRefreshing = false;
      }
    }

    return Promise.reject(error);
  }
);

export default instance;
