import PropTypes from 'prop-types';

const Bag = ({ width = 18, height = 18 }) => (
  <svg width={width} height={height} viewBox="0 0 18 18" style={{ fill: 'none' }} xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_23465_317109)">
      <path d="M15.1875 5.0625H2.8125C2.50184 5.0625 2.25 5.31434 2.25 5.625V14.625C2.25 14.9357 2.50184 15.1875 2.8125 15.1875H15.1875C15.4982 15.1875 15.75 14.9357 15.75 14.625V5.625C15.75 5.31434 15.4982 5.0625 15.1875 5.0625Z" stroke="#8891A3" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M11.8125 5.0625V3.9375C11.8125 3.63913 11.694 3.35298 11.483 3.142C11.272 2.93103 10.9859 2.8125 10.6875 2.8125H7.3125C7.01413 2.8125 6.72798 2.93103 6.51701 3.142C6.30603 3.35298 6.1875 3.63913 6.1875 3.9375V5.0625" stroke="#8891A3" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M2.25 11.25H15.75" stroke="#8891A3" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </g>
    <defs>
      <clipPath id="clip0_23465_317109">
        <rect width="18" height="18" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

Bag.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default Bag;
