export const SALARY_FOP_FETCH_DATA = 'SALARY_FOP_FETCH_DATA';
export const SALARY_FOP_SET_PAGE = 'SALARY_FOP_SET_PAGE';
export const SALARY_FOP_SET_ABORT = 'SALARY_FOP_SET_ABORT';
export const SALARY_FOP_SET_QUERY_PARAMS = 'SALARY_FOP_SET_QUERY_PARAMS';
export const SALARY_FOP_SET_HOLIDAY_ERROR = 'SALARY_FOP_SET_HOLIDAY_ERROR';
export const SALARY_FOP_RESET_FOP_DATA = 'SALARY_FOP_RESET_FOP_DATA';
export const SALARY_FOP_SET_HOLIDAY_DATA = 'SALARY_FOP_SET_HOLIDAY_DATA';
export const SALARY_FOP_SET_TABLE_HEAD_DATA = 'SALARY_FOP_SET_TABLE_HEAD_DATA';
export const SALARY_FOP_RESET_DATEPICKER = 'SALARY_FOP_RESET_DATEPICKER';
export const SALARY_FOP_LIST_SET_SUBMIT = 'SALARY_FOP_LIST_SET_SUBMIT';
export const SALARY_FOP_SET_MODAL_DATA = 'SALARY_FOP_SET_MODAL_DATA';
export const SALARY_FOP_SET_PAGE_FILTER_DATA = 'SALARY_FOP_SET_PAGE_FILTER_DATA';
export const SALARY_FOP_SET_TABLE_COLUMNS = 'SALARY_FOP_SET_TABLE_COLUMNS';
export const SALARY_FOP_RESET_STATE = 'SALARY_FOP_RESET_STATE';
export const SALARY_FOP_SET_SORT = 'SALARY_FOP_SET_SORT';
