export const SALARY_CORRECTION_FETCH_DATA = 'SALARY_CORRECTION_FETCH_DATA';
export const SALARY_CORRECTION_SET_PAGE = 'SALARY_CORRECTION_SET_PAGE';
export const SALARY_CORRECTION_SET_ABORT = 'SALARY_CORRECTION_SET_ABORT';
export const SALARY_CORRECTION_SET_QUERY_PARAMS = 'SALARY_CORRECTION_SET_QUERY_PARAMS';
export const SALARY_CORRECTION_LIST_SET_SUBMIT = 'SALARY_CORRECTION_LIST_SET_SUBMIT';
export const SALARY_CORRECTION_SET_MODAL_DATA = 'SALARY_CORRECTION_SET_MODAL_DATA';
export const SALARY_CORRECTION_SET_MODAL_FIELDS = 'SALARY_CORRECTION_SET_MODAL_FIELDS';
export const SALARY_CORRECTION_SET_PAGE_FILTER_DATA = 'SALARY_CORRECTION_SET_PAGE_FILTER_DATA';
export const SALARY_CORRECTION_SET_TABLE_COLUMNS = 'SALARY_CORRECTION_SET_TABLE_COLUMNS';
export const SALARY_CORRECTION_SET_LOG_ERROR = 'SALARY_CORRECTION_SET_LOG_ERROR';
export const SALARY_CORRECTION_SET_SORT = 'SALARY_CORRECTION_SET_SORT';
export const SALARY_CORRECTION_SET_TABLE_HEAD_DATA = 'SALARY_CORRECTION_SET_TABLE_HEAD_DATA';
export const SALARY_CORRECTION_RESET_MODAL = 'SALARY_CORRECTION_RESET_MODAL';
export const SALARY_CORRECTION_RESET_STATE = 'SALARY_CORRECTION_RESET_STATE';
