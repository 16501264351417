import { useCallback, useRef } from 'react';

const useInfiniteScroll = ({ loadMore, hasMore, isLoading }) => {
  const observer = useRef();

  const lastElementRef = useCallback((node) => {
    if (isLoading) return;

    if (observer.current) {
      observer.current.disconnect();
    }

    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && hasMore) {
        loadMore();
      }
    });

    if (node) {
      observer.current.observe(node);
    }
  }, [isLoading, hasMore, loadMore]);

  return lastElementRef;
};

export default useInfiniteScroll;
