import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../services/axios';
import { sortBy } from '../../utils/arrayHelpers';
import { isAllowed } from '../../utils/helpers';
import * as httpStatusCodes from '../../constants/httpStatusCodes';
import { GUARD_NAME_PROJECTS, GUARD_NAME_WEB } from '../../constants/common';
import { LIST_SIZE_PER_PAGE_25 } from '../../constants/values';
import { PERMISSION_MANAGEMENT, PERMISSION_READ } from '../../constants/permissions';
import { STATUS_ACTIVE } from '../../constants/status';

export const initialState = {
  role: undefined,
  editRole: undefined,
  isLoading: false,
  isPermissionsUpdating: false,
  isUsersUpdating: false,
  isMembersTab: true,
  users: [],
  permissions: [],
  rolePermissions: [],
  isUsersLoading: false,
  roleUsers: {
    data: [],
    meta: {},
  },
  usersQueryParams: {
    page: 0,
    per_page: LIST_SIZE_PER_PAGE_25,
    filters: {
      name: '',
      status: [STATUS_ACTIVE]
    }
  },
  modal: {
    modalId: '',
    isModalOpen: false,
  },
  permissionsData: {
    permissions: [],
    selectedPermissions: [],
    addPermissions: [],
    removePermissions: [],
    disabledPermissions: [],
    group: '',
  },
  deleteUserModalId: null,
  deletePermission: null,
  isDeleteAll: false,
  userNameValue: '',
  addUsers: [],
};

export const getPermissions = createAsyncThunk(
  'roleInfo/getPermissions',
  async ({ guardName }, { rejectWithValue }) => {
    const params = {
      filters: { guard_name: guardName }
    };

    try {
      const { data, status } = await axios.get('/permissions', { params });

      return status === httpStatusCodes.OK ? sortBy(data, 'group') : rejectWithValue(data.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getPermissionsByRoleId = createAsyncThunk(
  'roleInfo/getPermissionsByRoleId',
  async ({ id }, { rejectWithValue }) => {
    const params = { per_page: 500 };

    try {
      const { data, status } = await axios.get(`/roles/${id}/permissions`, { params });

      return status === httpStatusCodes.OK ? data : rejectWithValue(data.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getUsersByRoleId = createAsyncThunk(
  'roleInfo/getUsersByRoleId',
  async ({ id }, { getState, rejectWithValue }) => {
    try {
      const { usersQueryParams } = getState().roleInfo;
      const { data, status } = await axios.get(`/roles/${id}/users`, { params: usersQueryParams });

      return status === httpStatusCodes.OK ? data : rejectWithValue(data.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getRoleById = createAsyncThunk(
  'roleInfo/getRoleById',
  async ({ id }, { dispatch, getState, rejectWithValue }) => {
    const { permissions } = getState().user;

    try {
      const { data, status } = await axios.get(`/roles/${id}`);

      if (status === httpStatusCodes.OK) {
        if (data.guard_name === GUARD_NAME_PROJECTS
          && isAllowed([PERMISSION_MANAGEMENT, PERMISSION_READ], permissions)) {
          await dispatch(getPermissions({ guardName: GUARD_NAME_PROJECTS }));
          await dispatch(getPermissionsByRoleId({ id: data.id }));
        } else if (data.guard_name === GUARD_NAME_WEB) {
          await dispatch(getUsersByRoleId({ id: data.id }));
        }

        return data;
      }

      return rejectWithValue(data.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateRoleById = createAsyncThunk(
  'roleInfo/updateRoleById',
  async ({ id, body }, { rejectWithValue }) => {
    try {
      const { data, status } = await axios.put(`/roles/${id}`, body);

      return status === httpStatusCodes.OK ? data : rejectWithValue(data.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updatePermissionsForRole = createAsyncThunk(
  'roleInfo/updatePermissionsForRole',
  async ({ addPermissions, removePermissions }, { dispatch, getState, rejectWithValue }) => {
    try {
      const { role } = getState().roleInfo;

      const { data, status } = await axios.post(`/roles/${role.id}/permissions`, {
        add_permissions: addPermissions || [],
        remove_permissions: removePermissions || [],
      });

      if (status === httpStatusCodes.NO_CONTENT) {
        dispatch(getPermissionsByRoleId({ id: role.id }));
        return data;
      }

      return rejectWithValue(data.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateUsersForRole = createAsyncThunk(
  'roleInfo/updateUsersForRole',
  async ({ addUsers, removeUsers }, { dispatch, getState, rejectWithValue }) => {
    try {
      const { role } = getState().roleInfo;

      const { data, status } = await axios.post(`/roles/${role.id}/users`, {
        add_users: addUsers || [],
        remove_users: removeUsers || [],
      });

      if (status === httpStatusCodes.NO_CONTENT) {
        dispatch(getUsersByRoleId({ id: role.id }));
        return data;
      }

      return rejectWithValue(data.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const roleInfoSlice = createSlice({
  name: 'roleInfo',
  initialState,
  reducers: {
    resetState: () => initialState,
    setData: (state, action) => ({
      ...state, ...action.payload
    }),
    setModalData: (state, action) => {
      state.modal = { ...state.modal, ...action.payload };
    },
    setPermissionsData: (state, action) => {
      state.permissionsData = { ...state.permissionsData, ...action.payload };
    },
    setEditRoleData: (state, action) => {
      state.editRole = { ...(state.editRole || {}), ...action.payload };
    },
    setUsersFilters: (state, action) => {
      state.usersQueryParams.filters = { ...state.usersQueryParams.filters, ...action.payload };
      state.usersQueryParams.page = 0;
    },
    setUsersPage: (state, action) => {
      state.usersQueryParams.page = action.payload;
    },
    resetUserModal: (state) => {
      state.modal = initialState.modal;
      state.addUsers = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getRoleById.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getRoleById.fulfilled, (state, action) => {
        state.role = action.payload;
        state.editRole = action.payload;
        state.isLoading = false;
      })
      .addCase(getRoleById.rejected, (state) => {
        state.isLoading = false;
      });

    builder
      .addCase(getPermissions.fulfilled, (state, action) => {
        state.permissions = action.payload;
      });

    builder
      .addCase(getPermissionsByRoleId.fulfilled, (state, action) => {
        state.rolePermissions = action.payload.data;
        state.isMembersTab = false;
      });

    builder
      .addCase(getUsersByRoleId.pending, (state) => {
        state.isUsersLoading = true;
      })
      .addCase(getUsersByRoleId.fulfilled, (state, action) => {
        state.roleUsers = action.payload;
        state.isUsersLoading = false;
      })
      .addCase(getUsersByRoleId.rejected, (state) => {
        state.isUsersLoading = false;
      });

    builder
      .addCase(updatePermissionsForRole.pending, (state) => {
        state.isPermissionsUpdating = true;
      })
      .addCase(updatePermissionsForRole.fulfilled, (state) => {
        state.isPermissionsUpdating = false;
        state.permissionsData = initialState.permissionsData;
        state.modal = initialState.modal;
        state.deletePermission = initialState.deletePermission;
        state.isDeleteAll = initialState.isDeleteAll;
      })
      .addCase(updatePermissionsForRole.rejected, (state) => {
        state.isPermissionsUpdating = false;
      });

    builder
      .addCase(updateUsersForRole.pending, (state) => {
        state.isUsersUpdating = true;
      })
      .addCase(updateUsersForRole.fulfilled, (state) => {
        state.isUsersUpdating = false;
        state.deleteUserModalId = null;
        state.modal = initialState.modal;
        state.addUsers = [];
      })
      .addCase(updateUsersForRole.rejected, (state) => {
        state.isUsersUpdating = false;
      });

    builder
      .addCase(updateRoleById.fulfilled, (state, action) => {
        state.role = action.payload;
        state.editRole = action.payload;
      })
      .addCase(updateRoleById.rejected, (state) => {
        state.editRole = state.role;
      });
  }
});

export const {
  resetState,
  setData,
  setModalData,
  setPermissionsData,
  setEditRoleData,
  setUsersFilters,
  setUsersPage,
  resetUserModal,
} = roleInfoSlice.actions;

export default roleInfoSlice.reducer;
