// Country Code
export const COUNTRY_CODES = '/country_codes';
export const COUNTRY_CODES_ID = '/country_codes/{country_code_id}';

// Country Code Subdivision
export const COUNTRY_CODE_SUBDIVISIONS = '/country_code_subdivisions';
export const COUNTRY_CODE_SUBDIVISIONS_ID = '/country_code_subdivisions/{country_code_subdivision_id}';

// Search
export const GLOBAL_SEARCH = '/search';

// Expense Report
export const EXPENSE_REPORTS = '/expense_reports';
export const EXPENSE_REPORTS_ID = '/expense_reports/{expense_report_id}';
export const EXPENSE_REPORTS_ID_APPROVE = '/expense_reports/{expense_report_id}/approve';
export const EXPENSE_REPORTS_ID_REJECT = '/expense_reports/{expense_report_id}/reject';

// Salary / Finance Rate
export const FINANCE_RATE = '/finance_rates';
export const FINANCE_RATE_ID = '/finance_rates/{finance_rate_id}';
