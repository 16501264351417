import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { shallowEqual } from 'react-redux';
import { useTranslation } from 'next-i18next';
import Link from './components/SidebarLink';
import Versions from './components/Versions';
import { fetchMainBadges, toggleSidebarMinimizedAction, toggleSidebarMobileAction } from '../../store/sidebar/actions';
import { isAllowed } from '../../utils/helpers';
import classNames from '../../utils/classNames';
import { useDispatch as useReduxDispatch, useSelector as useReduxSelector } from '../../hooks/redux';
import { useSelector } from '../../hooks/reduxToolkit';
import useMediaQuery from '../../hooks/useMediaQuery';
import { ALL_USERS } from '../../constants/permissions';

import styles from './sass/Sidebar.module.scss';

const Sidebar = () => {
  const router = useRouter();
  const dispatch = useReduxDispatch();
  const { t } = useTranslation('common');
  const isMobile = useMediaQuery('(max-width: 767px)');
  const isSmallMobile = useMediaQuery('(max-width: 420px)');

  const isMinimized = useReduxSelector(({ sidebar }) => sidebar.isMinimized, shallowEqual);
  const isMobileOpen = useReduxSelector(({ sidebar }) => sidebar.isMobileOpen, shallowEqual);
  const sidebarMenu = useReduxSelector(({ sidebar }) => sidebar.sidebarMenu, shallowEqual);
  const {
    mainReviewCount,
    vacationCount,
    invoiceRecommendedCount,
    subscriptionsCount
  } = useReduxSelector(({ sidebar }) => sidebar.badges, shallowEqual);

  const userPermissions = useSelector(({ user }) => user.permissions, shallowEqual);
  const profileId = useSelector(({ user }) => user.profile.id, shallowEqual);

  const classes = classNames(styles.sidebar, {
    [styles.minimized]: isMinimized,
    [styles.mobile]: isMobileOpen,
  });

  const getActivePathname = (pathname) => {
    const end = pathname.indexOf('/', 1);
    return pathname.substring(1, end !== -1 ? end : pathname.length);
  };

  const notifications = {
    review: mainReviewCount,
    vacation: vacationCount,
    invoices: invoiceRecommendedCount,
    subscriptions: subscriptionsCount ? '+1' : '',
  };

  const handleBadgeClick = (badgeHref) => (badgeHref
    ? (e) => {
      e.preventDefault();
      e.stopPropagation();
      router.push(badgeHref);
    }
    : undefined);

  useEffect(() => {
    if (isSmallMobile) {
      dispatch(toggleSidebarMinimizedAction(true));
    } else {
      dispatch(toggleSidebarMinimizedAction(false));
    }
  }, [isSmallMobile]);

  useEffect(() => {
    dispatch(fetchMainBadges(userPermissions, profileId));
  }, []);

  useEffect(() => {
    dispatch(toggleSidebarMobileAction(false));
    dispatch(toggleSidebarMinimizedAction(false));
    document.body.style = undefined;
  }, [isMobile]);

  useEffect(() => {
    const handleRouteChange = () => {
      if (isMobile && isMobileOpen) {
        dispatch(toggleSidebarMobileAction(false));
      }
    };
  
    router.events.on('routeChangeComplete', handleRouteChange);
    
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [isMobile, isMobileOpen, dispatch, router.events]);

  return (
    <nav className={classes}>
      <div className={styles.mainNav}>
        <ul className={styles.nav}>
          {!isMobile && (
            <li className={styles.resizeBtn}>
              <button
                className={styles.toggleSidebarButton}
                type="button"
                aria-label="navigation"
                onClick={() => dispatch(toggleSidebarMinimizedAction(!isMinimized))}
              >
                <span className={`${styles.toggleIcon} ${isMinimized ? styles.open : ''}`} />
                <span>{t('hideSidebar')}</span>
              </button>
            </li>
          )}
          {sidebarMenu.map((item) => isAllowed(item.permissions, [...userPermissions, ALL_USERS]) && (
            <Link
              key={item.tKey}
              text={t(item.tKey)}
              active={item.activePath.includes(getActivePathname(router.pathname)) && router.pathname !== '/'}
              link={item.href}
              icon={item.icon}
              numberNotification={(item.notification && notifications[item.tKey]) ?? 0}
              onBadgeClick={handleBadgeClick(item.badgeHref)}
            />
          ))}
        </ul>
        <Versions isMinimized={isMinimized} />
      </div>
    </nav>
  );
};

export default Sidebar;
