import PropTypes from 'prop-types';
import { LOADER_COLORS, LOADER_COLOR_WHITE } from '../../constants/common';
import styles from './sass/BallPulse.module.scss';

const BallPulse = ({ className = '', color = LOADER_COLOR_WHITE }) => (
  <div className={`${styles.wrapper} ${styles[color]} ${className}`}>
    <div />
    <div />
    <div />
  </div>
);

BallPulse.propTypes = {
  className: PropTypes.string,
  color: PropTypes.oneOf(LOADER_COLORS),
};

export default BallPulse;
