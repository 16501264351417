export const SHOW_COLUMN_SALARY_BASE = 'show-column-salary-base';
export const SHOW_COLUMN_SALARY_FOP = 'show-column-salary-fop';
export const SHOW_COLUMN_SALARY_BONUS = 'show-column-salary-bonus';
export const SHOW_COLUMN_SALARY_CORRECTION = 'show-column-salary-correction';
export const SHOW_INACTIVE_SALARY = 'show-inactive-salary';
export const SHOW_SALARY_EMPLOYEE = 'show-salary-employee';
export const SHOW_SALARY_PAYMENT_EMPLOYEE = 'show-salary-payment-employee';
export const SALARY_FILTERS = 'salary-filters';
export const SALARY_LOAN_BALANCE_EMPLOYEE = 'salary-loan-balance-employee';
export const SHOW_COLUMN_CLIENT_AGREEMENTS = 'show-column-client-agreements';
export const SHOW_COLUMN_ASSETS = 'show-column-assets';
export const SHOW_OWN_PROJECTS = 'show-own-projects';
export const SHOW_COLUMN_PROJECTS = 'show_column_projects';
export const SHOW_SUBSCRIPTIONS = 'show-subscriptions';
export const VACATION_CALENDAR_FILTER = 'vacation-calendar-filter';
