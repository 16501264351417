import PropTypes from 'prop-types';

const Alert = ({ width = 24, height = 22 }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M23.5129 16.8582L15.0255 1.67386C14.3849 0.625888 13.2535 0 11.9991 0C10.7447 0 9.61335 0.625888 8.97276 1.67386C8.97034 1.67787 8.96812 1.68188 8.96589 1.68589L8.96585 1.68595C8.96364 1.68994 8.96142 1.69393 8.95902 1.69791L0.499755 16.8341C-0.154574 17.9042 -0.166949 19.1893 0.466052 20.2714C1.10051 21.3541 2.24351 22 3.5254 22H20.4259C21.7078 22 22.8978 21.3541 23.5322 20.2714C24.1652 19.1893 24.1529 17.9042 23.5129 16.8582ZM10.5913 6.95289C10.5913 6.19737 11.2215 5.58496 11.999 5.58496C12.7764 5.58496 13.4066 6.19741 13.4066 6.95289V12.4247C13.4066 13.1801 12.7764 13.7926 11.999 13.7926C11.2215 13.7926 10.5913 13.1801 10.5913 12.4247V6.95289ZM9.88757 17.2122C9.88757 18.3437 10.8348 19.2641 11.9991 19.2641C13.1635 19.2641 14.1106 18.3437 14.1106 17.2122C14.1106 16.0807 13.1634 15.1603 11.9991 15.1603C10.8347 15.1603 9.88757 16.0807 9.88757 17.2122Z" fill="#EDA61F" />
    </svg>
  );
};

Alert.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default Alert;
