import PropTypes from 'prop-types';

const ArrowLink = ({
  width = 12, height = 12, fill = 'currentColor', className = ''
}) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 12 12"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 11L11 1M11 1H2M11 1V10"
        stroke="#8F939B"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>

  );
};

ArrowLink.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  fill: PropTypes.string,
  className: PropTypes.string,
};

export default ArrowLink;
