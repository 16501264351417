import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../services/axios';
import { SORT_BY_NAME } from '../../constants/common';
import { LIST_SIZE_PER_PAGE } from '../../constants/values';
import { validateErrors, validateSystemsTrackersModal } from '../../utils/validators';
import httpRequestMethods from '../../constants/httpRequestMethods';

export const initialState = {
  data: [],
  links: {},
  meta: {},
  isSubmit: false,
  isDataLoading: true,
  isDeleteSubmit: false,
  deleteModalId: null,
  queryParams: {
    filters: {
      name: '',
      activity: true,
    },
    page: null,
    per_page: LIST_SIZE_PER_PAGE,
    sort: SORT_BY_NAME,
  },
  trackerHeadValue: '',
  systemsTrackersModal: {
    modalId: 'systemsTrackersModal',
    isModalOpen: false,
    trackerId: '',
    isActive: true,
    trackerName: '',
    email: '',
    url: '',
    apiToken: '',
    tempoApiToken: '',
    timezone: '',
    onlyProjects: [],
    skipProjects: [],
    internalManage: false,
    importTickets: true,
    importJiraWorklogs: false,
    importTempoWorklogs: true,
    description: ''
  },
  modalErrors: {}
};

export const getTrackers = createAsyncThunk(
  'systemsTrackers/getTrackers',
  async (arg, { getState, rejectWithValue }) => {
    try {
      const { queryParams } = getState().systemsTrackers;
      const { data, status } = await axios.get('/trackers', { params: queryParams });

      return status === 200 ? data : rejectWithValue(data.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteTracker = createAsyncThunk(
  'systemsTrackers/deleteTracker',
  async ({ id }, { dispatch, rejectWithValue }) => {
    try {
      const { data, status } = await axios.delete(`/trackers/${id}`);

      if (status === 204) {
        dispatch(getTrackers());

        return data;
      }

      return rejectWithValue(data.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const addOrEditTrackers = createAsyncThunk(
  'systemsTrackers/addOrEditTrackers',
  async ({ id }, { dispatch, getState, rejectWithValue }) => {
    const { systemsTrackersModal } = getState().systemsTrackers;
    const errors = validateErrors(
      systemsTrackersModal,
      validateSystemsTrackersModal,
      { tempoApiTokenRequired: systemsTrackersModal.importTempoWorklogs }
    );

    if (errors) {
      return rejectWithValue({ isFormValidation: true, errors });
    }

    try {
      let url = '/trackers';
      let method = httpRequestMethods.POST;

      if (id) {
        url += `/${id}`;
        method = httpRequestMethods.PUT;
      }

      const { data, status } = await axios({
        url,
        method,
        data: {
          name: systemsTrackersModal.trackerName,
          email: systemsTrackersModal.email,
          url: systemsTrackersModal.url,
          api_token: systemsTrackersModal.apiToken,
          tempo_api_token: systemsTrackersModal.tempoApiToken,
          only_projects: systemsTrackersModal.onlyProjects?.filter((item) => !!item),
          skip_projects: systemsTrackersModal.skipProjects?.filter((item) => !!item),
          internal_manage: systemsTrackersModal.internalManage,
          import_tickets: systemsTrackersModal.importTickets,
          import_jira_worklogs: systemsTrackersModal.importJiraWorklogs,
          import_tempo_worklogs: systemsTrackersModal.importTempoWorklogs,
          description: systemsTrackersModal.description,
          timezone: systemsTrackersModal.timezone,
          activity: systemsTrackersModal.isActive
        }
      });

      if (status === 200 || status === 201) {
        dispatch(getTrackers());

        return data;
      }

      return rejectWithValue(data.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const systemsTrackersSlice = createSlice({
  name: 'systemsTrackers',
  initialState,
  reducers: {
    setSubmit: (state, action) => {
      state[action.payload.key] = action.payload.value;
    },
    setPage: (state, action) => {
      state.queryParams.page = action.payload.page;
    },
    setModalData: (state, action) => {
      state.systemsTrackersModal = { ...state.systemsTrackersModal, ...action.payload };
    },
    setModalErrors: (state, action) => {
      state.modalErrors = action.payload;
    },
    setTableHeadInput: (state, action) => {
      state.trackerHeadValue = action.payload;
    },
    setQueryParamsFilters: (state, action) => {
      state.queryParams.filters = { ...state.queryParams.filters, ...action.payload };
      state.queryParams.page = null;
    },
    setSort: (state, action) => {
      state.queryParams.sort = action.payload;
    },
    resetModal: (state) => {
      state.modalErrors = initialState.modalErrors;
      state.systemsTrackersModal = initialState.systemsTrackersModal;
      state.deleteModalId = null;
    },
    setData: (state, action) => {
      return ({ ...state, ...action.payload });
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTrackers.pending, (state) => {
        state.isDataLoading = true;
      })
      .addCase(getTrackers.fulfilled, (state, action) => {
        state.isDataLoading = false;
        state.data = action.payload.data;
        state.links = action.payload.links;
        state.meta = action.payload.meta;
      })
      .addCase(getTrackers.rejected, (state) => {
        state.isDataLoading = false;
      });
    builder
      .addCase(addOrEditTrackers.pending, (state) => {
        state.isSubmit = true;
      })
      .addCase(addOrEditTrackers.fulfilled, (state) => {
        state.isSubmit = false;
        systemsTrackersSlice.caseReducers.resetModal(state);
      })
      .addCase(addOrEditTrackers.rejected, (state, action) => {
        state.isSubmit = false;
        if (action.payload?.isFormValidation) {
          state.modalErrors = action.payload.errors;
        }
      });
    builder
      .addCase(deleteTracker.pending, (state) => {
        state.isDeleteSubmit = true;
      })
      .addCase(deleteTracker.fulfilled, (state) => {
        state.isDeleteSubmit = false;
        systemsTrackersSlice.caseReducers.resetModal(state);
      })
      .addCase(deleteTracker.rejected, (state) => {
        state.isDeleteSubmit = false;
      });
  }
});

export const {
  setSubmit,
  setPage,
  setModalData,
  setModalErrors,
  setTableHeadInput,
  setQueryParamsFilters,
  setSort,
  resetModal,
  setData,
} = systemsTrackersSlice.actions;

export default systemsTrackersSlice.reducer;
