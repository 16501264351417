export const INVOICE_RECOMMENDED_SET_ABORT = 'INVOICE_RECOMMENDED_SET_ABORT';
export const INVOICE_RECOMMENDED_FETCH_INVOICES = 'INVOICE_RECOMMENDED_FETCH_INVOICES';
export const INVOICE_RECOMMENDED_SET_QUERY_PARAMS = 'INVOICE_RECOMMENDED_SET_QUERY_PARAMS';
export const INVOICE_RECOMMENDED_SET_SORT = 'INVOICE_RECOMMENDED_SET_SORT';
export const INVOICE_RECOMMENDED_SET_TABLE_HEAD_INPUT = 'INVOICE_RECOMMENDED_SET_TABLE_HEAD_INPUT';
export const INVOICE_RECOMMENDED_SET_EXPANDED_ROW = 'INVOICE_RECOMMENDED_SET_EXPANDED_ROW';
export const INVOICE_RECOMMENDED_SET_MODAL_DATA = 'INVOICE_RECOMMENDED_SET_MODAL_DATA';
export const INVOICE_RECOMMENDED_SET_DATA = 'INVOICE_RECOMMENDED_SET_DATA';
export const INVOICE_RECOMMENDED_FETCH_COMMENTS = 'INVOICE_RECOMMENDED_FETCH_COMMENTS';
export const INVOICE_RECOMMENDED_TOGGLE_COMMENT_ACTION = 'INVOICE_RECOMMENDED_TOGGLE_COMMENT_ACTION';
export const INVOICE_RECOMMENDED_SET_LOG_ERROR = 'INVOICE_RECOMMENDED_SET_LOG_ERROR';
export const INVOICE_RECOMMENDED_SET_PAGE = 'INVOICE_RECOMMENDED_SET_PAGE';
export const INVOICE_RECOMMENDED_SET_DATEPICKER_DATA = 'INVOICE_RECOMMENDED_SET_DATEPICKER_DATA';
export const INVOICE_RECOMMENDED_RESET_DATEPICKER = 'INVOICE_RECOMMENDED_RESET_DATEPICKER';
export const INVOICES_RECOMMENDED_SET_INITIAL_STATE = 'INVOICES_RECOMMENDED_SET_INITIAL_STATE';
export const INVOICES_RECOMMENDED_SET_COMMENT_SUBMITTING = 'INVOICES_RECOMMENDED_SET_COMMENT_SUBMITTING';
