import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../services/axios';
import { NO_CONTENT } from '../../constants/httpStatusCodes';

export const initialState = {
  isLoading: false,
  error: undefined,
  data: undefined,
  modalProps: {
    isModalOpen: false,
    modalId: 'buildReport',
  }
};

export const getBuildReportStats = createAsyncThunk(
  'buildReportStats/getGlobalSearch',
  async (params, { rejectWithValue }) => {
    try {
      const { data, status } = await axios.get('/reports/build_report_stats');

      return status === NO_CONTENT ? data : rejectWithValue(data.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const buildReportStatsSlice = createSlice({
  name: 'buildReportStats',
  initialState,
  reducers: {
    resetState: () => initialState,
    setModalProps: (state, action) => {
      state.modalProps = { ...state.modalProps, ...action.payload };
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBuildReportStats.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getBuildReportStats.fulfilled, (state) => {
        state.isLoading = false;
        state.modalProps.isModalOpen = true;
      })
      .addCase(getBuildReportStats.rejected, (state, action) => {
        state.error = action.payload;
        state.isLoading = false;
      });
  },
});

export const {
  resetState,
  setModalProps
} = buildReportStatsSlice.actions;

export default buildReportStatsSlice.reducer;
