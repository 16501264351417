// Global roles
export const ROLE_ADMIN = 'Admin';
export const ROLE_ACCOUNTANT = 'Accountant';
export const ROLE_HR = 'HR';
export const ROLE_SALES_MANAGER = 'Sales Manager';
export const ROLE_PROJECT_MANAGER = 'Project Manager';
export const ROLE_DEVELOPER = 'Developer';
export const ROLE_EMPLOYEE = 'Employee';
export const ROLE_TEACH_LEAD = 'Tech Lead';
export const ROLE_HEAD_OF_DEPARTMENT = 'Head Of Department';
export const ROLE_PMO = 'PMO';
export const ROLE_EXECUTIVE_TEAM = 'Executive Team';
export const ROLE_PEOPLE_PERTNER = 'People Partner';

// Projects roles
export const PROJECT_ROLE_TESTER = 'Tester';
export const PROJECT_ROLE_DESIGNER = 'Designer';
export const PROJECT_ROLE_DEVELOPER = 'Developer';
export const PROJECT_ROLE_SALES_MANAGER = 'Sales Manager';
export const PROJECT_ROLE_PROJECT_MANAGER = 'Project Manager';

// Default roles
export const DEFAULT_ROLES = [
  ROLE_ADMIN, ROLE_ACCOUNTANT, ROLE_HR, ROLE_SALES_MANAGER, ROLE_PROJECT_MANAGER, ROLE_DEVELOPER, ROLE_EMPLOYEE,
  ROLE_TEACH_LEAD, PROJECT_ROLE_TESTER, PROJECT_ROLE_DESIGNER, PROJECT_ROLE_DEVELOPER, PROJECT_ROLE_SALES_MANAGER,
  PROJECT_ROLE_PROJECT_MANAGER, ROLE_HEAD_OF_DEPARTMENT, ROLE_PMO, ROLE_EXECUTIVE_TEAM,
];

// Head of department roles
export const HEAD_OF_DEPARTMENT_ROLES = [
  ROLE_ADMIN,
  ROLE_PROJECT_MANAGER,
  ROLE_SALES_MANAGER,
  ROLE_TEACH_LEAD,
  ROLE_HEAD_OF_DEPARTMENT,
];

// Lead of department roles
export const LEAD_OF_DEPARTMENT_ROLES = [ROLE_ADMIN, ROLE_PROJECT_MANAGER, ROLE_SALES_MANAGER, ROLE_TEACH_LEAD];

// People partner manage roles
export const PEOPLE_PARTNER_MANAGE_ROLES = [ROLE_ADMIN, ROLE_HR, ROLE_PEOPLE_PERTNER];
