import { MAX_FETCH_DATA_PER_PAGE } from '../constants/values';
import { REVIEW_STATUS_NEW } from '../constants/status';

const getWorkLogErrors = (worklog) => {
  let errors = [];

  if (worklog.milestone_item === null || !worklog.milestone_item) {
    errors = [...errors, 'itemIdError'];
  }

  if (worklog.billed_time !== worklog.approved_time) {
    errors = [...errors, 'billedTimeError'];
  }

  if (worklog.billed_time > worklog.reported_time) {
    errors = [...errors, 'billedMoreError'];
  }

  return errors;
};

const parseExistData = (existData, newData) => {
  const newWorklogs = newData
    .filter((worklog) => !existData.some(({ id }) => id === worklog.id))
    .map((worklog) => ({
      ...worklog,
      worklogErrors: worklog.errors,
      errors: getWorkLogErrors(worklog),
    }));

  return { data: existData, newWorklogs };
};

const parseNewData = (newData) => {
  const data = newData.map((worklog) => {
    const singleItem = worklog.milestone_items?.length === 1 && worklog.status === REVIEW_STATUS_NEW
      ? worklog.milestone_items[0] : null;
    const autoBilledItem = worklog.milestone_items?.length > 0 && worklog.status === REVIEW_STATUS_NEW
      ? worklog.milestone_items.find((item) => item.auto_billed) : null;

    const tempWorklog = { ...worklog, worklogErrors: worklog.errors };

    if (worklog.status === REVIEW_STATUS_NEW) {
      tempWorklog.approved_time = worklog.reported_time;
    }

    if (singleItem) {
      tempWorklog.milestone_item = singleItem;

      if (singleItem.auto_billed) {
        tempWorklog.billed_time = worklog.reported_time;
      }
    } else if (autoBilledItem) {
      tempWorklog.milestone_item = autoBilledItem;
      tempWorklog.billed_time = worklog.reported_time;
    }

    tempWorklog.errors = getWorkLogErrors(tempWorklog);

    return tempWorklog;
  });

  return { data, newWorklogs: [] };
};

export const parseReviewData = (newData, prevData) => {
  const { data, newWorklogs } = prevData?.length > 0 ? parseExistData(prevData, newData) : parseNewData(newData);

  return [...data, ...newWorklogs].slice(0, MAX_FETCH_DATA_PER_PAGE);
};

export const getApproveWorklogs = (reviewData, checkedArray) => {
  return reviewData.filter((item) => checkedArray.includes(item.id))
    .reduce((acc, item) => {
      const isClient = item.project.type === 'client';

      const isValidWorklog = item.id
        && item.type
        && item.approved_time >= 0
        && item.billed_time >= 0
        && (isClient ? item.milestone_item?.id : true);

      if (isValidWorklog) {
        acc.push({
          id: item.id,
          type: item.type,
          approved_time: item.approved_time,
          billed_time: item.billed_time,
          ...(isClient ? { milestone_item_id: item?.milestone_item.id || null } : {})
        });
      }

      return acc;
    }, []);
};
