import styles from './sass/Loader.module.scss';

const Loader = () => (
  <img
    className={styles.loader}
    src="/images/gif/loader.gif"
    alt="loader"
  />
);

export default Loader;
