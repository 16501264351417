export const PROJECT_PEOPLE_FETCH_PEOPLE = 'PROJECT_PEOPLE_FETCH_PEOPLE';
export const PROJECT_PEOPLE_SET_PAGE = 'PROJECT_PEOPLE_SET_PAGE';
export const PROJECT_PEOPLE_SET_ABORT = 'PROJECT_PEOPLE_SET_ABORT';
export const PROJECT_PEOPLE_SET_QUERY_PARAMS_FILTERS = 'PROJECT_PEOPLE_SET_QUERY_PARAMS_FILTERS';
export const PROJECT_PEOPLE_HANDLE_USER_INPUT = 'PROJECT_PEOPLE_HANDLE_USER_INPUT';
export const PROJECT_PEOPLE_TOGGLE_REMOVE_MODAL = 'PROJECT_PEOPLE_TOGGLE_REMOVE_MODAL';
export const PROJECT_PEOPLE_TOGGLE_ADD_MODAL = 'PROJECT_PEOPLE_TOGGLE_ADD_MODAL';
export const PROJECT_PEOPLE_FETCH_ROLES = 'PROJECT_PEOPLE_FETCH_ROLES';
export const PROJECT_PEOPLE_SELECT_USER = 'PROJECT_PEOPLE_SELECT_USER';

export const PROJECT_PEOPLE_ADD_MODAL_SET_DATA = 'PROJECT_PEOPLE_ADD_MODAL_SET_DATA';
export const PROJECT_PEOPLE_ADD_MODAL_SET_ERROR = 'PROJECT_PEOPLE_ADD_MODAL_SET_ERROR';
export const PROJECT_PEOPLE_TOGGLE_TABLE_DROPDOWN = 'PROJECT_PEOPLE_TOGGLE_TABLE_DROPDOWN';
export const PROJECT_PEOPLE_FETCH_GROUPS = 'PROJECT_PEOPLE_FETCH_GROUPS';
export const PROJECT_PEOPLE_SET_SUBMIT = 'PROJECT_PEOPLE_SET_SUBMIT';
export const PROJECT_PEOPLE_SET_SORT = 'PROJECT_PEOPLE_SET_SORT';
