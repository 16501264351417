import PropTypes from 'prop-types';
import { shallowEqual } from 'react-redux';
import ErrorPage from '../../../pages/_error';
import { useSelector } from '../../hooks/reduxToolkit';
import { isAllowed } from '../../utils/helpers';
import { ALL_USERS } from '../../constants/permissions';
import { FORBIDDEN } from '../../constants/httpStatusCodes';

const PermissionGuard = ({ permissions = [ALL_USERS], children }) => {
  const userPermissions = useSelector(({ user }) => user.permissions, shallowEqual);

  return (
    isAllowed(permissions, [ALL_USERS, ...userPermissions])
      ? children
      : <ErrorPage statusCode={FORBIDDEN} />
  );
};

PermissionGuard.propTypes = {
  permissions: PropTypes.arrayOf(PropTypes.string),
  children: PropTypes.node.isRequired,
};

export default PermissionGuard;
