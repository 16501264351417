import {
  addDays,
  differenceInCalendarDays,
  isFirstDayOfMonth,
  isLastDayOfMonth,
  isSameDay,
  isSunday,
  isToday,
  isWeekend,
  addMinutes,
} from 'date-fns';
import axios from '../services/axios';
import { isEmpty, moveElement, removeValuesFromArray } from './arrayHelpers';
import { isEmpty as isObjectEmpty } from './objectHelpers';
import { formatStringDate, parseHolidaysData, parseWorklogTimeToSeconds } from './helpers';
import {
  HOLIDAY,
  MONTHS,
  MONTHS_SHORT,
  TIMESHEET_TIME_TYPES,
  WEEKDAYS_SHORT,
  WEEKEND,
  WORKDAY,
} from '../constants/common';
import { localStorageHelper } from './storageHelper';
import { parseRouterQuery } from './routerQuery';

export const timeTypeMap = {
  reported_time: 'isShowReportedTime',
  approved_time: 'isShowApprovedTime',
  billed_time: 'isShowBilledTime',
};

const keysByGroup = {
  user_id: 'users',
  project_id: 'projects',
  ticket_id: 'tickets',
  client_id: 'clients',
  department_id: 'departments',
  milestone_item_id: 'milestone_items',
};

const emptyTimes = {
  reported: 0,
  over_reported: 0,
  approved: 0,
  over_approved: 0,
  billed: 0,
  over_billed: 0,
};

const emptyWorklog = {
  date: null,
  worklog_ids: [],
  times: emptyTimes,
  npaTimes: [],
  ...emptyTimes,
};

const getFixTimezoneDate = (date) => {
  const currentDate = new Date();
  const currentTimezoneOffset = currentDate.getTimezoneOffset();

  return currentTimezoneOffset > 0 ? addMinutes(date, currentTimezoneOffset) : date;
};

export const getDayType = (date, holidays = []) => {
  let type;
  const isHoliday = holidays.find((holiday) => isSameDay(holiday, date));

  if (isHoliday) {
    type = HOLIDAY;
  } else if (isWeekend(date)) {
    type = WEEKEND;
  } else {
    type = WORKDAY;
  }

  return type;
};

export const secondsToTimeString = (seconds) => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds - (hours * 3600)) / 60);

  return `${hours}${minutes > 0 ? `:${minutes < 10 ? `0${minutes}` : minutes}` : ''}`;
};

const timeToString = (time) => {
  return time > 0 ? secondsToTimeString(time) : null;
};

export const getObjectWithTimeStrings = (data) => {
  return {
    reported_str: timeToString(data.reported),
    billed_str: timeToString(data.billed),
    approved_str: timeToString(data.approved),
    over_reported_str: timeToString(data.over_reported),
    over_billed_str: timeToString(data.over_billed),
    over_approved_str: timeToString(data.over_approved),
  };
};

export const getTotalTimeByWorklogs = (worklogs) => {
  const times = TIMESHEET_TIME_TYPES.reduce((acc, type) => ({
    ...acc,
    [type]: worklogs.reduce((sum, item) => (sum + item[type]), 0),
  }), emptyTimes);

  return { ...times, ...getObjectWithTimeStrings(times) };
};

const mergeTime = (elem1, elem2) => {
  return {
    reported: elem1.reported + elem2.reported,
    over_reported: elem1.over_reported + elem2.over_reported,
    approved: elem1.approved + elem2.approved,
    over_approved: elem1.over_approved + elem2.over_approved,
    billed: elem1.billed + elem2.billed,
    over_billed: elem1.over_billed + elem2.over_billed,
  };
};

export const getWorklogType = (worklog) => {
  let type = null;

  if (worklog.ticket_id === process.env.NEXT_PUBLIC_DAYOFF_TICKET_ID) {
    type = 'dayOff';
  }

  if (worklog.ticket_id === process.env.NEXT_PUBLIC_VACATION_TICKET_ID) {
    type = 'vacation';
  }

  if (worklog.ticket_id === process.env.NEXT_PUBLIC_SICKNESS_TICKET_ID) {
    type = 'sickness';
  }

  return type;
};

export const composeNPATimes = (type, existData, incomingData) => {
  const npaTimes = [...existData];
  const existingIndex = npaTimes.findIndex((obj) => obj.type === type);

  if (existingIndex !== -1) {
    npaTimes[existingIndex] = {
      type,
      ...mergeTime(npaTimes[existingIndex], incomingData),
    };
  } else {
    const newObj = {
      type,
      ...mergeTime(emptyTimes, incomingData),
    };
    npaTimes.push(newObj);
  }

  return npaTimes;
};

export const updateNPATimes = (times) => {
  return times.reduce((acc, item) => {
    return {
      isApprovedError: item.reported !== item.approved,
      times: [...acc.times, { ...item, ...getObjectWithTimeStrings(item) }],
    };
  }, { isApprovedError: false, times: [] });
};

const updateWorklogs = (
  worklogs,
  days,
  useBuilder,
  useBuilderDataRanges,
  isDisplayNpaEmoji = false,
  additionalData = {},
) => {
  return days.reduce((acc, item) => {
    const tempWorklogs = worklogs.filter((worklog) => {
      return isSameDay(getFixTimezoneDate(new Date(worklog.date)), item.date);
    });

    const tempWorklog = tempWorklogs.reduce((worklogAcc, worklog) => {
      const type = isDisplayNpaEmoji ? getWorklogType(worklog) : null;
      const times = type ? {
        npaTimes: composeNPATimes(type, worklogAcc.npaTimes || [], worklog)
      } : {
        times: mergeTime(worklog, worklogAcc.times)
      };

      return {
        ...worklogAcc,
        ...times,
        ...mergeTime(worklog, worklogAcc),
        worklog_ids: [...worklogAcc.worklog_ids, ...worklog.worklog_ids]
      };
    }, { ...emptyWorklog, date: formatStringDate(item.date, 'y-MM-dd') });

    const npaTimes = updateNPATimes(tempWorklog.npaTimes);

    const isApprovedError = () => {
      let result;

      if (useBuilder) {
        const currentData = new Date(tempWorklog.date);
        const { startDate, endDate } = useBuilderDataRanges;

        result = npaTimes.isApprovedError
          || (tempWorklog.times.approved !== tempWorklog.times.billed
          && (currentData >= startDate && currentData <= endDate));
      } else {
        result = npaTimes.isApprovedError
          || tempWorklog.times.reported !== tempWorklog.times.approved
          || tempWorklog.times.over_reported !== tempWorklog.times.over_approved;
      }
      return result;
    };

    return [...acc, {
      ...tempWorklog,
      ...additionalData,
      times: { ...tempWorklog.times, ...getObjectWithTimeStrings(tempWorklog.times) },
      npaTimes: npaTimes.times,
      isLogged: tempWorklog.times.reported > 0 || tempWorklog.times.over_reported > 0 || !isEmpty(tempWorklog.npaTimes),
      isApprovedError: isApprovedError(),
      day: item,
    }];
  }, []);
};

export const getDays = (startDate, endDate, holidays = []) => {
  const dates = [];
  let date = getFixTimezoneDate(new Date(startDate));
  const end = getFixTimezoneDate(new Date(endDate));
  const differenceDays = differenceInCalendarDays(end, date);

  for (let i = 0; i <= differenceDays; i += 1) {
    const day = date.getDay();
    const monthIndex = date.getMonth();

    dates.push({
      number: date.getDate().toString().padStart(2, '0'),
      name: WEEKDAYS_SHORT[day],
      type: getDayType(date, holidays),
      month: {
        name: MONTHS[monthIndex],
        shortName: MONTHS_SHORT[monthIndex],
        label: formatStringDate(date, 'MMMM y'),
        shortLabel: formatStringDate(date, 'MMM y'),
        number: monthIndex,
      },
      isToday: isToday(date),
      date,
      isFirstDayOfMonth: isFirstDayOfMonth(date) || i === 0,
    });
    date = addDays(date, 1);
  }

  return dates;
};

const composeRowData = (key, data) => {
  const compose = { id: data.id, name: data.name };

  switch (key) {
    case 'users':
      compose.status = data.status;
      compose.removed_from_project = data.removed_from_project || false;
      compose.photo48_link = data.photo48_link;
      break;
    case 'projects':
      compose.manager = data.manager;
      compose.status = data.status;
      compose.allow_overtime = data.allow_overtime;
      break;
    case 'clients':
      compose.status = data.status;
      break;
    case 'tickets':
      compose.key = data.key;
      compose.summary = data.summary;
      compose.link = data.link;
      break;
    case 'milestone_items':
      compose.type = data.client_product_milestone?.type || data.type;
      compose.rate = data.agreement_service?.rate || data.rate;
      compose.currency = data.agreement_service?.client_agreement?.currency || data.currency;
      break;
    default:
      break;
  }

  return compose;
};

export const generationRows = (data, groupBy, days, useBuilder, useBuilderDataRanges, parentData = {}) => {
  const parseData = { ...data, items: [...data.milestone_items] };
  const tempGroupBy = [...groupBy];
  const mainGroup = tempGroupBy.shift() || 'user_id';
  const isDisplayNpaEmoji = mainGroup === 'user_id' || mainGroup === 'project_id' || mainGroup === 'ticket_id';

  return parseData[keysByGroup[mainGroup]].reduce((acc, item) => {
    let tempRes = acc;
    const worklogs = parseData.worklogs.filter((worklog) => worklog[mainGroup] === item.id);
    const type = keysByGroup[mainGroup].slice(0, -1);
    const composeData = composeRowData(keysByGroup[mainGroup], item);
    const rowId = [composeData.id, parseData.parentRowId || ''].join('-');
    const additionalData = { ...parentData, [type]: composeData };
    const isUserEmptyData = mainGroup === 'user_id' && worklogs.length === 0 && Object.keys(parentData).length === 0;

    if (worklogs.length > 0 || isUserEmptyData) {
      tempRes = [...acc, {
        [type]: composeData,
        rowId,
        parentRowId: parseData.parentRowId || null,
        type,
        worklogs: updateWorklogs(worklogs, days, useBuilder, useBuilderDataRanges, isDisplayNpaEmoji, additionalData),
        expandedRows: isEmpty(tempGroupBy)
          ? []
          : generationRows(
            {
              ...parseData, worklogs, parentRowId: rowId
            },
            tempGroupBy,
            days,
            useBuilder,
            useBuilderDataRanges,
            additionalData,
          ),
        totalTime: getTotalTimeByWorklogs(worklogs),
      }];
    }

    return tempRes;
  }, []);
};

export const getDailyTotal = (rows) => {
  return rows.reduce((acc, { worklogs }) => {
    return worklogs.reduce((rowAcc, item, index) => {
      const tempTime = mergeTime(item, acc[index] || emptyTimes);

      return [...rowAcc, {
        date: item.date,
        day: item.day,
        ...tempTime,
        ...getObjectWithTimeStrings(tempTime),
      }];
    }, []);
  }, []);
};

export const getTotalByDaily = (dailyTotal, validationFn = (date) => isSunday(date)) => {
  let colSpan = 1;
  const total = [];

  const time = dailyTotal.reduce((acc, item) => {
    if (validationFn(getFixTimezoneDate(new Date(item.date)))) {
      const tempTime = mergeTime(item, acc);

      total.push({ ...tempTime, ...getObjectWithTimeStrings(tempTime), colSpan });
      colSpan = 1;

      return emptyTimes;
    }

    colSpan += 1;

    return mergeTime(item, acc);
  }, emptyTimes);

  if (colSpan > 1) {
    total.push({ ...time, ...getObjectWithTimeStrings(time), colSpan });
  }

  return total;
};

export const getTotalTimesFromRows = (rows) => {
  const daily = getDailyTotal(rows);
  const weekly = getTotalByDaily(daily);
  const monthly = getTotalByDaily(daily, (date) => isLastDayOfMonth(date));

  return { daily, weekly, monthly };
};

export const workingHoursFromDays = (days) => {
  return days.reduce((acc, { type }) => {
    return type === WORKDAY ? acc + 8 : acc;
  }, 0);
};

export const getAllExpandedRows = (rows) => {
  return rows.reduce((acc, item) => {
    return item.expandedRows?.length > 0 ? (
      [...acc, item.rowId, ...getAllExpandedRows(item.expandedRows)]
    ) : acc;
  }, []);
};

const getChildRowIds = (parentId, data) => {
  return data.reduce((acc, item) => {
    if (item.id === parentId && item.expandedRows) {
      const childRowIds = item.expandedRows.map((childItem) => childItem.id);

      return item.expandedRows.reduce((childAcc, childItem) => {
        return childAcc.concat(getChildRowIds(childItem.id, data));
      }, acc.concat(childRowIds));
    }

    if (item.expandedRows) {
      return item.expandedRows.reduce((childAcc, childItem) => {
        return childAcc.concat(getChildRowIds(parentId, [childItem]));
      }, acc);
    }

    return acc;
  }, []);
};

export const getRowIds = (rows, deep, currentLevel = 0) => {
  if (!Array.isArray(rows) || currentLevel >= deep) {
    return [];
  }

  const ids = [];
  rows.forEach((row) => {
    if (row.rowId) {
      ids.push(row.rowId);
    }

    if (Array.isArray(row.expandedRows)) {
      const childIds = getRowIds(row.expandedRows, deep, currentLevel + 1);
      ids.push(...childIds);
    }
  });

  return ids;
};

export const expandedRow = (rowId, expandedRows, rows) => {
  let tempExpandedRows = [...expandedRows];

  if (expandedRows.includes(rowId)) {
    const childRowIds = getChildRowIds(rowId, rows);
    tempExpandedRows = removeValuesFromArray(tempExpandedRows, [rowId, ...childRowIds]);
  } else {
    tempExpandedRows = [...tempExpandedRows, rowId];
  }

  return tempExpandedRows;
};

const fetchTimesheetHolidays = async (startDate, endDate) => {
  let result = [];
  const params = {
    filters: {
      start_date: formatStringDate(startDate, 'yyyy-MM-dd'),
      end_date: formatStringDate(endDate, 'yyyy-MM-dd'),
    },
  };

  const { status, data } = await axios.get('/holidays', { params });

  if (status === 200) {
    result = parseHolidaysData(data.data, startDate, endDate);
  }

  return result;
};

const fetchTimesheetVacation = async (startDate, endDate, userId) => {
  let result = [];
  const params = {
    filters: {
      start_date: formatStringDate(startDate, 'yyyy-MM-dd'),
      end_date: formatStringDate(endDate, 'yyyy-MM-dd'),
      user: { id: [userId] },
      ticket: { key: ['NPA-3'] },
    },
    per_page: 500,
  };

  const { status, data } = await axios.get('/worklogs', { params });

  if (status === 200) {
    result = data.data;
  }

  return result;
};

const isVacationDate = (date, vacations) => {
  return vacations.find((vacation) => {
    return getFixTimezoneDate(new Date(vacation.date)).toDateString() === date.toDateString();
  });
};

export const createNewWorklogs = async (worklog, date) => {
  let result;
  const isUpdate = !!worklog.worklogId;

  const body = {
    reported_time: parseWorklogTimeToSeconds(worklog.time),
    type: worklog.type,
    comment: worklog.comment,
    ...(isUpdate ? {} : {
      ticket_id: worklog.ticket?.id,
      user_id: worklog.user?.id,
      project_id: worklog.project?.id,
    }),
  };

  if (worklog.isLogPeriod) {
    const worklogs = [];
    const { from, to } = date;
    const changeableDate = new Date(from);
    const vacations = await fetchTimesheetVacation(from, to || from, worklog.user?.id);
    const holidays = await fetchTimesheetHolidays(from, to || from, worklog.user?.id);
    const days = getDays(from, to || from, holidays);
    let day = 0;

    while (day < days.length) {
      if (days[day].type === WORKDAY && (!isVacationDate(changeableDate, vacations) || days.length === 1)) {
        worklogs.push({ ...body, date: formatStringDate(changeableDate, 'yyyy-MM-dd') });
      }

      day += 1;
      changeableDate.setDate(changeableDate.getDate() + 1);
    }

    result = axios.post('/worklogs/create_batch', { worklogs });
  } else {
    const changeableDate = date;
    let method = 'POST';
    let url = '/worklogs';

    if (isUpdate) {
      method = 'PUT';
      url += `/${worklog.worklogId}`;
    }

    result = axios({
      method,
      url,
      data: { ...body, date: formatStringDate(changeableDate, 'yyyy-MM-dd') },
    });
  }

  return result;
};

export const updateRouterQuery = (query) => {
  const updateQuery = { isEmpty: false };
  const parseQuery = parseRouterQuery(
    query,
    undefined,
    ['project_id', 'user_id', 'ticket_id', 'client_id', 'department_id', 'group_by'],
  );

  if (isObjectEmpty(parseQuery)) {
    updateQuery.isEmpty = true;
    updateQuery.expanded = false;
  } else {
    const selectedIds = {};
    const filters = {};
    const showTimes = {};

    if (parseQuery.start_date && parseQuery.end_date) {
      filters.start_date = parseQuery.start_date;
      filters.end_date = parseQuery.end_date;
    }

    if (parseQuery.group_by) {
      updateQuery.groupBy = parseQuery.group_by;
    }

    ['reported_time', 'billed_time', 'approved_time'].forEach((item) => {
      if (typeof parseQuery[item] === 'boolean') {
        showTimes[timeTypeMap[item]] = parseQuery[item];
      }
    });

    ['project', 'user', 'ticket', 'client', 'department'].forEach((item) => {
      const key = `${item}_id`;

      if (parseQuery[key]) {
        filters[item] = { id: parseQuery[key] };
        selectedIds[item] = parseQuery[key];
      }
    });

    updateQuery.expanded = parseQuery.expanded || false;
    updateQuery.selectedIds = selectedIds;
    updateQuery.filters = filters;
    updateQuery.showTimes = showTimes;
  }

  return updateQuery;
};

export const mergeUsers = (users1, users2) => {
  const userMap = new Map(users1.map((user) => [user.id, user]));

  users2.forEach((user) => {
    if (userMap.has(user.id)) {
      const existingUser = userMap.get(user.id);
      userMap.set(user.id, { ...existingUser, ...user });
    } else {
      userMap.set(user.id, user);
    }
  });

  return Array.from(userMap.values());
};

export const isShowTime = (time, overTime, isShow) => {
  return (time > 0 || overTime > 0) && isShow;
};

export const addToRecent = (key, value) => {
  let tempRecent = [];
  const recent = localStorageHelper.get(key) || [];

  if (recent.includes(value)) {
    tempRecent = moveElement(recent, recent.findIndex((item) => item === value), 0);
  } else if (recent.length >= 6) {
    const sliceRecent = recent.slice(0, 6);

    sliceRecent.pop();
    tempRecent = [value, ...sliceRecent];
  } else {
    tempRecent = [value, ...recent];
  }

  localStorageHelper.set(key, tempRecent);
};
