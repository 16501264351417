import { forwardRef } from 'react';
import { useCommandState } from 'cmdk';

const GlobalSearchEmpty = forwardRef((props, ref) => {
  const render = useCommandState((state) => state.filtered.count === 0);
  
  if (!render) return null;

  return (
    <div
      ref={ref}
      cmdk-empty=""
      role="presentation"
      {...props}
    />
  );
});

export default GlobalSearchEmpty;
