import PropTypes from 'prop-types';

const FolderCloseIcon = ({ width = 24, height = 24, fill = 'currentColor' }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 4H10L12 6H20C21.1 6 22 6.9 22 8V18C22 19.1 21.1 20 20 20H4C2.9 20 2 19.1 2 18L2.01 6C2.01 4.9 2.9 4 4 4ZM11.17 8L9.17 6H4V18H20V8H11.17Z"
        fill={fill}
      />
    </svg>
  );
};

FolderCloseIcon.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  fill: PropTypes.string,
};

export default FolderCloseIcon;
