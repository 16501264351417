import { FETCH_API_INFO, SET_IS_UNREAD_NOTIFICATION } from './types';

export const initialState = {
  apiInfo: {},
  isUnreadNotification: false
};

export default function general(state = initialState, action) {
  switch (action.type) {
    case FETCH_API_INFO:
      return {
        ...state,
        apiInfo: action.apiInfo,
      };
    case SET_IS_UNREAD_NOTIFICATION:
      return {
        ...state,
        isUnreadNotification: action.isUnreadNotification,
      };
    default:
      return state;
  }
}
