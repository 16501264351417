import {
  addMonths, endOfMonth, endOfWeek, parseISO, startOfMonth, startOfWeek,
} from 'date-fns';

const dateISORegex = /^\d{4}-\d{2}-\d{2}(T\d{2}:\d{2}:\d{2}\.\d{3}Z)?$/;

export const getCorrectDate = (date) => {
  let correctDate;

  if (typeof date === 'string' && dateISORegex.test(date)) {
    correctDate = parseISO(date);
  } else if (typeof date === 'string') {
    correctDate = new Date(date);
  } else {
    correctDate = date;
  }

  return correctDate;
};

export const getDateWithoutTimezone = (dateStr) => {
  const [date] = dateStr.split('T');

  return parseISO(date);
};

export const getPercentageOfMinutes = (minute) => (
  (Math.floor((minute / 60) * 100) / 100).toString().split('.')[1]
);

export const convertTimeStampToUserTime = (timeStamp) => {
  const totalMinute = timeStamp / 60;
  const hours = totalMinute / 60;
  const minute = totalMinute % 60;
  let result = 0;
  if (Math.floor(hours) >= 1) {
    result = Math.floor(hours);
  }
  if (getPercentageOfMinutes(minute) >= 1) {
    result += `.${getPercentageOfMinutes(minute)}`;
  }
  return result;
};

export const numberDaysBetween = (date1, date2) => {
  let result = 0;
  const from = new Date(date1);
  const to = new Date(date2);

  if (from < to) {
    const diffTime = to.getTime() - from.getTime();
    result = diffTime / (1000 * 3600 * 24);
  }

  return result;
};

export const getDaysInMonth = (month, year) => (
  new Date(year, month + 1, 0).getDate()
);

export const getPrevMonth = (date) => {
  const month = addMonths(getCorrectDate(date), -1);

  return {
    firstDay: startOfMonth(month),
    lastDay: endOfMonth(month),
  };
};

export const getNextMonth = (date) => {
  const month = addMonths(getCorrectDate(date), 1);

  return {
    firstDay: startOfMonth(month),
    lastDay: endOfMonth(month),
  };
};

export const getLastWeek = () => {
  const today = new Date();
  const lastWeek = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7);

  const firstDay = startOfWeek(lastWeek, { weekStartsOn: 1 });
  const lastDay = endOfWeek(lastWeek, { weekStartsOn: 1 });

  return { firstDay, lastDay };
};

export const getLastMonth = () => {
  const date = new Date();
  const year = date.getFullYear();
  const month = date.getMonth();
  const firstDay = new Date(year, month - 1, 1);
  const lastDay = new Date(year, month, 0);

  return { firstDay, lastDay };
};

export const getThisMonth = () => {
  const date = new Date();
  const year = date.getFullYear();
  const month = date.getMonth();
  const firstDay = new Date(year, month, 1);
  const lastDay = new Date(year, month + 1, 0);

  return { firstDay, lastDay };
};
