import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import Router from 'next/router';
import axios from '../../services/axios';
import { newValidateErrors, validateClientAgreement, validateErrors } from '../../utils/validators';
import { formatStringDate } from '../../utils/helpers';
import { LIST_SIZE_PER_PAGE } from '../../constants/values';
import * as httpStatusCodes from '../../constants/httpStatusCodes';

export const initialState = {
  isLoading: true,
  error: undefined,
  data: [],
  meta: {},
  tableColumns: {
    name: true,
    isUpwork: true,
    clientCompany: false,
    startDate: true,
    endDate: false,
    currency: true,
    paymentMethod: false,
    ourCompany: true,
    nonCompete: true,
    comments: false,
    action: true,
    service: true,
    nonDisclosure: true
  },
  deleteAgreementModal: {
    modalId: 'deleteAgreementModal',
    isModalOpen: false,
    agreementId: null,
    agreementName: ''
  },
  modalProps: {
    modalId: 'agreementModal',
    agreementId: null,
    isModalOpen: false,
    isAdd: false
  },
  modalFields: {
    name: '',
    isUpwork: false,
    overtimeAllowed: false,
    startDate: formatStringDate(new Date()),
    endDate: null,
    currency: null,
    paymentMethod: null,
    pmCommentText: '',
    ourCompany: '',
    clientCompanies: [],
    ourCompanyOtherText: '',
    nonCompete: false,
    notifyTeamChanging: false,
    documentFile: null,
    comment: '',
    isServiceAgreement: false,
    nonCompeteTerms: '',
    nonDisclosure: false
  },
  modalErrors: {},
  agreementFieldsErrors: {},
  isClientCompanyOpen: false,
  isCurrencyOpen: false,
  isCompanyOpen: false,
  isStartDateOpen: false,
  isEndDateOpen: false,
  isPaymentMethodOpen: false,
  clientCompanyInputValue: '',
  isEdit: false,
  isUpload: false,
  isSubmit: false,
  pageFilters: {
    isColumnsOpen: false
  },
  query: {
    filters: {
      name: ''
    },
    sort: '-start_date',
    with: ['client', 'client_companies', 'payment_method'],
    page: null,
    per_page: LIST_SIZE_PER_PAGE
  },
};

export const fetchClientAgreementsAsync = createAsyncThunk(
  'clientAgreements/fetchClientAgreements',
  async (arg, { rejectWithValue, getState }) => {
    try {
      const { clientAgreements: { query } } = getState();
      const { data, status } = await axios.get('/client_agreements', {
        params: { ...query, filters: { ...query.filters, client_id: [Router.query.id] } }
      });

      return status === 200 ? data : rejectWithValue(data.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const addOrEditClientAgreementAsync = createAsyncThunk(
  'clientAgreements/addOrEditClientAgreement',
  async ({ agreementId }, { rejectWithValue, dispatch, getState }) => {
    try {
      const { clientAgreements: { modalFields, isUpload } } = getState();
      const errors = validateErrors(modalFields, validateClientAgreement, {
        ourCompany: modalFields.ourCompany,
        isUpload,
        isServiceAgreement: modalFields.isServiceAgreement,
        nonCompete: modalFields.nonCompete,
        paymentMethod: modalFields.paymentMethod
      });

      if (errors) {
        return rejectWithValue({ isFormValidation: true, errors });
      }

      let method = 'POST';
      let endPoint = '/client_agreements';

      if (agreementId) {
        method = 'PUT';
        endPoint += `/${agreementId}`;
      }

      const params = {
        name: modalFields.name,
        start_date: formatStringDate(new Date(modalFields.startDate), 'y-MM-dd'),
        pm_comment_text: modalFields.pmCommentText,
        our_company: modalFields.ourCompany,
        our_company_other_text: modalFields.ourCompanyOtherText,
        service_agreement: modalFields.isServiceAgreement,
        non_compete: modalFields.nonCompete,
        non_compete_terms: modalFields.nonCompeteTerms,
        non_disclosure: modalFields.nonDisclosure,
        comments: modalFields.comment,
        client_id: Router.query.id,
        client_company_ids: modalFields.clientCompanies?.map(({ id }) => id) || [],
      };

      if (modalFields.isServiceAgreement) {
        params.is_upwork = !!modalFields.isUpwork;
        params.overtime_allowed = !!modalFields.overtimeAllowed;
        params.notify_team_changing = !!modalFields.notifyTeamChanging;
        params.currency = modalFields.currency;
        params.end_date = modalFields.endDate ? formatStringDate(modalFields.endDate, 'y-MM-dd') : null;
        params.payment_method_id = modalFields.paymentMethod?.id || null;
      }

      const { data, status } = await axios({ method, url: endPoint, data: params });

      if (status === 200 || status === 201) {
        dispatch(fetchClientAgreementsAsync());
        return data;
      }

      return rejectWithValue(data.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteClientAgreementAsync = createAsyncThunk(
  'clientAgreements/deleteClientAgreement',
  async ({ id }, { dispatch, fulfillWithValue, rejectWithValue }) => {
    try {
      const { data, status } = await axios.delete(`/client_agreements/${id}`);

      if (status === httpStatusCodes.NO_CONTENT) {
        dispatch(fetchClientAgreementsAsync());
        return fulfillWithValue();
      }

      return rejectWithValue(data.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const clientAgreementsSlice = createSlice({
  name: 'clientAgreements',
  initialState,
  reducers: {
    setQueryFilter: (state, action) => {
      state.query.filters = { ...state.query.filters, ...action.payload };
      state.query.page = initialState.query.page;
    },
    setQuery: (state, action) => {
      state.query = { ...state.query, ...action.payload };
    },
    setTableColumns: (state, action) => {
      state.tableColumns = { ...state.tableColumns, ...action.payload };
    },
    setPageFilterData: (state, action) => {
      state.pageFilters = { ...state.pageFilters, ...action.payload };
    },
    setModalData: (state, action) => {
      state[action.payload.key] = { ...state[action.payload.key], ...action.payload.data };
    },
    setEdit: (state, action) => {
      state.isEdit = action.payload;
    },
    resetClientAgreementsModal: (state) => {
      state.modalErrors = initialState.modalErrors;
      state.modalProps = initialState.modalProps;
      state.modalFields = initialState.modalFields;
    },
    setFieldsData: (state, action) => {
      state.modalFields = { ...state.modalFields, ...action.payload };
      state.isCurrencyOpen = false;
      state.isPaymentMethodOpen = false;
      state.isCompanyOpen = false;
      state.isStartDateOpen = false;
      state.isEndDateOpen = false;
      state.isClientCompanyOpen = false;
      state.modalErrors = {
        ...state.modalErrors,
        ...newValidateErrors({ ...action.payload }, validateClientAgreement)
      };
    },
    setData: (state, action) => {
      return ({ ...state, ...action.payload });
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchClientAgreementsAsync.pending, (state) => {
        state.isLoading = true;
        state.projects = undefined;
        state.error = undefined;
      })
      .addCase(fetchClientAgreementsAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload.data;
        state.meta = action.payload.meta;
      })
      .addCase(fetchClientAgreementsAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
        state.isSubmit = false;
      })
      .addCase(addOrEditClientAgreementAsync.pending, (state) => {
        state.isLoading = true;
        state.clientAgreements = undefined;
        state.error = undefined;
        state.isSubmit = true;
      })
      .addCase(addOrEditClientAgreementAsync.fulfilled, (state) => {
        state.isLoading = false;
        state.isSubmit = false;
        state.modalErrors = initialState.modalErrors;
        state.modalProps = initialState.modalProps;
        state.modalFields = initialState.modalFields;
      })
      .addCase(addOrEditClientAgreementAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.isSubmit = false;
        if (action.payload?.isFormValidation) {
          state.modalErrors = action.payload.errors;
          state.modalProps.isModalOpen = true;
        } else {
          state.error = action.payload;
        }
      })
      .addCase(deleteClientAgreementAsync.pending, (state) => {
        state.isSubmit = true;
      })
      .addCase(deleteClientAgreementAsync.fulfilled, (state) => {
        state.isSubmit = false;
        state.deleteAgreementModal = initialState.deleteAgreementModal;
      })
      .addCase(deleteClientAgreementAsync.rejected, (state, action) => {
        state.isSubmit = false;
        state.error = action.payload;
      });
  },
});

export const {
  setQueryFilter,
  setQuery,
  setData,
  setFieldsData,
  setTableColumns,
  setPageFilterData,
  setModalData,
  setEdit,
  resetClientAgreementsModal
} = clientAgreementsSlice.actions;

export default clientAgreementsSlice.reducer;
