import {
  PROJECT_DOC_SET_DATA, PROJECT_DOC_SET_MODAL_DATA, PROJECT_DOC_SET_MODAL_FIELDS, PROJECT_DOC_RESET_MODAL,
  PROJECT_DOC_SET_MODAL_ERRORS, PROJECT_DOC_SET_PAGE, PROJECT_DOC_SET_DESCRIPTION, PROJECT_DOC_SET_SUBMIT
} from './types';
import { LIST_SIZE_PER_PAGE } from '../../constants/values';

export const initialState = {
  data: [],
  links: {},
  meta: {},
  isSubmit: false,
  isDeleteSubmit: false,
  shortDescription: '',
  fullDescription: '',
  queryParams: {
    filters: {
      id: [],
      name: '',
      type: ''
    },
    page: null,
    per_page: LIST_SIZE_PER_PAGE,
  },
  documentationModal: {
    modalId: 'documentationModal',
    isModalOpen: false,
    isTypeOpen: false,
    docId: null,
  },
  warningModal: {
    modalId: 'warningModal',
    isModalOpen: false,
    doc: null,
  },
  documentationFields: {
    name: '',
    link: '',
    type: '',
  },
  modalErrors: {},
};

export default function projectDocumentation(state = initialState, action) {
  switch (action.type) {
    case PROJECT_DOC_SET_SUBMIT:
      return {
        ...state,
        [action.key]: action.value,
      };
    case PROJECT_DOC_SET_DESCRIPTION:
      return {
        ...state,
        ...action.description,
      };
    case PROJECT_DOC_SET_PAGE:
      return {
        ...state,
        queryParams: {
          ...state.queryParams,
          page: action.page,
        },
      };
    case PROJECT_DOC_SET_MODAL_ERRORS:
      return {
        ...state,
        modalErrors: action.errors,
      };
    case PROJECT_DOC_RESET_MODAL:
      return {
        ...state,
        documentationModal: initialState.documentationModal,
        modalErrors: initialState.modalErrors,
        documentationFields: initialState.documentationFields,
      };
    case PROJECT_DOC_SET_DATA:
      return {
        ...state,
        ...action.doc,
      };
    case PROJECT_DOC_SET_MODAL_DATA:
      return {
        ...state,
        [action.key]: {
          ...state[action.key],
          ...action.data,
        },
      };
    case PROJECT_DOC_SET_MODAL_FIELDS:
      return {
        ...state,
        documentationFields: {
          ...state.documentationFields,
          ...action.data,
        },
      };
    default:
      return state;
  }
}
