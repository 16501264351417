import { useEffect, useRef, useState } from 'react';

const useIsPresent = () => {
  const ref = useRef(null);
  const [isPresent, setIsPresent] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsPresent(entry.isIntersecting);
      },
      {
        threshold: 0.1,
      }
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, [ref]);

  return [isPresent, ref];
};

export default useIsPresent;
