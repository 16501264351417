import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../services/axios';
import { formatISODate, formatStringDate } from '../../utils/helpers';
import {
  validateMilestone, validateErrors, newValidateErrors, validateMilestoneInfo
} from '../../utils/validators';
import { parseMilestoneItemData } from '../../utils/aggrements';
import { addOrEditMilestoneAsync } from '../milestones/slice';
import { acceptMilestoneAsync, rejectMilestoneAsync } from './thunks';

const date = formatStringDate(new Date());

export const initialState = {
  isLoading: true,
  isSubmit: false,
  error: undefined,
  milestone: undefined,
  fields: {
    name: '',
    startDate: date,
    dateFinishPlan: null,
    dateFinishReal: null,
    billingPeriod: null,
    comments: '',
    type: null,
  },
  modalFields: {
    name: '',
    managerComment: '',
    images: [],
    approvedDate: date,
    status: '',
    milestoneId: null,
  },
  acceptMilestoneModal: {
    modalId: 'acceptMilestoneModal',
    isModalOpen: false,
    isApproveDateOpen: false,
  },
  rejectMilestoneModal: {
    modalId: 'rejectMilestoneModal',
    isModalOpen: false,
    isShowNote: true
  },
  isStartDateOpen: false,
  isFinishDateOpen: false,
  isFinishDateRealOpen: false,
  isBudgetingOpen: false,
  isAcceptanceOpen: false,
  isBillingPeriodOpen: false,
  isTypeOpen: false,
  fieldsErrors: {},
  isDateOpen: false,
  isEdit: false,
  isWarningModalOpen: false,
  milestoneCreateErrors: [],
};

export const fetchMilestoneAsync = createAsyncThunk(
  'milestoneInfo/fetchMilestone',
  async ({ milestoneId }, { rejectWithValue }) => {
    try {
      const { data, status } = await axios.get(`/product_milestones/${milestoneId}`);

      return status === 200 ? parseMilestoneItemData(data) : rejectWithValue(data.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const editMilestoneAsync = createAsyncThunk(
  'milestoneInfo/editMilestone',
  async (arg, { getState, rejectWithValue }) => {
    try {
      const { milestone: { id: milestoneId }, fields } = getState().milestoneInfo;
      const errors = validateErrors(fields, validateMilestone);

      if (errors) {
        return rejectWithValue({ isFormValidation: true, errors });
      }

      const body = {
        name: fields.name,
        comments: fields.comments,
        type: fields.type,
        preferred_billing_period: fields.billingPeriod,
        start_date: formatStringDate(fields.startDate, 'y-MM-dd'),
        planned_completion_date: fields.dateFinishPlan ? formatStringDate(fields.dateFinishPlan, 'y-MM-dd') : null,
      };

      const { data, status } = await axios.put(`/product_milestones/${milestoneId}`, body);

      return status === 200 ? parseMilestoneItemData(data) : rejectWithValue(data.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const milestoneInfoSlice = createSlice({
  name: 'milestoneInfo',
  initialState,
  reducers: {
    resetState: () => initialState,
    setQueryFilter: (state, action) => {
      state.query.filters = { ...state.query.filters, ...action.payload };
    },
    setQuery: (state, action) => {
      state.query = { ...state.query, ...action.payload };
    },
    setEdit: (state, action) => {
      if (action.payload === true) {
        state.fields = {
          name: state.milestone.name,
          startDate: formatISODate(state.milestone.start_date),
          dateFinishPlan: state.milestone.planned_completion_date
            ? formatISODate(state.milestone.planned_completion_date) : null,
          dateFinishReal: state.milestone.real_completion_date
            ? formatISODate(state.milestone.real_completion_date) : null,
          comments: state.milestone.comments || '',
          billingPeriod: state.milestone.preferred_billing_period,
          type: state.milestone.type
        };
      } else {
        state.fields = initialState.fields;
        state.fieldsErrors = initialState.fieldsErrors;
      }
      state.isEdit = action.payload;
    },
    setFieldsData: (state, action) => {
      state.fields = { ...state.fields, ...action.payload };
    },
    setData: (state, action) => {
      return ({ ...state, ...action.payload });
    },
    setModalData: (state, action) => {
      state[action.payload.key] = { ...state[action.payload.key], ...action.payload.data };
    },
    setModalFields: (state, action) => {
      state.modalFields = { ...state.modalFields, ...action.payload };
      state.fieldsErrors = {
        ...state.fieldsErrors,
        ...newValidateErrors({ ...action.payload }, validateMilestoneInfo)
      };
    },
    resetModalFields: (state) => {
      state.modalFields = initialState.modalFields;
      state.fieldsErrors = {};
      state.acceptMilestoneModal = initialState.acceptMilestoneModal;
      state.rejectMilestoneModal = initialState.rejectMilestoneModal;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchMilestoneAsync.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchMilestoneAsync.fulfilled, (state, action) => {
        state.milestone = action.payload;
        state.fields = action.payload;
        state.isLoading = false;
      })
      .addCase(fetchMilestoneAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
    builder
      .addCase(editMilestoneAsync.pending, (state) => {
        state.isSubmit = true;
        state.error = undefined;
        state.fieldsErrors = initialState.fieldsErrors;
      })
      .addCase(editMilestoneAsync.fulfilled, (state, action) => {
        state.milestone = action.payload;
        state.fields = initialState.fields;
        state.fieldsErrors = initialState.fieldsErrors;
        state.isEdit = false;
        state.isSubmit = false;
      })
      .addCase(editMilestoneAsync.rejected, (state, action) => {
        if (action.payload?.isFormValidation) {
          state.fieldsErrors = action.payload.errors;
        } else {
          state.error = action.payload;
        }
        state.isSubmit = false;
      });
    builder
      .addCase(acceptMilestoneAsync.pending, (state) => {
        state.isSubmit = true;
        state.error = undefined;
        state.fieldsErrors = {};
      })
      .addCase(acceptMilestoneAsync.fulfilled, (state, action) => {
        state.isSubmit = false;
        state.milestone = action.payload;
        state.acceptMilestoneModal = initialState.acceptMilestoneModal;
        state.modalFields = initialState.modalFields;
        state.fieldsErrors = {};
      })
      .addCase(acceptMilestoneAsync.rejected, (state, action) => {
        if (action.payload?.isFormValidation) {
          state.fieldsErrors = action.payload.errors;
        } else {
          state.error = action.payload;
        }
        state.isSubmit = false;
      });
    builder
      .addCase(rejectMilestoneAsync.pending, (state) => {
        state.isSubmit = true;
        state.error = undefined;
      })
      .addCase(rejectMilestoneAsync.fulfilled, (state, action) => {
        state.isSubmit = false;
        state.milestone = action.payload;
        state.rejectMilestoneModal = initialState.rejectMilestoneModal;
        state.modalFields = initialState.modalFields;
        state.fieldsErrors = {};
      })
      .addCase(rejectMilestoneAsync.rejected, (state, action) => {
        state.isSubmit = false;
        state.error = action.payload;
      });
    builder
      .addCase(addOrEditMilestoneAsync.fulfilled, (state, action) => {
        if (action.payload.errors?.length > 0) {
          state.isWarningModalOpen = true;
          state.milestoneCreateErrors = action.payload.errors;
        }
      });
  },
});

export const {
  setQueryFilter,
  setQuery,
  setEdit,
  setData,
  setFieldsData,
  resetState,
  setModalData,
  setModalFields,
  resetModalFields
} = milestoneInfoSlice.actions;

export default milestoneInfoSlice.reducer;
