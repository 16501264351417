import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { shallowEqual } from 'react-redux';
import { signIn, useSession } from 'next-auth/react';

import AuthLoader from './AuthLoader';

import axios from '../../services/axios';
import { useDispatch, useSelector } from '../../hooks/reduxToolkit';
import { getUserProfile } from '../../toolkitStore/user/slice';
import { REFRESH_ACCESS_TOKEN_ERROR } from '../../constants/errors';

const AuthGuard = ({ children }) => {
  const { data: sessionData } = useSession({ required: true });
  const dispatch = useDispatch();

  const { isLoading, profile, error } = useSelector(({ user }) => ({
    isLoading: user.isLoading,
    profile: user.profile,
    error: user.error,
  }), shallowEqual);

  useEffect(() => {
    if (sessionData?.accessToken) {
      axios.defaults.headers.common['Authorization'] = `Bearer ${sessionData.accessToken}`;
    }
  }, [sessionData?.accessToken]);

  useEffect(() => {
    if (sessionData?.error === REFRESH_ACCESS_TOKEN_ERROR) {
      signIn('hydra_passport');
    } else if (sessionData?.user && !profile) {
      const user = dispatch(getUserProfile());

      return () => {
        user.abort();
      };
    }

    return () => null;
  }, [profile, sessionData]);

  if (error) {
    return <div>Something happened. Please contact the hydra-support chanel in slack</div>;
  }

  if (isLoading) {
    return <AuthLoader />;
  }

  return children;
};

AuthGuard.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthGuard;
