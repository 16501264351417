import Router from 'next/router';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../services/axios';
import { LIST_SIZE_PER_PAGE } from '../../constants/values';
import { validateErrors, validateProductProjectModal } from '../../utils/validators';

export const initialState = {
  isLoading: true,
  isSubmit: false,
  error: undefined,
  data: [],
  meta: {},
  query: {
    filters: {
      id: [],
      name: '',
      manager_name: '',
      client_id: [],
      client_product_id: [],
      status: []
    },
    with: ['client_product', 'manager'],
    page: null,
    per_page: LIST_SIZE_PER_PAGE,
  },
  projectModal: {
    modalId: 'projectModal',
    isModalOpen: false,
    project: null,
    searchValue: '',
    isProjectOpen: false
  },
  errors: {},
};

export const fetchClientProductProjectsAsync = createAsyncThunk(
  'clientProductProjects/fetchClientProductProjectsAsync',
  async (arg, { getState, rejectWithValue }) => {
    try {
      const { query } = getState().clientProductProjects;
      const { data, status } = await axios.get('/projects', { params: query });

      return status === 200 ? data : rejectWithValue(data.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const addExistingProject = createAsyncThunk(
  'clientProductProjects/addExistingProjectAsync',
  async (arg, { getState, rejectWithValue, dispatch }) => {
    try {
      const { projectModal } = getState().clientProductProjects;
      const { query } = Router;

      const errors = validateErrors(projectModal, validateProductProjectModal);

      if (errors) {
        return rejectWithValue({ errors, isFormValidation: true });
      }

      const { data, status } = await axios.put(
        `/projects/${projectModal.project.id}`,
        { client_product_id: query.product }
      );

      if (status === 200) {
        dispatch(fetchClientProductProjectsAsync());
        return data;
      }

      return rejectWithValue(data.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const clientProductProjectsSlice = createSlice({
  name: 'clientProductProjects',
  initialState,
  reducers: {
    resetState: () => initialState,
    setQueryFilter: (state, action) => {
      state.query.filters = { ...state.query.filters, ...action.payload };
    },
    setQuery: (state, action) => {
      state.query = { ...state.query, ...action.payload };
    },
    setData: (state, action) => {
      return ({ ...state, ...action.payload });
    },
    setModalData: (state, action) => {
      state.projectModal = { ...state.projectModal, ...action.payload };
    },
    resetModalData: (state) => {
      state.projectModal = initialState.projectModal;
      state.errors = initialState.errors;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchClientProductProjectsAsync.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchClientProductProjectsAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload.data;
        state.meta = action.payload.meta;
      })
      .addCase(fetchClientProductProjectsAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(addExistingProject.pending, (state) => {
        state.isSubmit = true;
      })
      .addCase(addExistingProject.fulfilled, (state) => {
        state.isSubmit = false;
        state.projectModal = initialState.projectModal;
        state.errors = initialState.errors;
      })
      .addCase(addExistingProject.rejected, (state, action) => {
        state.isSubmit = false;
        if (action.payload?.type === 'modal') {
          state.errors = action.payload.errors;
        } else {
          state.error = action.payload;
        }
      });
  },
});

export const {
  setQueryFilter,
  setQuery,
  setModalData,
  setData,
  resetState,
  resetModalData,
} = clientProductProjectsSlice.actions;

export default clientProductProjectsSlice.reducer;
