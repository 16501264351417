// ALERTS
export const ALERT_ERROR = 'error';
export const ALERT_SUCCESS = 'success';

// VACATION
export const VACATION_APPROVE = 'approve';
export const VACATION_REJECT = 'reject';
export const VACATION_FORCE_APPROVE = 'forceApprove';
export const VACATION_FORCE_REJECT = 'forceReject';
export const VACATION_DELETE = 'delete';
export const VACATION_SICKNESS = 'NPA-4';
export const VACATION_VACATION = 'NPA-3';
export const VACATION_DAY_OFF = 'NPA-2';

// RATE TYPES
export const HOURLY_RATE_TYPE = 'hourly';
export const MONTHLY_RATE_TYPE = 'monthly';
export const FIXED_RATE_TYPE = 'fixed';
export const RATE_TYPES = [HOURLY_RATE_TYPE, MONTHLY_RATE_TYPE, FIXED_RATE_TYPE];

// SALARY LOGGING MODE
export const PART_TIME_DELIVERY = 'part-time-delivery';

// PROJECT ITEM TYPES
export const PROJECT_ITEM_TM_TYPE = 't&m';
export const PROJECT_ITEM_FIXED_PRICE_TYPE = 'fixed-price';
export const PROJECT_ITEM_TYPES = [PROJECT_ITEM_TM_TYPE, PROJECT_ITEM_FIXED_PRICE_TYPE];

// INVOICE ITEM TYPES
export const INVOICE_ITEM_CUSTOM_TYPE = 'custom';
export const INVOICE_ITEM_WORKLOG_TYPE = 'created-by-worklogs';
export const INVOICE_ITEM_TYPES = [INVOICE_ITEM_CUSTOM_TYPE, INVOICE_ITEM_WORKLOG_TYPE];

// MODAL TYPES
export const MODAL_WARNING_TYPE = 'warning';
export const MODAL_STOP_TYPE = 'stop';
export const MODAL_SUCCESS_TYPE = 'success';

// MILESTONE TYPE
export const MILESTONE_SUPPORT_TYPE = 'support';
export const MILESTONE_DEDICATED_TEAM_TYPE = 'dedicated-team';
export const MILESTONE_TM_SCOPE_BASED_TYPE = 't&m-scope-based';
export const MILESTONE_FP_SHOW_HOURS_RATES = 'fixed-price-show-hours-rates';
export const MILESTONE_FP_HIDE_HOURS_RATES = 'fixed-price-hide-hours-rates';

// WORKLOAD TYPE
export const WORKLOAD_PER_WEEK = 'per-week';
export const WORKLOAD_PER_MILESTONE = 'per-milestone';

// WORKLOG
export const WORKLOG_MILESTONE_ITEM_TYPE = 'ClientProductMilestoneItem';

// PROJECT DOCUMENT TYPES
export const ESTIMATION = 'estimation';
export const PRESALE_TICKET = 'presale-ticket';
export const JIRA_PROJECT = 'jira-project';
export const REPOSITORIES = 'repositories';
export const AGREEMENT = 'agreement';
export const ROAD_MAP = 'road-map';
export const GOOGLE_FOLDER = 'google-folder';
export const PROJECT_LINK_ON_WORKS = 'project-link-on-works';
export const STAGING_SERVER = 'staging-server';
export const SLACK_CHANNEL = 'slack_channel';

// PROJECT TYPES
export const PROJECT_TYPES = {
  CLIENT: 'client',
  INTERNAL: 'internal',
  INTERNAL_STRATEGIC: 'internal-strategic',
  NPA: 'npa',
};

// COMMENT TYPES
export const COMMENT_TYPES = {
  CLIENT: 'Client',
  PROJECT: 'Project',
};
