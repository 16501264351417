import PropTypes from 'prop-types';

const UserCheckIcon = ({ width = 24, height = 24, fill = 'currentColor' }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5 20V18C5 16.8954 5.89543 16 7 16H9C9.55228 16 10 15.5523 10 15C10 14.4477 9.55228 14 9 14L7 14C4.79086 14 3 15.7909 3 18V20C3 20.5523 3.44772 21 4 21C4.55228 21 5 20.5523 5 20Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 7.5C14 9.98528 11.9853 12 9.5 12C7.01472 12 5 9.98528 5 7.5C5 5.01472 7.01472 3 9.5 3C11.9853 3 14 5.01472 14 7.5ZM7 7.5C7 8.88071 8.11929 10 9.5 10C10.8807 10 12 8.88071 12 7.5C12 6.11929 10.8807 5 9.5 5C8.11929 5 7 6.11929 7 7.5Z"
        fill={fill}
      />
      <path
        d="M15.775 19.8725C15.385 20.2625 14.745 20.2625 14.355 19.8725L12.285 17.7825C11.905 17.3925 11.905 16.7725 12.285 16.3925L12.295 16.3825C12.685 15.9925 13.315 15.9925 13.695 16.3825L15.065 17.7525L19.495 13.2925C19.885 12.9025 20.515 12.9025 20.905 13.2925L20.915 13.3025C21.295 13.6925 21.295 14.3125 20.915 14.6925L15.775 19.8725Z"
        fill={fill}
      />
    </svg>
  );
};

UserCheckIcon.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  fill: PropTypes.string,
};

export default UserCheckIcon;
