export const TIMETRACKING_SET_DATA = 'TIMETRACKING_SET_DATA';
export const TIMETRACKING_SET_QUERY_PARAMS = 'TIMETRACKING_SET_QUERY_PARAMS';
export const TIMETRACKING_SET_DATEPICKER_DATA = 'TIMETRACKING_SET_DATEPICKER_DATA';
export const TIMETRACKING_SET_PAGE_FILTERS_DATA = 'TIMETRACKING_SET_PAGE_FILTERS_DATA';
export const TIMETRACKING_SET_REPORT_DETAILS = 'TIMETRACKING_SET_REPORT_DETAILS';
export const TIMETRACKING_SET_DATES_INTERVAL = 'TIMETRACKING_SET_DATES_INTERVAL';
export const TIMETRACKING_RESET_PAGE = 'TIMETRACKING_RESET_PAGE';
export const TIMETRACKING_SET_NEED_REPORTED_TIME = 'TIMETRACKING_SET_NEED_REPORTED_TIME';
export const TIMETRACKING_SET_MAX_TRACKING_TIME = 'TIMETRACKING_SET_MAX_TRACKING_TIME';
export const TIMETRACKING_SET_MAX_TRACKING_TEAM_TIME = 'TIMETRACKING_SET_MAX_TRACKING_TEAM_TIME';
export const TIMETRACKING_SET_ABORT = 'TIMETRACKING_SET_ABORT';
export const TIMETRACKING_SET_PAGE_INITIAL_FILTERS = 'TIMETRACKING_SET_PAGE_INITIAL_FILTERS';
