import fetchInstance from '../services/fetchInstance';

const downloadFile = async (url, filename) => {
  const response = await fetchInstance(`${process.env.NEXT_PUBLIC_API_BACKEND_URL}${url}`);

  if (!response.ok) {
    throw new Error('An error occurred while fetching the file');
  }

  const blob = await response.blob();
  const objectUrl = URL.createObjectURL(blob);

  const downloadLink = document.createElement('a');
  downloadLink.href = objectUrl;
  downloadLink.download = filename;

  document.body.appendChild(downloadLink);
  downloadLink.click();

  setTimeout(() => {
    document.body.removeChild(downloadLink);
    URL.revokeObjectURL(objectUrl);
  }, 100);
};

export default downloadFile;
