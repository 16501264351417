import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../services/axios';
import { validateErrors, validateSalaryCorrectionTypeForm } from '../../utils/validators';
import httpRequestMethods from '../../constants/httpRequestMethods';
import * as httpStatusCodes from '../../constants/httpStatusCodes';
import { LIST_SIZE_PER_PAGE } from '../../constants/values';
import { SORT_BY_NAME } from '../../constants/common';
import { fetchEnumsAsync } from '../enums/slice';
import { SALARY_CORRECTION_TYPE_ENUM } from '../../constants/enums';

export const initialState = {
  data: [],
  links: {},
  meta: {},
  isLoading: false,
  isSubmit: false,
  isActiveSubmit: false,
  queryParams: {
    filters: {
      name: '',
      show_inactive: false,
    },
    with: ['creator'],
    sort: SORT_BY_NAME,
    page: null,
    per_page: LIST_SIZE_PER_PAGE,
  },
  nameHeadValue: '',
  modal: {
    modalId: 'salaryCorrectionTypes',
    isModalOpen: false,
  },
  formFields: {
    correctionTypeId: null,
    name: '',
    xeroId: '',
    usingExpenseReport: false,
    compensateUnifiedTax: false,
    description: '',
    trashed: false,
  },
  formErrors: {},
};

export const getSalaryCorrectionTypes = createAsyncThunk(
  'salaryCorrectionType/getSalaryCorrectionTypes',
  async (arg, { getState, rejectWithValue }) => {
    try {
      const { queryParams: params } = getState().salaryCorrectionType;
      const { data, status } = await axios.get('/salary_correction_types', { params });

      return status === httpStatusCodes.OK ? data : rejectWithValue(data.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const removeSalaryCorrectionType = createAsyncThunk(
  'salaryCorrectionType/removeSalaryCorrectionType',
  async ({ id }, { dispatch, fulfillWithValue, rejectWithValue }) => {
    try {
      const { data, status } = await axios.delete(`/salary_correction_types/${id}`);

      if (status === httpStatusCodes.NO_CONTENT) {
        dispatch(getSalaryCorrectionTypes());
        return fulfillWithValue();
      }

      return rejectWithValue(data.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const restoreSalaryCorrectionType = createAsyncThunk(
  'salaryCorrectionType/restoreSalaryCorrectionType',
  async ({ id }, { dispatch, fulfillWithValue, rejectWithValue }) => {
    try {
      const { data, status } = await axios.put(`/salary_correction_types/${id}/restore`);

      if (status === httpStatusCodes.OK) {
        dispatch(getSalaryCorrectionTypes());
        return fulfillWithValue();
      }

      return rejectWithValue(data.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const createOrEditSalaryCorrectionType = createAsyncThunk(
  'salaryCorrectionType/createOrEditSalaryCorrectionType',
  async ({ id }, { dispatch, getState, rejectWithValue }) => {
    try {
      const { formFields } = getState().salaryCorrectionType;
      const errors = validateErrors(formFields, validateSalaryCorrectionTypeForm);

      if (errors) {
        return rejectWithValue({ isFormValidation: true, errors });
      }

      let url = '/salary_correction_types';
      let method = httpRequestMethods.POST;

      if (id) {
        url += `/${id}`;
        method = httpRequestMethods.PUT;
      }

      const { data, status } = await axios({
        url,
        method,
        data: {
          name: formFields.name,
          xero_account_code: formFields.xeroId,
          using_expense_report: formFields.usingExpenseReport,
          compensate_unified_tax: formFields.compensateUnifiedTax,
          description: formFields.description,
        },
      });

      if (status === httpStatusCodes.CREATED || status === httpStatusCodes.OK) {
        dispatch(getSalaryCorrectionTypes());
        return data;
      }

      return rejectWithValue(data.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const salaryCorrectionSlice = createSlice({
  name: 'salaryCorrectionType',
  initialState,
  reducers: {
    setModalData: (state, action) => {
      state.modal = { ...state.modal, ...action.payload };
    },
    setFormErrors: (state, action) => {
      state.formErrors = action.payload;
    },
    setFormFields: (state, action) => {
      state.formFields = action.payload;
    },
    setTableHeadInput: (state, action) => {
      state.nameHeadValue = action.payload;
    },
    setQueryParamsFilters: (state, action) => {
      state.queryParams.filters = { ...state.queryParams.filters, ...action.payload };
      state.queryParams.page = null;
    },
    setPage: (state, action) => {
      state.queryParams.page = action.payload;
    },
    setSort: (state, action) => {
      state.queryParams.sort = action.payload;
    },
    resetModal: (state) => {
      state.formErrors = initialState.formErrors;
      state.modal = initialState.modal;
      state.formFields = initialState.formFields;
    },
    setValidateFormField: (state, action) => {
      const fieldError = validateSalaryCorrectionTypeForm(action.payload.fieldName, action.payload.fieldData);

      state.formErrors = {
        ...state.formErrors,
        [action.payload.fieldName]: fieldError,
      };
      state.formFields = {
        ...state.formFields,
        [action.payload.fieldName]: action.payload.fieldData,
      };
    },
    resetState: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSalaryCorrectionTypes.pending, (state) => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(getSalaryCorrectionTypes.fulfilled, (state, action) => {
        state.data = action.payload.data;
        state.meta = action.payload.meta;
        state.link = action.payload.link;
        state.isLoading = false;
      })
      .addCase(getSalaryCorrectionTypes.rejected, (state, action) => {
        state.error = action.payload;
        state.isLoading = false;
      });
    builder
      .addCase(removeSalaryCorrectionType.pending, (state) => {
        state.error = undefined;
        state.isActiveSubmit = true;
      })
      .addCase(removeSalaryCorrectionType.fulfilled, (state) => {
        state.isActiveSubmit = false;
        state.formFields.trashed = true;
      })
      .addCase(removeSalaryCorrectionType.rejected, (state, action) => {
        state.error = action.payload;
        state.isActiveSubmit = false;
      });
    builder
      .addCase(restoreSalaryCorrectionType.pending, (state) => {
        state.error = undefined;
        state.isActiveSubmit = true;
      })
      .addCase(restoreSalaryCorrectionType.fulfilled, (state) => {
        state.isActiveSubmit = false;
        state.formFields.trashed = false;
      })
      .addCase(restoreSalaryCorrectionType.rejected, (state, action) => {
        state.error = action.payload;
        state.isActiveSubmit = false;
      });
    builder
      .addCase(createOrEditSalaryCorrectionType.pending, (state) => {
        state.isSubmit = true;
      })
      .addCase(createOrEditSalaryCorrectionType.fulfilled, (state) => {
        state.formFields = initialState.formFields;
        state.formErrors = initialState.formErrors;
        state.modal = initialState.modal;
        state.isSubmit = false;
      })
      .addCase(createOrEditSalaryCorrectionType.rejected, (state, action) => {
        state.isSubmit = false;
        if (action.payload?.isFormValidation) {
          state.formErrors = action.payload.errors;
        }
      });

    builder
      .addCase(fetchEnumsAsync.pending, (state, { meta }) => {
        if (meta.arg.enumName === SALARY_CORRECTION_TYPE_ENUM) {
          state.isLoading = true;
        }
      });
  }
});

export const {
  setModalData,
  setPage,
  setSort,
  setQueryParamsFilters,
  resetModal,
  setTableHeadInput,
  setFormFields,
  setFormErrors,
  setValidateFormField,
  resetState,
} = salaryCorrectionSlice.actions;

export default salaryCorrectionSlice.reducer;
