export const MAX_LOGIN_COUNTS = 5;
export const ONE_PAGE = 1;
export const PAGE_DELIMITER = 2;
export const THREE_PAGES_DELIMITER = 3;
export const ENTRY_DELIMITER = 4;
export const MIN_SEARCH_WORD_LENGTH = 2;
export const DAY_MONDAY_INDEX = 1;
export const DAY_FRIDAY_INDEX = 5;
export const DAY_SATURDAY_INDEX = 6;
export const DAY_SUNDAY_INDEX = 0;
export const TOOLTIP_OFFSET_TOP = 10;
export const MAX_SHOW_VACATION_REQUEST = 7;
export const VACATION_MANAGER_IMAGE_WIDTH = 39;
export const NEW_VACATION_MANAGER_IMAGE_WIDTH = 40;
export const VACATION_MANAGERS_TOOLTIP_OFFSET = 12;
export const VACATION_MANAGERS_TOOLTIP_PADDING = 26;
export const VACATION_MANAGERS_TOOLTIP_ITEM_HEIGHT = 39;
export const VACATION_MANAGERS_TOOLTIP_ITEM_HEIGHT_FIX = 11;
export const VACATION_TABLE_ACTION_MORE_TOOLTIP_OFFSET_TOP = 12;
export const VACATION_TABLE_ACTION_MORE_TOOLTIP_WIDTH = 140;
export const MAX_VACATION_ITEM_IN_SMALL_BLOCK = 5;
export const WAITING_LIST_DATEPICKER_OFFSET_TOP = 50;
export const WAITING_LIST_DATEPICKER_WIDTH = 548;
export const WAITING_LIST_ACTIONS_TOOLTIP_WIDTH = 140;
export const OWN_VACATION_SCROLL_LENGTH = 3;
export const WORKLOG_MODAL_DATEPICKER_OFFSET_TOP = 50;
export const MIN_DIFF_BETWEEN_DATES = 6;
export const TIMESHEET_LOG_INFO_WIDTH = 1000;
export const TIMESHEET_MODAL_TOP_OFFSET = 42;
export const TABLE_DATEPICKER_TOP_OFFSET = 46;
export const VACATION_CALENDAR_HEIGHT = 41.5;
export const TABLE_DATEPICKER_WIDTH = 548;
export const TABLE_DATEPICKER_OFFSET_LEFT = 24;
export const TABLE_DROPDOWN_WIDTH = 320;
export const TABLE_DROPDOWN_OFFSET = 30;
export const MINUTES_IN_HOUR = 60;
export const SECONDS_IN_HOUR = 3600;
export const MONTH_IN_YEAR = 12;
export const MIN_LENGTH_TICKET_SEARCH = 1;
export const TOOLTIP_OFFSET_RIGHT = 8;
export const TOOLTIP_OFFSET_LEFT = 50;
export const TOOLTIP_WIDTH_CENTER = 150;
export const MODAL_DATEPICKER_OFFSET_TOP = 50;
export const WORKLOG_MODAL_DATEPICKER_WIDTH = 548;
export const MODAL_RANGE_DATEPICKER_WIDTH = 548;
export const VACATION_MOBILE_ACTION_MORE_OFFSET_TOP = 15;
export const VACATION_MOBILE_ACTION_MORE_OFFSET_LEFT = 95;
export const VACATION_REQUEST_ACTION_MORE_TOOLTIP_OFFSET_TOP = 15;
export const VACATION_REQUEST_ACTION_MORE_TOOLTIP_OFFSET_LEFT = 155;
export const VACATION_MAX_TO_SHOW_SPENT_DAYS = 4;
export const VACATION_HISTORY_MIN_YEAR = 2002;
export const MAX_FETCH_DATA_PER_PAGE = 500;
export const MIN_FETCH_DATA_PER_PAGE = 1;
export const LIST_SIZE_PER_PAGE = 100;
export const LIST_SIZE_PER_PAGE_25 = 25;

export const COPYRIGHT_START_YEAR = 2020;
export const ALERT_AUTO_CLOSE_TIME = 5000;
export const MIN_LOADER_BUTTON_WIDTH = 60;
export const HOURS_PER_MONTH = 168;
export const OVERTIME_HOURLY_RARE_COEFFICIENT = 1.5;
export const DROPDOWN_OFFSET = 8;
export const PROJECT_REPORT_DATEPICKER_OFFSET_TOP = 50;
export const PROJECT_REPORT_DATEPICKER_WIDTH = 318;
export const SALARY_PAYMENT_PERIOD = 7;
export const CV_GENERATOR_PAGE_HEIGHT = 770;
export const CV_GENERATOR_SYMBOL_LENGTH = 400;
export const TO_RADIANS = Math.PI / 180;
export const CLIENT_FEED_PIPEDRIVE_HEIGHT = 70;
export const CV_PDF_HEIGHT = 920;
export const AGREEMENTS_MILESTONE_PERIOD_MAX = 182;
export const WINDOWS_MOBILE_WIDTH = 768;
export const WINDOWS_SMALL_MOBILE_WIDTH = 470;
export const WINDOWS_LARGE_WIDTH = 1512;
export const MIN_VACATION_CALENDAR_SIZE = 1253;
export const TIMELINE_HEADER_SIZE = 72;
export const TIMELINE_INITIAL_SIZE = 0;
export const TIMELINE_ROW_SIZE = 42;
export const CALENDAR_EMPTY_BLOCK = 160;
export const WORKS_HOURS_IN_WEEK = 40;
export const MILESTONE_BILLABILITY_LIMIT = 100;
export const IS_USE_BUILDER_FLAG = 'use_builder is true';

// REGEX
export const REGEX_NUMBER_TWO_DEGREE = /^(?:[0-9]+([.][0-9]{0,2})?|)$/;
export const REGEX_NUMBER = /[^0-9.]/;

// AVATAR SIZES
export const AVATAR_SMALL_SIZE = 24;
export const AVATAR_LARGE_SIZE = 32;

export const FIRST_MONTH_INTEGER = 1;
export const LAST_MONTH_INTEGER = 12;

// STRINGS
export const MAX_DESCRIPTION_LENGTH = 150;
export const MAX_SHORT_DESCRIPTION_LENGTH = 255;
export const MAX_FULL_DESCRIPTION_LENGTH = 1000;
