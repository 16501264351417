import {
  ASSET_INFO_FETCH_ASSET, ASSET_INFO_SET_ABORT, ASSET_INFO_SET_MODAL_DATA, ASSET_INFO_RESET_MODAL,
  ASSET_INFO_SET_MODAL_FIELDS, ASSET_INFO_LIST_SET_SUBMIT, ASSET_INFO_SET_LOG_ERROR, ASSET_INFO_SET_CLICK_COUNT,
  ASSET_INFO_RESET_DELETE_MODAL,
} from './types';
import { LIST_SIZE_PER_PAGE } from '../../constants/values';

export const initialState = {
  asset: {},
  isSubmit: false,
  isDeleteSubmit: false,
  modalErrors: {},
  categoriesData: [],
  queryParams: {
    filters: {
      id: [],
      name: '',
      inventory: '',
      serial: '',
      category_name: '',
      category_id: [],
      part_number: '',
      ean_number: 0,
      user_id: [],
      user_name: '',
      show_unassigned: false,
      show_deleted: false,
    },
    page: null,
    per_page: LIST_SIZE_PER_PAGE,
    with: ['asset_category', 'active_assignment'],
    sort: 'name'
  },
  tableHeadInput: {
    nameInputValue: '',
    inventoryInputValue: '',
    serialInputValue: '',
    partInputValue: '',
    eanInputValue: ''
  },
  assetsInfoModal: {
    modalId: 'assetInfoModal',
    isModalOpen: false,
    isCategoryOpen: false,
    isEmployeeOpen: false,
    isPartNumberOpen: false,
    isNameOpen: false,
    searchEmployeeValue: '',
    shared_device: false,
    isCurrencyOpen: false,
    currencies: [],
    isDepartmentOpen: false,
    departmentSearchValue: ''
  },
  assetsInfoWarningModal: {
    modalId: 'assetInfoWarningModal',
    isModalOpen: false,
  },
  assetsInfoFields: {
    asset_id: null,
    category: null,
    name: '',
    inventory_number: '',
    serial_number: '',
    part_number: '',
    ean_number: null,
    description: '',
    employee: null,
    user_id: '',
    comment: '',
    amount: '',
    currency: '',
    currency_rate: '',
    department: null
  },
  comment: '',
  clickCount: 0,
  abortProps: {
    abortAsset: {},
  },
};

export default function assetInfo(state = initialState, action) {
  switch (action.type) {
    case ASSET_INFO_RESET_DELETE_MODAL:
      return {
        ...state,
        clickCount: 0,
        comment: '',
        modalErrors: {},
        assetsInfoWarningModal: initialState.assetsInfoWarningModal,
        assetsInfoFields: initialState.assetsInfoFields,
      };
    case ASSET_INFO_SET_ABORT:
      return {
        ...state,
        abortProps: {
          ...state.abortProps,
          ...action.abortProps
        }
      };
    case ASSET_INFO_FETCH_ASSET:
      return {
        ...state,
        asset: action.asset,
      };
    case ASSET_INFO_SET_MODAL_DATA:
      return {
        ...state,
        [action.key]: {
          ...state[action.key],
          ...action.data
        }
      };
    case ASSET_INFO_RESET_MODAL:
      return {
        ...state,
        assetsInfoFields: initialState.assetsInfoFields,
        modalErrors: initialState.modalErrors,
        assetsInfoModal: initialState.assetsInfoModal,
      };
    case ASSET_INFO_SET_MODAL_FIELDS:
      return {
        ...state,
        assetsInfoFields: {
          ...state.assetsInfoFields,
          ...action.data
        }
      };
    case ASSET_INFO_LIST_SET_SUBMIT:
      return {
        ...state,
        [action.key]: action.value,
      };
    case ASSET_INFO_SET_LOG_ERROR:
      return {
        ...state,
        modalErrors: action.modalErrors,
      };
    case ASSET_INFO_SET_CLICK_COUNT:
      return {
        ...state,
        clickCount: action.clickCount,
      };
    default:
      return state;
  }
}
