import PropTypes from 'prop-types';

const Bell = ({ width = 24, height = 24, isActive = false }) => (
  <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.0001 11V16L19.2901 17.29C19.9201 17.92 19.4701 19 18.5801 19H5.41005C4.52005 19 4.08005 17.92 4.71005 17.29L6.00005 16V11C6.00005 7.92 7.63005 5.36 10.5001 4.68V4C10.5001 3.17 11.1701 2.5 12.0001 2.5C12.8301 2.5 13.5001 3.17 13.5001 4V4.68C16.3601 5.36 18.0001 7.93 18.0001 11ZM14.0001 20C14.0001 21.1 13.1001 22 12.0001 22C10.8901 22 10.0001 21.1 10.0001 20H14.0001Z"
      fill="#8891A3"
    />
    {isActive ? (
      <path
        d="M17 13C19.2091 13 21 11.2091 21 9C21 6.79086 19.2091 5 17 5C14.7909 5 13 6.79086 13 9C13 11.2091 14.7909 13 17 13Z"
        fill="#FF2E2E"
        stroke="white"
        strokeWidth="2"
      />
    ) : null}
  </svg>
);

Bell.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isActive: PropTypes.bool
};

export default Bell;
