import PropTypes from 'prop-types';

const SearchIcon = ({ width = 18, height = 18, fill = 'currentColor' }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="i-dropdown-search copy 2">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.6914 11.0865H12.3587L15.9401 14.6826C16.2864 15.0288 16.2864 15.5944 15.9401 15.9405C15.5938 16.2866 15.0278 16.2866 14.6815 15.9405L11.0917 12.3527V11.6858L10.8636 11.4495C9.68108 12.4625 8.06777 12.9859 6.35309 12.6988C4.00492 12.3021 2.12975 10.3436 1.84257 7.97992C1.40334 4.40907 4.41036 1.40382 7.9833 1.84279C10.3484 2.1298 12.308 4.00387 12.705 6.35067C12.9922 8.06434 12.4685 9.67671 11.4549 10.8585L11.6914 11.0865ZM3.48967 7.2877C3.48967 9.38969 5.18745 11.0865 7.29067 11.0865C9.3939 11.0865 11.0917 9.38969 11.0917 7.2877C11.0917 5.18571 9.3939 3.48892 7.29067 3.48892C5.18745 3.48892 3.48967 5.18571 3.48967 7.2877Z"
          fill={fill}
        />
      </g>
    </svg>
  );
};

SearchIcon.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  fill: PropTypes.string,
};

export default SearchIcon;
