import PropTypes from 'prop-types';
import Link from 'next/link';
import * as Popover from '@radix-ui/react-popover';
import Avatar from '../Avatar/Avatar';
import { AVATAR_LARGE_SIZE } from '../../constants/values';
import styles from './sass/ManagersMore.module.scss';

const ManagersMore = ({
  remainingItems, numberOfRemainingItems, size, onClick = () => {}
}) => (
  <Popover.Root>
    <Popover.Trigger asChild>
      <button
        type="button"
        className={styles.number}
        data-initial={`+${numberOfRemainingItems}`}
        aria-label="remaining count"
        style={{ 'minWidth': `${size}px`, 'height': `${size}px` }}
      />
    </Popover.Trigger>
    <Popover.Portal>
      <Popover.Content sideOffset={8} className={styles.content}>
        {remainingItems.map((element) => (
          <div className={styles.manager} key={element.user.id}>
            <div className={styles.managerImg}>
              <Avatar image={element.user.photo48_link} alt={element.user.name} title={element.user.name} />
              <div className={styles[element.status]} />
            </div>
            <Link href={`/employees/${element.user.id}`} onClick={onClick}>
              {element.user.name}
            </Link>
          </div>
        ))}
        <Popover.Arrow className="DropdownMenuArrow" />
      </Popover.Content>
    </Popover.Portal>
  </Popover.Root>
);

ManagersMore.propTypes = {
  remainingItems: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  numberOfRemainingItems: PropTypes.number.isRequired,
  size: PropTypes.number.isRequired,
  onClick: PropTypes.func
};

ManagersMore.defaultProos = {
  size: AVATAR_LARGE_SIZE,
};

export default ManagersMore;
