import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../services/axios';
import { newValidateErrors, validateClientProduct, validateErrors } from '../../utils/validators';
import { sendFiles } from '../../utils/sendFiles';

export const initialState = {
  isLoading: true,
  isSubmit: false,
  error: undefined,
  product: undefined,
  productFields: {
    name: '',
    dealLink: '',
    dealBudget: null,
    dealCurrency: '',
    description: '',
    ndaStatus: 'unknown',
    ndaComment: '',
    ndaFile: null,
  },
  fieldsErrors: {},
  isEdit: false,
};

export const fetchProductAsync = createAsyncThunk(
  'clientProduct/fetchProduct',
  async ({ productId }, { rejectWithValue }) => {
    try {
      const { data, status } = await axios.get(`/client_products/${productId}`);

      return status === 200 ? data : rejectWithValue(data.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const editProductAsync = createAsyncThunk(
  'clientProduct/editProduct',
  async (arg, { getState, rejectWithValue }) => {
    try {
      const { product: { id: productId }, productFields } = getState().clientProductInfo;
      const errors = validateErrors(
        productFields,
        validateClientProduct,
        {
          isDealBudget: productFields.dealBudget,
        }
      );

      if (errors) {
        return rejectWithValue({ isFormValidation: true, errors });
      }

      const body = {
        name: productFields.name,
        deal_link: productFields.dealLink,
        deal_budget: productFields.dealBudget,
        deal_currency: productFields.dealCurrency,
        description: productFields.description,
        nda_status: !productFields.ndaStatus ? 'unknown' : productFields.ndaStatus,
        nda_comment: productFields.ndaComment,
      };

      if (productFields.ndaFile?.file) {
        const mediaIds = await sendFiles([productFields.ndaFile.file]);

        if (mediaIds.error) {
          return rejectWithValue(mediaIds);
        }

        mediaIds.data = mediaIds.data.filter((item) => item !== undefined);
        body.nda_file_id = mediaIds.data[0] || null;
      } else if (!productFields.ndaFile) {
        body.nda_file_id = null;
      }

      const { data, status } = await axios.put(`/client_products/${productId}`, body);

      return status === 200 ? data : rejectWithValue(data.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const clientProductSlice = createSlice({
  name: 'clientProduct',
  initialState,
  reducers: {
    resetState: () => initialState,
    setQueryFilter: (state, action) => {
      state.query.filters = { ...state.query.filters, ...action.payload };
    },
    setQuery: (state, action) => {
      state.query = { ...state.query, ...action.payload };
    },
    setEdit: (state, action) => {
      if (action.payload === true) {
        state.productFields = {
          name: state.product.name,
          dealLink: state.product.deal_link,
          dealBudget: state.product.deal_budget,
          dealCurrency: state.product.deal_currency,
          ndaStatus: state.product.nda_status,
          ndaComment: state.product.nda_comment || '',
          ndaFile: state.product.nda_file,
          description: state.product.description || '',
        };
      } else {
        state.productFields = initialState.productFields;
        state.fieldsErrors = initialState.fieldsErrors;
      }
      state.isEdit = action.payload;
    },
    setFieldsData: (state, action) => {
      state.productFields = { ...state.productFields, ...action.payload };
      state.fieldsErrors = {
        ...state.fieldsErrors,
        ...newValidateErrors({ ...action.payload }, validateClientProduct),
      };
    },
    setData: (state, action) => {
      return ({ ...state, ...action.payload });
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchProductAsync.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchProductAsync.fulfilled, (state, action) => {
        state.product = action.payload;
        state.isLoading = false;
      })
      .addCase(fetchProductAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(editProductAsync.pending, (state) => {
        state.isSubmit = true;
        state.error = undefined;
        state.fieldsErrors = initialState.fieldsErrors;
      })
      .addCase(editProductAsync.fulfilled, (state, action) => {
        state.product = action.payload;
        state.productFields = initialState.productFields;
        state.fieldsErrors = initialState.fieldsErrors;
        state.isEdit = false;
        state.isSubmit = false;
      })
      .addCase(editProductAsync.rejected, (state, action) => {
        if (action.payload?.isFormValidation) {
          state.fieldsErrors = action.payload.errors;
        } else {
          state.error = action.payload;
        }
        state.isSubmit = false;
      });
  },
});

export const {
  setQueryFilter,
  setQuery,
  setEdit,
  setData,
  setFieldsData,
  resetState,
} = clientProductSlice.actions;

export default clientProductSlice.reducer;
