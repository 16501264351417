export const formatHours = (hours) => {
  let wholeHours = Math.floor(hours);
  let minutes = Math.round((hours - wholeHours) * 60);

  if (minutes === 60) {
    minutes = 0;
    wholeHours += 1;
  }

  const formattedHours = wholeHours.toString();

  if (minutes === 0) {
    return formattedHours;
  }

  const formattedMinutes = String(minutes).padStart(2, '0');
  return `${formattedHours}:${formattedMinutes}`;
};

export const convertHoursToStrings = (data) => {
  return Object.entries(data).reduce((acc, [key, value]) => {
    return { ...acc, [key]: formatHours(value) };
  }, {});
};
