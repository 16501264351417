import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../services/axios';
import httpRequestMethods from '../../constants/httpRequestMethods';
import * as httpStatusCodes from '../../constants/httpStatusCodes';
import { validateErrors, validateSystemsDepartmentsForm } from '../../utils/validators';
import { LIST_SIZE_PER_PAGE } from '../../constants/values';
import { GMP_SCHEMA_PARAMS, BHR_SCHEMA_PARAMS, SORT_BY_NAME } from '../../constants/common';

export const initialState = {
  data: [],
  links: {},
  meta: {},
  department: null,
  isFetching: false,
  isSubmit: false,
  isActiveSubmit: false,
  queryParams: {
    filters: {
      name: '',
      activity: true,
    },
    with: ['head', 'lead', 'parent'],
    sort: SORT_BY_NAME,
    page: null,
    per_page: LIST_SIZE_PER_PAGE,
  },
  modal: {
    modalId: 'systemsDepartmentsModal',
    isModalOpen: false,
    departmentId: null,
  },
  formFields: {
    name: '',
    baseXero: '',
    projectXero: '',
    bonusXero: '',
    parent: null,
    head: null,
    lead: null,
    description: '',
    isDelivery: false,
    isActive: false,
    schema: '',
    loRate: '',
    hiRate: '',
    percent: ''
  },
  formErrors: {},
};

export const fetchDepartments = createAsyncThunk(
  'systemsDepartments/fetchDepartments',
  async (arg, { getState, rejectWithValue }) => {
    try {
      const { queryParams: params } = getState().systemsDepartments;
      const { data, status } = await axios.get('/departments', { params });

      return status === httpStatusCodes.OK ? data : rejectWithValue(data.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchDepartmentById = createAsyncThunk(
  'systemsDepartments/fetchDepartmentById',
  async ({ id }, { rejectWithValue }) => {
    try {
      const { data, status } = await axios.get(`/departments/${id}`);

      return status === httpStatusCodes.OK ? data : rejectWithValue(data.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const createOrEditDepartment = createAsyncThunk(
  'systemsDepartments/createOrEditDepartment',
  async ({ id }, { dispatch, getState, rejectWithValue }) => {
    try {
      const { formFields } = getState().systemsDepartments;

      const errors = validateErrors(
        formFields,
        validateSystemsDepartmentsForm,
        {
          isBhrSchema: formFields.schema === BHR_SCHEMA_PARAMS,
          isGmpSchema: formFields.schema === GMP_SCHEMA_PARAMS,
        }
      );

      if (errors) {
        return rejectWithValue({ isFormValidation: true, errors });
      }

      let url = '/departments';
      let method = httpRequestMethods.POST;

      if (id) {
        url += `/${id}`;
        method = httpRequestMethods.PUT;
      }

      const body = {
        name: formFields.name,
        description: formFields.description,
        parent_id: formFields.parent?.id,
        lead_id: formFields.lead?.id,
        head_id: formFields.head?.id,
        is_delivery: formFields.isDelivery,
        base_xero_account_code: formFields.baseXero,
        project_xero_account_code: formFields.projectXero,
        bonus_xero_account_code: formFields.bonusXero,
        schema: formFields.schema,
        schema_params: {},
        activity: formFields.isActive
      };

      if (formFields.schema === GMP_SCHEMA_PARAMS) {
        body.schema_params.percent = formFields.percent / 100;
      }

      if (formFields.schema === BHR_SCHEMA_PARAMS) {
        body.schema_params.lo_rate = +formFields.loRate;
        body.schema_params.hi_rate = +formFields.hiRate;
      }

      const { data, status } = await axios({ url, method, data: body });

      if (status === httpStatusCodes.CREATED || status === httpStatusCodes.OK) {
        dispatch(fetchDepartments());
        return data;
      }

      return rejectWithValue(data.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const systemsDepartmentsSlice = createSlice({
  name: 'systemsDepartments',
  initialState,
  reducers: {
    setModalData: (state, action) => {
      state.modal = { ...state.modal, ...action.payload };
    },
    setFormErrors: (state, action) => {
      state.formErrors = action.payload;
    },
    setFormFields: (state, action) => {
      state.formFields = action.payload;
    },
    setQueryParamsFilters: (state, action) => {
      state.queryParams.filters = { ...state.queryParams.filters, ...action.payload };
      state.queryParams.page = null;
    },
    setPage: (state, action) => {
      state.queryParams.page = action.payload;
    },
    setSort: (state, action) => {
      state.queryParams.sort = action.payload;
    },
    resetModal: (state) => {
      state.formErrors = initialState.formErrors;
      state.modal = initialState.modal;
      state.formFields = initialState.formFields;
    },
    setValidateFormField: (state, action) => {
      const fieldError = validateSystemsDepartmentsForm(action.payload.fieldName, action.payload.fieldValue);

      state.formErrors = {
        ...state.formErrors,
        [action.payload.fieldName]: fieldError,
      };
      state.formFields = {
        ...state.formFields,
        [action.payload.fieldName]: action.payload.fieldValue,
      };
    },
    setData: (state, action) => {
      return ({ ...state, ...action.payload });
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchDepartments.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(fetchDepartments.fulfilled, (state, action) => {
        state.data = action.payload.data;
        state.meta = action.payload.meta;
        state.link = action.payload.link;
        state.isFetching = false;
      })
      .addCase(fetchDepartments.rejected, (state) => {
        state.isFetching = false;
      });

    builder
      .addCase(createOrEditDepartment.pending, (state) => {
        state.isSubmit = true;
      })
      .addCase(createOrEditDepartment.fulfilled, (state) => {
        state.formFields = initialState.formFields;
        state.formErrors = initialState.formErrors;
        state.modal = initialState.modal;
        state.isSubmit = false;
      })
      .addCase(createOrEditDepartment.rejected, (state, action) => {
        if (action.payload.isFormValidation) {
          state.formErrors = action.payload.errors;
        }
        state.isSubmit = false;
      });

    builder
      .addCase(fetchDepartmentById.fulfilled, (state, action) => {
        state.department = action.payload;
      });
  }
});

export const {
  setData,
  setModalData,
  setPage,
  setSort,
  setQueryParamsFilters,
  resetModal,
  setFormFields,
  setFormErrors,
  setValidateFormField,
} = systemsDepartmentsSlice.actions;

export default systemsDepartmentsSlice.reducer;
