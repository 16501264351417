export const SUBSCRIPTIONS_TAB = 'subscriptions';
export const NEEDTOPAY_TAB = 'needToPay';
export const INDIVIDUAL_TAB = 'individual';
export const EXPENSES_TAB = 'expenses';
export const SUBSCRIPTIONS_ACCOUNTANT_TABS = [
  SUBSCRIPTIONS_TAB, NEEDTOPAY_TAB, INDIVIDUAL_TAB, EXPENSES_TAB
];
export const SUBSCRIPTIONS_HEAD_TABS = [SUBSCRIPTIONS_TAB, INDIVIDUAL_TAB, EXPENSES_TAB];
export const DEPARTMENT_TYPE = 'department';
export const INDIVIDUAL_TYPE = 'individual';
export const EDIT_SUBSCRIPTION = 'edit';
export const APPROVE_SUBSCRIPTION = 'approve';
export const COPY_SUBSCRIPTION = 'copy';
export const REVIEW_SUBSCRIPTION = 'review';
export const REJECT_SUBSCRIPTION = 'reject';
export const TERM_ONE_TIME = 'one-time';
