import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../services/axios';
import httpRequestMethods from '../../constants/httpRequestMethods';
import { validateErrors, validatePaymentMethodsForm } from '../../utils/validators';
import { SORT_BY_NAME } from '../../constants/common';
import { CREATED, OK } from '../../constants/httpStatusCodes';
import { STATUS_ACTIVE } from '../../constants/status';
import { LIST_SIZE_PER_PAGE } from '../../constants/values';

export const initialState = {
  data: [],
  links: {},
  meta: {},
  isLoading: false,
  isSubmit: false,
  queryParams: {
    filters: {
      id: [],
      xero_branding_theme_id: [],
      agreement_company: '',
      is_upwork: null,
      status: [STATUS_ACTIVE],
    },
    page: null,
    per_page: LIST_SIZE_PER_PAGE,
    sort: SORT_BY_NAME,
  },
  modal: {
    modalId: 'paymentMethods',
    isModalOpen: false,
  },
  formFields: {
    id: null,
    name: '',
    xero_branding_theme_id: '',
    agreement_company: '',
    currencies: [],
    is_upwork: true,
    status: STATUS_ACTIVE,
  },
  formErrors: {},
};

export const getPaymentMethods = createAsyncThunk(
  'paymentMethods/getPaymentMethods',
  async (arg, { getState, rejectWithValue }) => {
    try {
      const { queryParams: params } = getState().paymentMethods;
      const { data, status } = await axios.get('/payment_methods', { params });

      return status === 200 ? data : rejectWithValue(data.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const createOrEditPaymentMethod = createAsyncThunk(
  'paymentMethods/createOrEditPaymentMethod',
  async ({ id }, { dispatch, getState, rejectWithValue }) => {
    try {
      const { formFields } = getState().paymentMethods;
      const errors = validateErrors(formFields, validatePaymentMethodsForm);

      if (errors) {
        return rejectWithValue({ isFormValidation: true, errors });
      }

      let url = '/payment_methods';
      let method = httpRequestMethods.POST;

      if (id) {
        url += `/${id}`;
        method = httpRequestMethods.PUT;
      }

      const body = {
        name: formFields.name,
        xero_branding_theme_id: formFields.xero_branding_theme_id,
        agreement_company: formFields.agreement_company,
        currencies: formFields.currencies,
        is_upwork: formFields.is_upwork,
        status: formFields.status,
      };

      const { data, status } = await axios({ url, method, data: body });

      if (status === CREATED || status === OK) {
        dispatch(getPaymentMethods());
        return data;
      }

      return rejectWithValue(data.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const paymentMethodsSlice = createSlice({
  name: 'paymentMethods',
  initialState,
  reducers: {
    setModalData: (state, action) => {
      state.modal = { ...state.modal, ...action.payload };
    },
    setFormErrors: (state, action) => {
      state.formErrors = action.payload;
    },
    setFormFields: (state, action) => {
      state.formFields = action.payload;
    },
    setQueryParamsFilters: (state, action) => {
      state.queryParams.filters = { ...state.queryParams.filters, ...action.payload };
      state.queryParams.page = null;
    },
    setPage: (state, action) => {
      state.queryParams.page = action.payload;
    },
    resetModal: (state) => {
      state.formErrors = initialState.formErrors;
      state.modal = initialState.modal;
      state.formFields = initialState.formFields;
    },
    setValidateFormField: (state, action) => {
      const fieldError = validatePaymentMethodsForm(action.payload.fieldName, action.payload.fieldData);

      state.formErrors = {
        ...state.formErrors,
        [action.payload.fieldName]: fieldError,
      };
      state.formFields = {
        ...state.formFields,
        [action.payload.fieldName]: action.payload.fieldData,
      };
    },
    resetState: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPaymentMethods.pending, (state) => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(getPaymentMethods.fulfilled, (state, action) => {
        state.data = action.payload.data;
        state.meta = action.payload.meta;
        state.link = action.payload.link;
        state.isLoading = false;
      })
      .addCase(getPaymentMethods.rejected, (state, action) => {
        state.error = action.payload;
        state.isLoading = false;
      });

    builder
      .addCase(createOrEditPaymentMethod.pending, (state) => {
        state.isSubmit = true;
      })
      .addCase(createOrEditPaymentMethod.fulfilled, (state) => {
        state.formFields = initialState.formFields;
        state.formErrors = initialState.formErrors;
        state.modal = initialState.modal;
        state.isSubmit = false;
      })
      .addCase(createOrEditPaymentMethod.rejected, (state, action) => {
        if (action.payload.isFormValidation) {
          state.formErrors = action.payload.errors;
        }
        state.isSubmit = false;
      });
  }
});

export const {
  setModalData,
  setPage,
  setQueryParamsFilters,
  resetModal,
  setFormFields,
  setFormErrors,
  setValidateFormField,
  resetState,
} = paymentMethodsSlice.actions;

export default paymentMethodsSlice.reducer;
