export const EMPLOYEE_INFO_FETCH_EMPLOYEE = 'EMPLOYEE_INFO_FETCH_EMPLOYEE';
export const EMPLOYEE_INFO_SET_EDIT = 'EMPLOYEE_INFO_SET_EDIT';
export const EMPLOYEE_INFO_SET_DATA = 'EMPLOYEE_INFO_SET_DATA';
export const EMPLOYEE_INFO_SET_EDIT_PROPS = 'EMPLOYEE_INFO_SET_EDIT_PROPS';
export const EMPLOYEE_INFO_SET_FORM_ERRORS = 'EMPLOYEE_INFO_SET_FORM_ERRORS';
export const EMPLOYEE_INFO_FETCH_ROLES = 'EMPLOYEE_INFO_FETCH_ROLES';
export const EMPLOYEE_INFO_FETCH_POSITIONS = 'EMPLOYEE_INFO_FETCH_POSITIONS';
export const EMPLOYEE_INFO_SET_SUBMIT = 'EMPLOYEE_INFO_SET_SUBMIT';
export const EMPLOYEE_FETCH_STATISTIC = 'EMPLOYEE_FETCH_STATISTIC';
export const EMPLOYEE_INFO_SET_MODAL_DATA = 'EMPLOYEE_INFO_SET_MODAL_DATA';
