import Router from 'next/router';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../services/axios';
import { formatStringDate } from '../../utils/helpers';
import { newValidateErrors, validateClientAgreement, validateErrors } from '../../utils/validators';

const date = formatStringDate(new Date());

export const initialState = {
  isLoading: true,
  isSubmit: false,
  error: undefined,
  agreement: undefined,
  agreementFields: {
    name: '',
    isUpwork: false,
    startDate: date,
    endDate: null,
    currency: null,
    paymentMethod: null,
    pmCommentText: '',
    ourCompany: null,
    clientCompany: null,
    ourCompanyOtherText: '',
    nonCompete: false,
    notifyTeamChanging: false,
    comment: '',
    isServiceAgreement: false,
    nonCompeteTerms: '',
    nonDisclosure: false,
    overtimeAllowed: false,
  },
  agreementFieldsErrors: {},
  isClientCompanyOpen: false,
  isCurrencyOpen: false,
  isCompanyOpen: false,
  isStartDateOpen: false,
  isEndDateOpen: false,
  isPaymentMethodOpen: false,
  isEdit: false,
  clientCompanyInputValue: '',
};

export const fetchAgreementAsync = createAsyncThunk(
  'agreement/fetchAgreement',
  async ({ agreementId }, { rejectWithValue }) => {
    try {
      const { data, status } = await axios.get(`/client_agreements/${agreementId}`);

      return status === 200 ? data : rejectWithValue(data.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const editAgreementAsync = createAsyncThunk(
  'agreement/editAgreement',
  async (arg, { getState, rejectWithValue }) => {
    try {
      const { agreement: { id: agreementId }, agreementFields } = getState().agreement;
      const errors = validateErrors(
        agreementFields,
        validateClientAgreement,
        {
          ourCompany: agreementFields.ourCompany,
          paymentMethod: agreementFields.paymentMethod,
          isServiceAgreement: agreementFields.isServiceAgreement,
        }
      );

      if (errors) {
        return rejectWithValue({ isFormValidation: true, errors });
      }

      const params = {
        name: agreementFields.name,
        start_date: formatStringDate(agreementFields.startDate, 'y-MM-dd'),
        pm_comment_text: agreementFields.pmCommentText,
        our_company: agreementFields.ourCompany,
        our_company_other_text: agreementFields.ourCompanyOtherText,
        service_agreement: agreementFields.isServiceAgreement,
        non_compete: agreementFields.nonCompete,
        non_compete_terms: agreementFields.nonCompeteTerms,
        non_disclosure: agreementFields.nonDisclosure,
        comments: agreementFields.comment,
        client_id: Router.query.id,
        client_company_ids: agreementFields.clientCompanies?.map(({ id }) => id) || []
      };

      if (agreementFields.isServiceAgreement) {
        params.is_upwork = !!agreementFields.isUpwork;
        params.overtime_allowed = !!agreementFields.overtimeAllowed;
        params.notify_team_changing = !!agreementFields.notifyTeamChanging;
        params.currency = agreementFields.currency;
        params.end_date = agreementFields.endDate ? formatStringDate(agreementFields.endDate, 'y-MM-dd') : null;
        params.payment_method_id = agreementFields.paymentMethod?.id || null;
      }

      const { data, status } = await axios.put(`/client_agreements/${agreementId}`, params);

      return status === 200 ? data : rejectWithValue(data.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const agreementSlice = createSlice({
  name: 'agreement',
  initialState,
  reducers: {
    resetState: () => initialState,
    setQueryFilter: (state, action) => {
      state.query.filters = { ...state.query.filters, ...action.payload };
    },
    setQuery: (state, action) => {
      state.query = { ...state.query, ...action.payload };
    },
    setEdit: (state, action) => {
      if (action.payload.isEdit) {
        state.agreementFields = {
          name: state.agreement.name,
          isUpwork: state.agreement.is_upwork,
          overtimeAllowed: state.agreement.overtime_allowed,
          startDate: formatStringDate(new Date(state.agreement.start_date)),
          endDate: state.agreement.end_date ? formatStringDate(state.agreement.end_date) : null,
          currency: state.agreement.currency,
          paymentMethod: state.agreement.payment_method,
          pmCommentText: state.agreement.pm_comment_text || '',
          ourCompany: state.agreement.our_company,
          ourCompanyOtherText: state.agreement.our_company_other_text || '',
          nonCompete: state.agreement.non_compete,
          nonCompeteTerms: state.agreement.non_compete_terms,
          nonDisclosure: state.agreement.non_disclosure,
          isServiceAgreement: state.agreement.service_agreement,
          notifyTeamChanging: state.agreement.notify_team_changing,
          comment: state.agreement.comments || '',
          clientCompanies: state.agreement.client_companies,
        };
      } else {
        state.agreementFields = initialState.agreementFields;
        state.agreementFieldsErrors = initialState.agreementFieldsErrors;
      }
      state.isEdit = action.payload.isEdit;
    },
    setFieldsData: (state, action) => {
      state.agreementFields = { ...state.agreementFields, ...action.payload };
      state.isCurrencyOpen = false;
      state.isPaymentMethodOpen = false;
      state.isCompanyOpen = false;
      state.isStartDateOpen = false;
      state.isEndDateOpen = false;
      state.isClientCompanyOpen = false;
      state.agreementFieldsErrors = {
        ...state.agreementFieldsErrors,
        ...newValidateErrors(action.payload, validateClientAgreement),
      };
    },
    setData: (state, action) => {
      return ({ ...state, ...action.payload });
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAgreementAsync.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchAgreementAsync.fulfilled, (state, action) => {
        state.agreement = action.payload;
        state.agreementFields = action.payload;
        state.isLoading = false;
      })
      .addCase(fetchAgreementAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(editAgreementAsync.pending, (state) => {
        state.isSubmit = true;
        state.error = undefined;
        state.agreementFieldsErrors = initialState.agreementFieldsErrors;
      })
      .addCase(editAgreementAsync.fulfilled, (state, action) => {
        state.agreement = action.payload;
        state.agreementFields = initialState.agreementFields;
        state.agreementFieldsErrors = initialState.agreementFieldsErrors;
        state.isEdit = false;
        state.isSubmit = false;
      })
      .addCase(editAgreementAsync.rejected, (state, action) => {
        if (action.payload.isFormValidation) {
          state.agreementFieldsErrors = action.payload.errors;
        } else {
          state.error = action.payload;
        }
        state.isSubmit = false;
      });
  },
});

export const {
  setQueryFilter,
  setQuery,
  setEdit,
  setData,
  setFieldsData,
  resetState,
} = agreementSlice.actions;

export default agreementSlice.reducer;
