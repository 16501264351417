import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { format, parseISO } from 'date-fns';
import Router from 'next/router';
import axios from '../../services/axios';
import { newValidateErrors, validateAgreementService, validateErrors } from '../../utils/validators';

const today = new Date();

export const initialState = {
  isLoading: true,
  isSubmit: false,
  error: undefined,
  service: undefined,
  serviceFields: {
    id: '',
    name: '',
    department: null,
    rate: '',
    rateType: '',
    overtimeRate: '',
    startDate: today,
    endDate: null
  },
  serviceProps: {
    serviceId: null,
    isStartDateOpen: false,
    isEndDateOpen: false,
    isRateTypeOpen: false,
    isDepartmentOpen: false,
    searchDepartmentValue: ''
  },
  errors: {},
  isEdit: false,
};

export const fetchAgreementServiceAsync = createAsyncThunk(
  'agreementService/fetchAgreementService',
  async ({ serviceId }, { rejectWithValue }) => {
    try {
      const { data, status } = await axios.get(`/agreement_services/${serviceId}`);

      return status === 200 ? data : rejectWithValue(data.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const editAgreementServiceAsync = createAsyncThunk(
  'agreementService/editAgreementService',
  async ({ serviceId }, { getState, rejectWithValue }) => {
    try {
      const { agreementServiceInfo: { serviceFields } } = getState();
      const errors = validateErrors(serviceFields, validateAgreementService, {
        rateType: serviceFields.rateType,
        endDate: serviceFields.endDate
      });

      if (errors) {
        return rejectWithValue({ isFormValidation: true, errors });
      }

      const { query } = Router;

      const body = {
        name: serviceFields.name,
        department_id: serviceFields.department.id,
        rate: serviceFields.rate,
        rate_type: serviceFields.rateType,
        overtime_hourly_rate: serviceFields.overtimeRate,
        start_date: format(serviceFields.startDate, 'yyyy-MM-dd'),
        end_date: serviceFields.endDate ? format(serviceFields.endDate, 'yyyy-MM-dd') : null,
        client_agreement_id: query.agreement,
        client_id: query.id,
      };

      const { data, status } = await axios.put(`/agreement_services/${serviceId}`, body);

      return status === 200 ? data : rejectWithValue(data.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const agreementServiceSlice = createSlice({
  name: 'agreementService',
  initialState,
  reducers: {
    resetState: () => initialState,
    setEdit: (state, action) => {
      if (action.payload === true) {
        state.serviceFields = {
          id: state.service.id,
          name: state.service.name,
          department: state.service.department,
          rate: state.service.rate?.toString(),
          rateType: state.service.rate_type,
          overtimeRate: state.service.overtime_hourly_rate?.toString(),
          startDate: state.service.start_date ? parseISO(state.service.start_date) : null,
          endDate: state.service.end_date ? parseISO(state.service.end_date) : null,
        };
      } else {
        state.serviceFields = initialState.serviceFields;
        state.errors = initialState.errors;
      }
      state.isEdit = action.payload;
    },
    setFieldsData: (state, action) => {
      state.serviceFields = { ...state.serviceFields, ...action.payload };
      state.errors = {
        ...state.errors,
        ...newValidateErrors(
          { ...action.payload },
          validateAgreementService,
          { rateType: state.serviceFields.rateType }
        )
      };
    },
    setData: (state, action) => {
      state.serviceProps = { ...state.serviceProps, ...action.payload };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAgreementServiceAsync.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchAgreementServiceAsync.fulfilled, (state, action) => {
        state.service = action.payload;
        state.serviceFields = action.payload;
        state.isLoading = false;
      })
      .addCase(fetchAgreementServiceAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(editAgreementServiceAsync.pending, (state) => {
        state.isSubmit = true;
        state.error = undefined;
        state.errors = initialState.errors;
      })
      .addCase(editAgreementServiceAsync.fulfilled, (state, action) => {
        state.service = action.payload;
        state.serviceFields = initialState.serviceFields;
        state.errors = initialState.errors;
        state.isEdit = false;
        state.isSubmit = false;
      })
      .addCase(editAgreementServiceAsync.rejected, (state, action) => {
        if (action.payload?.isFormValidation) {
          state.errors = action.payload.errors;
        } else {
          state.error = action.payload;
        }
        state.isSubmit = false;
      });
  },
});

export const {
  setEdit,
  setData,
  setFieldsData,
  resetState,
} = agreementServiceSlice.actions;

export default agreementServiceSlice.reducer;
