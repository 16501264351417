import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../services/axios';
import { getPeriodLists } from '../../utils/vacation';

export const initialState = {
  isLoading: false,
  requests: [],
};

export const fetchVacationUpcomingRequests = createAsyncThunk(
  'userHoverCard/upcoming-requests',
  async ({ id }, { rejectWithValue, signal }) => {
    const params = {
      per_page: 500,
      page: 1,
      with: ['user'],
      filters: {
        status: ['approved'],
        user: { id: [id] },
      }
    };
    try {
      const { data, status } = await axios.get('/vacations', { params, signal });

      return status === 200 ? data.data : rejectWithValue(data.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const userHoverCardSlice = createSlice({
  name: 'userHoverCard',
  initialState,
  extraReducers: (build) => {
    build
      .addCase(fetchVacationUpcomingRequests.pending, (state) => {
        state.isLoading = true;
        state.isShow = true;
      })
      .addCase(fetchVacationUpcomingRequests.fulfilled, (state, action) => {
        let stateRequest = [...state.requests];

        if (stateRequest.length === 10) {
          stateRequest.shift();
        } else {
          stateRequest = state.requests;
        }

        state.isLoading = false;
        state.requests = [
          ...stateRequest,
          { id: action.payload[0].user.id, ...getPeriodLists(action.payload) }
        ];
      })
      .addCase(fetchVacationUpcomingRequests.rejected, (state) => {
        state.isLoading = false;
      });
  }
});

export default userHoverCardSlice.reducer;
