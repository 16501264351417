import PropTypes from 'prop-types';

const CheckCircleIcon = ({ width = 16, height = 16, fill = '#FFA90B' }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g>
        <path
          d="M7.99992 1.83331C4.31992 1.83331 1.33325 4.81998 1.33325 8.49998C1.33325 12.18 4.31992 15.1666 7.99992 15.1666C11.6799 15.1666 14.6666 12.18 14.6666 8.49998C14.6666 4.81998 11.6799 1.83331 7.99992 1.83331ZM7.99992 13.8333C5.05992 13.8333 2.66659 11.44 2.66659 8.49998C2.66659 5.55998 5.05992 3.16665 7.99992 3.16665C10.9399 3.16665 13.3333 5.55998 13.3333 8.49998C13.3333 11.44 10.9399 13.8333 7.99992 13.8333ZM11.0599 5.55331L6.66658 9.94665L4.93992 8.22665L3.99992 9.16665L6.66658 11.8333L11.9999 6.49998L11.0599 5.55331Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_20171_14345">
          <rect width={width} height={height} fill="white" transform="translate(0 0.5)" />
        </clipPath>
      </defs>
    </svg>
  );
};

CheckCircleIcon.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  fill: PropTypes.string,
};

export default CheckCircleIcon;
