export const isEqual = (a, b) => {
  return JSON.stringify([...new Set(a)].sort()) === JSON.stringify([...new Set(b)].sort());
};

export const isEmpty = (arr) => {
  return Array.isArray(arr) && !arr.length;
};

export const merge = (a, b) => {
  return [...a, ...b];
};

export const sortBy = (arr, k, compareFn = undefined) => {
  const defaultCompare = (a, b) => {
    if (a[k] > b[k]) {
      return 1;
    }

    if (a[k] < b[k]) {
      return -1;
    }

    return 0;
  };

  return arr.concat().sort(compareFn || defaultCompare);
};

export const groupBy = (arr, k) => {
  return arr.reduce((acc, item) => ({ ...acc, [item[k]]: [...(acc[item[k]] || []), item] }), {});
};

export const removeValue = (arr, value) => {
  return arr.filter((ele) => ele !== value);
};

export const removeValuesFromArray = (source, valuesToRemove) => {
  return source.filter((item) => !valuesToRemove.includes(item));
};

export const wrapToArray = (value) => {
  if (!value) {
    return [];
  }

  if (Array.isArray(value)) {
    return value;
  }

  return [value];
};

export const uniqueObjArray = (arr, key) => arr.filter((v, i, a) => a.findIndex((t) => t[key] === v[key]) === i);

export const moveElement = (array, fromIndex, toIndex) => {
  const arrayCopy = [...array];
  const element = arrayCopy.splice(fromIndex, 1)[0];

  arrayCopy.splice(toIndex, 0, element);

  return arrayCopy;
};

export const sortByCondition = (arr, condition, sortAZSecondArr = false) => {
  const actionArr = arr?.filter((e) => (condition(e)
    ? e : false)) || [];
  const unActionArr = arr?.filter((el) => (condition(el)
    ? false : el)) || [];

  if (sortAZSecondArr) {
    unActionArr.sort((a, b) => a.name.localeCompare(b.name));
  }

  return merge(actionArr, unActionArr);
};

export const createArrayWithDefaults = (arr, length, initial = {}) => {
  return Array.from({ length: Math.max(length, arr.length) }, (_, index) => ({
    id: `id_${index + 1}`,
    ...(index < arr.length ? arr[index] : initial)
  }));
};

export const chunkArray = (arr, number) => {
  const result = [];
  let currentSubarray = [];

  for (let i = 0; i < arr.length; i += 1) {
    const element = arr[i];
    currentSubarray.push(element);

    if (currentSubarray.length === number) {
      result.push(currentSubarray);
      currentSubarray = [];
    }
  }

  if (currentSubarray.length > 0) {
    result.push(currentSubarray);
  }

  return result;
};
