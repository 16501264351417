import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../services/axios';
import { OK } from '../../constants/httpStatusCodes';
import { GLOBAL_SEARCH } from '../../constants/apiReqUrls';

export const initialState = {
  isLoading: false,
  error: undefined,
  data: undefined,
};

export const getGlobalSearch = createAsyncThunk(
  'countryCode/getGlobalSearch',
  async (params, { rejectWithValue }) => {
    try {
      const { data, status } = await axios.get(GLOBAL_SEARCH, { params });

      return status === OK ? data : rejectWithValue(data.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const globalSearchSlice = createSlice({
  name: 'globalSearch',
  initialState,
  reducers: {
    resetState: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getGlobalSearch.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getGlobalSearch.fulfilled, (state, action) => {
        state.data = Object.entries(action.payload).reduce((acc, [key, data]) => {
          return {
            ...acc,
            [key]: data.map((item) => ({ ...item, type: key })),
          };
        }, {});
        state.isLoading = false;
      })
      .addCase(getGlobalSearch.rejected, (state, action) => {
        state.error = action.payload;
        state.isLoading = false;
        state.data = undefined;
      });
  },
});

export const { resetState } = globalSearchSlice.actions;

export default globalSearchSlice.reducer;
