import { useCallback, useEffect, useRef } from 'react';

const useDebounceFn = (func, options = {}) => {
  const { leading = false, trailing = true, wait = 500 } = options;
  const timerRef = useRef(null);
  const leadingTimeoutRef = useRef(null);

  const debounced = useCallback(
    (...args) => {
      if (leadingTimeoutRef.current) {
        clearTimeout(leadingTimeoutRef.current);
      }

      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }

      if (leading) {
        func(...args);
        leadingTimeoutRef.current = setTimeout(() => {
          leadingTimeoutRef.current = null;
        }, wait);
      }

      if (trailing) {
        timerRef.current = setTimeout(() => {
          func(...args);
          timerRef.current = null;
        }, wait);
      }
    },
    [func, wait, leading, trailing]
  );

  const cancel = useCallback(() => {
    if (leadingTimeoutRef.current) {
      clearTimeout(leadingTimeoutRef.current);
      leadingTimeoutRef.current = null;
    }

    if (timerRef.current) {
      clearTimeout(timerRef.current);
      timerRef.current = null;
    }
  }, []);

  useEffect(() => {
    return cancel;
  }, [cancel]);

  const run = (...args) => {
    cancel();
    debounced(...args);
  };

  const flush = () => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
      timerRef.current = null;
    }
  };

  return { run, cancel, flush };
};

export default useDebounceFn;
