import { createListenerMiddleware, isAsyncThunkAction } from '@reduxjs/toolkit';
import { addNotification } from './slice';

const { startListening, middleware } = createListenerMiddleware();

startListening({
  matcher: isAsyncThunkAction(),
  effect: async (action, listenerApi) => {
    const { requestStatus } = action.meta;
    const { dispatch } = listenerApi;

    if (requestStatus === 'rejected' && action.payload && !action.payload?.isFormValidation) {
      const { response } = action.payload;

      if (response) {
        const { message, errors } = response.data;

        if (errors) {
          const errorValues = Object.values(errors);

          if (Array.isArray(errorValues)) {
            errorValues.forEach((item) => {
              item.forEach((value) => {
                if (Array.isArray(value)) {
                  value.forEach((error) => {
                    dispatch(addNotification({ message: error, type: 'error' }));
                  });
                } else {
                  dispatch(addNotification({ message: value, type: 'error' }));
                }
              });
            });
          } else {
            Object.values(errors).forEach((value) => {
              dispatch(addNotification({ message: value[0], type: 'error' }));
            });
          }
        } else {
          dispatch(addNotification({ message, type: 'error' }));
        }
      } else if (action.payload.name !== 'CanceledError') {
        if (Array.isArray(action.payload?.data)) {
          action.payload.data.forEach(({ message }) => {
            dispatch(addNotification({ message, type: 'error' }));
          });
        } else {
          dispatch(addNotification({ message: action.payload.message, type: 'error' }));
        }
      }
    }
  },
});

export default middleware;
