import PropTypes from 'prop-types';
import Link from 'next/link';
import Avatar from '../Avatar/Avatar';
import UserHoverCard from '../UserHoverCard/UserHoverCard';
import Badge from '../Badge/Badge';
import Vacation from '../Badge/Vacation';
import { STATUS_INACTIVE, USER_STATUSES } from '../../constants/status';
import styles from './sass/UserLink.module.scss';

const UserLink = ({
  user,
  isShowAvatar = true,
  className = '',
  width = 24,
  height = 24,
  onClick = () => {}
}) => {
  return (
    <div className={`${styles.wrapper} ${className}`}>
      {isShowAvatar && (
        <div className={styles.avatarWrapper} style={{ width }}>
          <UserHoverCard user={user}>
            <Badge statuses={user?.on_vacation ? (<Vacation vacation="vacation" />) : undefined}>
              <Avatar
                width={width}
                height={height}
                image={user.photo48_link || '/images/avatar.svg'}
                alt={user.name}
              />
            </Badge>
          </UserHoverCard>
        </div>
      )}
      <p>
        <Link
          href={`/employees/${user.id}`}
          className={user.status === STATUS_INACTIVE ? styles.trashed : ''}
          onClick={onClick}
        >
          {user.name}
        </Link>
      </p>
    </div>
  );
};

UserLink.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    photo48_link: PropTypes.string,
    status: PropTypes.oneOf(USER_STATUSES),
    on_vacation: PropTypes.bool,
  }).isRequired,
  isShowAvatar: PropTypes.bool,
  width: PropTypes.number,
  height: PropTypes.number,
  className: PropTypes.string,
  onClick: PropTypes.func,
};

export default UserLink;
