import { useTranslation } from 'next-i18next';
import { batch, shallowEqual } from 'react-redux';
import Button from '../../../components/Button/Button';
import CheckSquareIcon from '../../../components/Icons/CheckSquareIcon';
import { useDispatch, useSelector } from '../../../hooks/reduxToolkit';
import {
  markAsReadNotificationAsync, setChips, setNotificationIds, setQueryFilters
} from '../../../toolkitStore/notificationsModal/slice';
import { NOTIFICATIONS_CHIPS } from '../../../constants/notifications';
import styles from '../sass/NotificationsList.module.scss';

const NotificationsBottom = () => {
  const { t } = useTranslation('common');
  const dispatch = useDispatch();

  const { notificationsData } = useSelector(({ notificationsModal }) => ({
    notificationsData: notificationsModal.notificationsData,
  }), shallowEqual);

  const handleMarkAsRead = () => {
    batch(() => {
      dispatch(setChips(NOTIFICATIONS_CHIPS.ALL));
      dispatch(setNotificationIds(notificationsData.map(({ id }) => id)));
      dispatch(setQueryFilters({ only_unread: true, group: '' }));
      dispatch(markAsReadNotificationAsync());
    });
  };

  return (
    <div className={styles.bottom}>
      <CheckSquareIcon fill="#99AACB" />
      <Button
        ariaLabel="markAsRead"
        color="default"
        onClick={handleMarkAsRead}
      >
        {t('markAllAsRead')}
      </Button>
    </div>
  );
};

export default NotificationsBottom;
