import Link from 'next/link';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { useRouter } from 'next/router';
import { shallowEqual } from 'react-redux';
import { signOut } from 'next-auth/react';
import { useTranslation } from 'next-i18next';
import HeaderAvatar from './HeaderAvatar';
import { logout } from '../../../toolkitStore/user/slice';
import { useDispatch, useSelector } from '../../../hooks/reduxToolkit';

import styles from '../sass/Header.module.scss';

const HeaderProfileMenu = () => {
  const { t } = useTranslation('common');
  const router = useRouter();

  const dispatch = useDispatch();

  const profile = useSelector(({ user }) => user.profile, shallowEqual);

  const handleLogout = async () => {
    const data = await signOut({ redirect: false, callbackUrl: '/auth/sign-in' });
    await router.push({ pathname: data.url });
    dispatch(logout());
  };

  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger asChild>
        <div className={styles.profileBtn}>
          <div className={styles.profileBtnName}>
            <span>{profile.name}</span>
          </div>
          <HeaderAvatar profile={profile} />
        </div>
      </DropdownMenu.Trigger>
      <DropdownMenu.Portal>
        <DropdownMenu.Content className="DropdownMenuContent" align="end" sideOffset={5}>
          <DropdownMenu.Item className="DropdownMenuItem" asChild>
            <Link href={`/employees/${profile.id}`}>{t('profile')}</Link>
          </DropdownMenu.Item>
          <DropdownMenu.Separator />
          <DropdownMenu.Item className="DropdownMenuItem" onSelect={handleLogout}>
            {t('logOut')}
          </DropdownMenu.Item>
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  );
};

export default HeaderProfileMenu;
