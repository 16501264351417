export const PROJECT_REPORT_SET_MODAL_REPORT = 'PROJECT_REPORT_SET_MODAL_REPORT';
export const PROJECT_REPORT_SET_DATEPICKER_DATA = 'PROJECT_REPORT_SET_DATEPICKER_DATA';
export const PROJECT_REPORT_SET_QUERY_PARAMS_FILTERS = 'PROJECT_REPORT_SET_QUERY_PARAMS_FILTERS';
export const PROJECT_REPORT_SET_WORKLOGS = 'PROJECT_REPORT_SET_WORKLOGS';
export const PROJECT_REPORT_SET_WORKLOGS_ID = 'PROJECT_REPORT_SET_WORKLOGS_ID';
export const PROJECT_REPORT_SET_PROJECT_SUMMARY = 'PROJECT_REPORT_SET_PROJECT_SUMMARY';
export const PROJECT_REPORT_SET_FILTER_FIELDS = 'PROJECT_REPORT_SET_FILTER_FIELDS';
export const PROJECT_REPORT_SET_ABORT = 'PROJECT_REPORT_SET_ABORT';
export const PROJECT_REPORT_SET_LOADER = 'PROJECT_REPORT_SET_LOADER';
export const PROJECT_REPORT_RESET_DATA = 'PROJECT_REPORT_RESET_DATA';
export const PROJECT_REPORT_SET_BILLING_REPORTS = 'PROJECT_REPORT_SET_BILLING_REPORTS';
export const PROJECT_REPORT_SET_SORT = 'PROJECT_REPORT_SET_SORT';
