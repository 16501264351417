import Router from 'next/router';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../services/axios';
import { validateClientProduct, validateErrors, newValidateErrors } from '../../utils/validators';
import { LIST_SIZE_PER_PAGE } from '../../constants/values';
import { sendFiles } from '../../utils/sendFiles';

export const initialState = {
  isLoading: true,
  isSubmit: false,
  error: undefined,
  data: [],
  meta: {},
  query: {
    filters: {
      id: [],
      name: '',
      description: '',
      client_id: []
    },
    sort: '',
    page: null,
    per_page: LIST_SIZE_PER_PAGE,
  },
  modalFields: {
    name: '',
    dealLink: '',
    dealBudget: null,
    dealCurrency: '',
    description: '',
    ndaStatus: 'unknown',
    ndaComment: '',
    ndaFile: null,
  },
  productModal: {
    modalId: 'productModal',
    isModalOpen: false,
    productId: null,
  },
  deleteModal: {
    modalId: 'deleteModal',
    isModalOpen: false,
    product: null,
  },
  fieldsErrors: {},
};

export const fetchProductsAsync = createAsyncThunk(
  'clientProducts/fetchProductsAsync',
  async (arg, { getState, rejectWithValue }) => {
    try {
      const { query } = getState().clientProducts;
      const { data, status } = await axios.get('/client_products', {
        params: {
          ...query,
          filters: {
            ...query.filters,
            client_id: [Router.query.id]
          }
        }
      });

      return status === 200 ? data : rejectWithValue(data.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const addOrEditProductAsync = createAsyncThunk(
  'clientProducts/addOrEditProduct',
  async (arg, { getState, rejectWithValue, dispatch }) => {
    try {
      const { modalFields, productModal, query } = getState().clientProducts;
      const errors = validateErrors(
        modalFields,
        validateClientProduct,
        {
          isDealBudget: modalFields.dealBudget,
        }
      );

      if (errors) {
        return rejectWithValue({ isFormValidation: true, errors });
      }

      let method = 'POST';
      let url = '/client_products';

      if (productModal.productId) {
        method = 'PUT';
        url += `/${productModal.productId}`;
      }

      const isProjectsPage = Router.pathname.includes('projects');

      const body = {
        name: modalFields.name,
        deal_link: modalFields.dealLink,
        deal_budget: modalFields.dealBudget,
        deal_currency: modalFields.dealCurrency,
        description: modalFields.description,
        nda_status: modalFields.ndaStatus || 'unknown',
        nda_comment: modalFields.ndaComment,
        client_id: isProjectsPage ? query.filters.client_id : Router.query.id,
      };

      if (modalFields.ndaFile?.file) {
        const mediaIds = await sendFiles([modalFields.ndaFile.file]);

        if (mediaIds.error) {
          return rejectWithValue(mediaIds);
        }

        mediaIds.data = mediaIds.data.filter((item) => item !== undefined);
        body.nda_file_id = mediaIds.data[0] || null;
      } else if (!modalFields.ndaFile) {
        body.nda_file_id = null;
      }

      const { data, status } = await axios({ method, url, data: body });

      if (status === 201 || status === 200) {
        if (!isProjectsPage) {
          dispatch(fetchProductsAsync());
        }

        return data;
      }

      return rejectWithValue(data.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteProductAsync = createAsyncThunk(
  'clientProducts/deleteProduct',
  async ({ productId }, { dispatch, rejectWithValue }) => {
    try {
      const { data, status } = await axios.delete(`/client_products/${productId}`);

      if (status === 204) {
        dispatch(fetchProductsAsync());
        return data;
      }

      return rejectWithValue(data.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const clientProductsSlice = createSlice({
  name: 'clientProducts',
  initialState,
  reducers: {
    resetState: () => initialState,
    setQueryFilter: (state, action) => {
      state.query.filters = { ...state.query.filters, ...action.payload };
    },
    setQuery: (state, action) => {
      state.query = { ...state.query, ...action.payload };
    },
    setData: (state, action) => {
      return ({ ...state, ...action.payload });
    },
    setModalData: (state, action) => {
      state[action.payload.key] = { ...state[action.payload.key], ...action.payload.data };
    },
    setModalFields: (state, action) => {
      state.modalFields = { ...state.modalFields, ...action.payload };
      state.fieldsErrors = {
        ...state.fieldsErrors,
        ...newValidateErrors({ ...action.payload }, validateClientProduct),
      };
    },
    resetModalFields: (state) => {
      state.modalFields = initialState.modalFields;
      state.fieldsErrors = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchProductsAsync.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchProductsAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload.data;
        state.meta = action.payload.meta;
      })
      .addCase(fetchProductsAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(addOrEditProductAsync.pending, (state) => {
        state.isSubmit = true;
        state.error = undefined;
        state.fieldsErrors = {};
      })
      .addCase(addOrEditProductAsync.fulfilled, (state) => {
        state.isSubmit = false;
        state.productModal = initialState.productModal;
        state.modalFields = initialState.modalFields;
        state.fieldsErrors = {};
      })
      .addCase(addOrEditProductAsync.rejected, (state, action) => {
        if (action.payload?.isFormValidation) {
          state.fieldsErrors = action.payload.errors;
        } else {
          state.error = action.payload;
        }
        state.isSubmit = false;
      })
      .addCase(deleteProductAsync.pending, (state) => {
        state.isSubmit = true;
      })
      .addCase(deleteProductAsync.fulfilled, (state) => {
        state.isSubmit = false;
        state.deleteModal = initialState.deleteModal;
      })
      .addCase(deleteProductAsync.rejected, (state, action) => {
        state.isSubmit = false;
        state.error = action.payload;
      });
  },
});

export const {
  setQueryFilter,
  setQuery,
  setModalData,
  setModalFields,
  setData,
  resetModalFields,
  resetState,
} = clientProductsSlice.actions;

export default clientProductsSlice.reducer;
