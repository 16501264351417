import {
  SALARY_BONUS_FETCH_DATA, SALARY_BONUS_SET_PAGE, SALARY_BONUS_SET_ABORT, SALARY_BONUS_CATEGORIES_FETCH_DATA,
  SALARY_BONUS_SET_QUERY_PARAMS, SALARY_BONUS_SET_MODAL_DATA, SALARY_BONUS_SET_MODAL_FIELDS,
  SALARY_BONUS_RESET_MODAL, SALARY_BONUS_RESET_STATE, SALARY_BONUS_LIST_SET_SUBMIT, SALARY_BONUS_SET_PAGE_FILTER_DATA,
  SALARY_BONUS_SET_TABLE_COLUMNS, SALARY_BONUS_SET_LOG_ERROR, SALARY_BONUS_SET_SORT, SALARY_BONUS_SET_TABLE_HEAD_DATA
} from './types';
import { LIST_SIZE_PER_PAGE } from '../../constants/values';
import { SORT_BY_CREATED_AT } from '../../constants/common';

export const initialState = {
  data: [],
  links: {},
  meta: {},
  categoriesData: [],
  isSubmit: false,
  isImportSubmit: false,
  isDeleteSubmit: false,
  employees: [],
  modalErrors: {},
  tableColumns: {
    employee: true,
    month: true,
    amount: true,
    client: true,
    project: true,
    department: true,
    gpnlMonth: true,
    gpnlUser: true,
    category: true,
    createdAt: false,
    createdBy: false,
    comment: false,
    action: true,
  },
  queryParams: {
    filters: {
      id: [],
      user_name: '',
      user_id: [],
      category_id: [],
      active_users: true,
      category: '',
      client: { search: '' },
      project: { search: '' },
      department: { search: '' },
      gpnl_user: { search: '' },
      comment: '',
      range: [],
      month: null,
      start_month: null,
      end_month: null,
      gpnl_month: null,
      show_inactive: false
    },
    with: ['category', 'creator', 'client', 'project', 'department', 'gpnl_user'],
    sort: `-${SORT_BY_CREATED_AT}`,
    page: null,
    per_page: LIST_SIZE_PER_PAGE
  },
  pageFilters: {
    employeeName: '',
    isEmployeeOpen: false,
    selectedEmployeeName: [],
    isColumnsOpen: false,
    fromMonth: null,
    toMonth: null,
  },
  tableHeadData: {
    isCategoryOpen: false,
    commentInputValue: '',
    clientInputValue: '',
    projectInputValue: '',
    departmentInputValue: '',
    userInputValue: '',
  },
  salaryBonusImportModal: {
    modalId: 'salaryBonusImportModal',
    isModalOpen: false,
    data: {
      bonuses: [],
      month: 0,
      month_work_hours: 202301,
    },
    isImportLoading: false
  },
  importResultModal: {
    modalId: 'importResultModal',
    isModalOpen: false,
    countSuccess: null
  },
  createBonusModal: {
    modalId: 'createBonusModal',
    isModalOpen: false,
    isEmployeeOpen: false,
    isClientOpen: false,
    isProjectOpen: false,
    isDepartmentOpen: false,
    searchEmployeeValue: '',
    isCategoryOpen: false,
    isUserOpen: false,
    searchCategoryValue: '',
    searchDepartmentValue: '',
    searchProjectValue: '',
    searchClientValue: '',
    searchBonusValue: '',
    searchUserValue: '',
  },
  warningModal: {
    modalId: 'warningModal',
    isModalOpen: false,
    bonusId: ''
  },
  salaryBonusFields: {
    id: '',
    user_id: '',
    employee: '',
    month: null,
    amount: '',
    comment: '',
    category_id: '',
    client: null,
    project: null,
    department: null,
    gpnlMonth: null,
    user: null,
    category: '',
    creator_id: '',
    created_at: '',
    updated_at: '',
  },
  abortProps: {
    abortSalaryBonus: {},
    abortBonusCategories: {},
  },
};

export default function salaryBonus(state = initialState, action) {
  switch (action.type) {
    case SALARY_BONUS_RESET_STATE:
      return initialState;
    case SALARY_BONUS_SET_TABLE_COLUMNS:
      return {
        ...state,
        tableColumns: {
          ...state.tableColumns,
          ...action.columns
        }
      };
    case SALARY_BONUS_SET_PAGE_FILTER_DATA:
      return {
        ...state,
        pageFilters: {
          ...state.pageFilters,
          ...action.data
        }
      };
    case SALARY_BONUS_SET_TABLE_HEAD_DATA:
      return {
        ...state,
        tableHeadData: {
          ...state.tableHeadData,
          ...action.data
        },
      };
    case SALARY_BONUS_SET_SORT:
      return {
        ...state,
        queryParams: {
          ...state.queryParams,
          sort: action.sort,
        }
      };
    case SALARY_BONUS_LIST_SET_SUBMIT:
      return {
        ...state,
        [action.key]: action.value,
      };
    case SALARY_BONUS_SET_MODAL_DATA:
      return {
        ...state,
        [action.key]: {
          ...state[action.key],
          ...action.data,
        }
      };
    case SALARY_BONUS_SET_MODAL_FIELDS:
      return {
        ...state,
        salaryBonusFields: {
          ...state.salaryBonusFields,
          ...action.data
        },
      };
    case SALARY_BONUS_SET_LOG_ERROR:
      return {
        ...state,
        modalErrors: action.modalErrors,
      };
    case SALARY_BONUS_RESET_MODAL:
      return {
        ...state,
        salaryBonusFields: initialState.salaryBonusFields,
        modalErrors: initialState.modalErrors,
        createBonusModal: initialState.createBonusModal,
      };
    case SALARY_BONUS_CATEGORIES_FETCH_DATA:
      return {
        ...state,
        categoriesData: action.categoriesData,
      };
    case SALARY_BONUS_FETCH_DATA:
      return {
        ...state,
        ...action.payload,
      };
    case SALARY_BONUS_SET_PAGE:
      return {
        ...state,
        queryParams: {
          ...state.queryParams,
          page: action.page
        },
      };
    case SALARY_BONUS_SET_ABORT:
      return {
        ...state,
        abortProps: {
          ...state.abortProps,
          ...action.abortProps
        }
      };
    case SALARY_BONUS_SET_QUERY_PARAMS:
      return {
        ...state,
        queryParams: {
          ...state.queryParams,
          filters: {
            ...state.queryParams.filters,
            ...action.filters
          }
        }
      };
    default:
      return state;
  }
}
