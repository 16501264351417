export const VACATION_INFO_TABLE_HEAD_ITEMS = ['employee', 'date', 'created', 'changedAt', 'daysLeft'];
export const VACATION_HISTORY_TABLE_HEAD_ITEMS = [
  'status', 'date', 'created', 'changedAt', 'managers', 'comment', 'days', 'action'
];

export const VACATION_REQUESTS_HEAD_ITEMS = ['employees', 'date', 'manager', 'days', 'managersToApprove', 'action'];
export const VACATION_FILTER_OPTIONS = {
  DEPARTMENT: 'department',
  PROJECT: 'project',
  EMPLOYEES: 'employees',
  OTHER_OPTIONS: 'otherOptions'
};

export const VACATION_FILTER_OPTIONS_KEYS_ID = {
  DEPARTMENT: 'departmentIds',
  PROJECT: 'projectIds',
  USER: 'userIds',
  OTHER_OPTIONS: 'otherOptions'
};

export const VACATION_FILTER_OTHER_OPTIONS = {
  MY_FOLLOWING: 'myFollowing',
  APPROVED_VACATIONS_BY_ME: 'approvedVacationsByMe',
  MY_MENTORS: 'myMentors',
  // ONLY_TODAY: 'onlyToday' TODO: implement this option
};

export const VACATION_CALENDAR_VIEW = {
  calendar: 'calendar',
  table: 'table',
};
