import PropTypes from 'prop-types';

const DangerouslySetInnerHTML = ({ html, className = '' }) => {
  return (
    <span className={className} dangerouslySetInnerHTML={{ __html: html }} />
  );
};

DangerouslySetInnerHTML.propTypes = {
  html: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default DangerouslySetInnerHTML;
