import { numberFormatter, parseMonthIntToObj } from './helpers';

export const fn = () => null;

export const parseSalaryRateAndFopInfoData = (data) => {
  return data.map((item) => {
    const { month: monthIndex, year } = parseMonthIntToObj(item.month);

    return {
      ...item,
      monthIndex,
      year,
      bank_rate: numberFormatter.format(item.bank_rate),
      card_rate: numberFormatter.format(item.card_rate),
      fop_rate: numberFormatter.format(item.fop_rate),
      fop_esv_uah: numberFormatter.format(item.fop_esv_uah),
      fop_rent_uah: numberFormatter.format(item.fop_rent_uah),
    };
  });
};
