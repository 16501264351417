import {
  INVOICES_EXPORT_FETCH_WORKLOGS, INVOICES_EXPORT_SET_MODAL_DATA, INVOICES_EXPORT_FETCH_INVOICE,
  INVOICES_EXPORT_SET_LOADED, INVOICES_EXPORT_RESET_MODAL_DATA, INVOICES_EXPORT_SET_FILTERS_QUERY_WORKLOGS
} from './types';
import { MAX_FETCH_DATA_PER_PAGE } from '../../constants/values';

export const initialState = {
  invoice: {},
  worklogs: [],
  totalHrs: 0,
  isLoaded: false,
  exportModal: {
    isModalOpen: false,
    modalId: 'exportModal'
  },
  queryInvoice: {
    filters: {
      invoice: {
        id: []
      }
    },
    page: 1,
    per_page: MAX_FETCH_DATA_PER_PAGE,
    with: ['user', 'milestone_item']
  }
};

export default function invoicesExport(state = initialState, action) {
  switch (action.type) {
    case INVOICES_EXPORT_SET_LOADED:
      return {
        ...state,
        isLoaded: action.isLoaded
      };
    case INVOICES_EXPORT_SET_MODAL_DATA:
      return {
        ...state,
        exportModal: {
          ...state.exportModal,
          ...action.data
        }
      };
    case INVOICES_EXPORT_RESET_MODAL_DATA:
      return {
        ...state,
        exportModal: initialState.exportModal
      };
    case INVOICES_EXPORT_FETCH_WORKLOGS:
      return {
        ...state,
        worklogs: action.worklogs,
        totalHrs: action.totalHrs,
        isLoaded: true,
      };
    case INVOICES_EXPORT_FETCH_INVOICE:
      return {
        ...state,
        invoice: action.invoice
      };
    case INVOICES_EXPORT_SET_FILTERS_QUERY_WORKLOGS:
      return {
        ...state,
        queryInvoice: {
          ...state.queryInvoice,
          ...action.filters,
          filters: {
            ...state.queryInvoice.filters,
            [action.key]: action.params
          }
        }
      };
    default:
      return state;
  }
}
