import {
  TIMETRACKING_SET_DATA,
  TIMETRACKING_SET_QUERY_PARAMS,
  TIMETRACKING_SET_DATEPICKER_DATA,
  TIMETRACKING_SET_PAGE_FILTERS_DATA,
  TIMETRACKING_SET_REPORT_DETAILS,
  TIMETRACKING_SET_DATES_INTERVAL,
  TIMETRACKING_RESET_PAGE,
  TIMETRACKING_SET_NEED_REPORTED_TIME,
  TIMETRACKING_SET_MAX_TRACKING_TIME,
  TIMETRACKING_SET_MAX_TRACKING_TEAM_TIME,
  TIMETRACKING_SET_ABORT,
  TIMETRACKING_SET_PAGE_INITIAL_FILTERS
} from './types';
import { changeDateFormat, getEndOfMonth, getStartOfMonth } from '../../utils/helpers';
import { getDays } from '../../utils/timesheet';

const date = new Date();
const startDate = getStartOfMonth(date);
const endDate = getEndOfMonth(date);
const queryParams = {
  filters: {
    start_date: changeDateFormat(startDate, true),
    end_date: changeDateFormat(endDate, true),
    user_id: [],
    department_id: []
  }
};

export const initialState = {
  isLoading: true,
  isPending: false,
  queryParams,
  days: getDays(startDate, endDate, []),
  datesInterval: [],
  maxTrackTime: 0,
  maxTrackTimeTeam: 0,
  needReportedTime: [],
  employeeFilter: {
    projectName: '',
    employeeName: '',
    departmentName: '',
    isProjectOpen: false,
    isEmployeeOpen: false,
    isDepartmentOpen: false,
    selectDepartmentId: [],
    selectedProjectName: [],
    selectedEmployeeName: [],
    selectedDepartmentName: [],
    showOnlyAssigned: false,
  },
  datepicker: {
    from: startDate,
    to: endDate,
  },
  res: {
    report: [],
    report_manager: [],
    users: []
  },
  abortProps: {
    abortTimetracking: {},
  }
};

export default function timeTracking(state = initialState, action) {
  switch (action.type) {
    case TIMETRACKING_RESET_PAGE:
      return initialState;
    case TIMETRACKING_SET_PAGE_INITIAL_FILTERS:
      return {
        ...state,
        employeeFilter: initialState.employeeFilter,
      };
    case TIMETRACKING_SET_REPORT_DETAILS:
      return {
        ...state,
        res: action.report,
        isLoading: false,
      };
    case TIMETRACKING_SET_QUERY_PARAMS:
      return {
        ...state,
        queryParams: {
          ...state.queryParams,
          filters: {
            ...state.queryParams.filters,
            ...action.filters,
          }
        },
      };
    case TIMETRACKING_SET_PAGE_FILTERS_DATA:
      return {
        ...state,
        employeeFilter: {
          ...state.employeeFilter,
          ...action.data
        }
      };
    case TIMETRACKING_SET_DATEPICKER_DATA:
      return {
        ...state,
        datepicker: action.datepicker,
      };
    case TIMETRACKING_SET_DATES_INTERVAL:
      return {
        ...state,
        datesInterval: action.data
      };
    case TIMETRACKING_SET_NEED_REPORTED_TIME:
      return {
        ...state,
        needReportedTime: action.needReportedTime
      };
    case TIMETRACKING_SET_MAX_TRACKING_TIME:
      return {
        ...state,
        maxTrackTime: action.maxTrackTime
      };
    case TIMETRACKING_SET_MAX_TRACKING_TEAM_TIME:
      return {
        ...state,
        maxTrackTimeTeam: action.maxTrackTimeTeam
      };
    case TIMETRACKING_SET_ABORT:
      return {
        ...state,
        abortProps: {
          ...state.abortProps,
          [action.name]: action.controller,
        },
      };
    case TIMETRACKING_SET_DATA:
      return {
        ...state,
        ...action.data,
      };
    default:
      return state;
  }
}
