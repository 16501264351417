import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import subMonths from 'date-fns/subMonths';
import axios from '../../services/axios';
import {
  getSchemaParams, isDouble, parseToMonthInt
} from '../../utils/helpers';
import { validateErrors, validateTeachleadingForm } from '../../utils/validators';
import httpRequestMethods from '../../constants/httpRequestMethods';
import * as httpStatusCodes from '../../constants/httpStatusCodes';
import { SORT_BY_MONTH } from '../../constants/common';
import { LIST_SIZE_PER_PAGE_25 } from '../../constants/values';
import { STATUS_APPROVED, TEACHLEADING_STATUSES } from '../../constants/status';

const date = new Date();
const prevMonth = subMonths(date, 1);
const month = parseToMonthInt(prevMonth.getFullYear(), prevMonth.getMonth() + 1);

export const initialState = {
  isLoading: false,
  isDeleteSubmit: false,
  error: undefined,
  data: [],
  approvedData: {},
  meta: {},
  query: {
    filters: {
      lead: {
        id: [],
        search: ''
      },
      user: {
        id: [],
        search: ''
      },
      project: {
        id: [],
        search: ''
      },
      department: {
        id: [],
        search: ''
      },
      month,
      show_inactive: false,
      show_only_with_positive_gross_margin: false,
      status: TEACHLEADING_STATUSES
    },
    with: ['project', 'user', 'lead', 'department'],
    sort: SORT_BY_MONTH,
    per_page: LIST_SIZE_PER_PAGE_25
  },
  statuses: TEACHLEADING_STATUSES,
  arrayOfApproved: [],
  tableFilters: {
    leadName: '',
    userName: '',
    projectName: '',
    departmentName: '',
    isDepartmentOpen: false
  },
  formFields: {
    department: null,
    departmentList: [],
    projectsData: [],
    employee: null,
    lead: null,
    month,
    approvedAmount: '',
  },
  techleadingModal: {
    modalId: 'techleadingModal',
    isModalOpen: false,
  },
  techleadingDeleteDialog: {
    modalId: 'techleadingDeleteDialog',
    isModalOpen: false,
    id: ''
  },
  formErrors: {},
};

export const fetchTechleading = createAsyncThunk(
  'techleading/fetchTechleading',
  async (arg, { rejectWithValue, getState }) => {
    try {
      const { techleading: { query } } = getState();
      const { data, status } = await axios.get('/user_teachleads', { params: query });

      const newData = data.data.map((item) => {
        return {
          ...item,
          rate: getSchemaParams(item),
          updateApprovedAmount: item.status === STATUS_APPROVED ? item.approved_amount : item.amount
        };
      });

      const resData = { ...data, data: newData };

      return status === 200 ? resData : rejectWithValue(data.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const createTechleading = createAsyncThunk(
  'techleading/createTechleading',
  async (arg, { dispatch, rejectWithValue, getState }) => {
    try {
      const { formFields } = getState().techleading;
      const errors = validateErrors(
        formFields,
        validateTeachleadingForm,
        { isCreate: true }
      );

      if (errors) {
        return rejectWithValue({ isFormValidation: true, errors });
      }

      const body = {
        month: formFields.month,
        user_id: formFields.employee.id,
        project_id: formFields.projectsData.map(({ id }) => id),
        lead_id: formFields.lead.id,
        department_id: formFields.department.id,
      };

      const { data, status } = await axios({
        method: httpRequestMethods.POST,
        url: '/user_teachleads',
        data: body
      });

      if (status === httpStatusCodes.OK || status === httpStatusCodes.CREATED) {
        dispatch(fetchTechleading());

        return data;
      }

      return rejectWithValue(data.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const approveTechleading = createAsyncThunk(
  'techleading/approveTechleading',
  async (teachLead, { rejectWithValue }) => {
    try {
      const body = {
        approved_data: {
          amount: teachLead.amount || '0',
          approved_amount: teachLead.approved_amount || '0',
          lead_approved_hours: teachLead.lead_approved_hours || '0',
          user_billed_hours: teachLead.user_billed_hours || '0',
          user_gross_margin: teachLead.user_gross_margin || '0',
          work_hours: teachLead.work_hours || '0'
        },
        approved_amount: +teachLead.updateApprovedAmount
      };

      const { data, status } = await axios({
        method: httpRequestMethods.POST,
        url: `/user_teachleads/${teachLead.id}/approve`,
        data: body
      });

      if (status === httpStatusCodes.OK || status === httpStatusCodes.CREATED) {
        return data;
      }

      return rejectWithValue(data.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteTechleading = createAsyncThunk(
  'techleading/deleteTechleading',
  async (arg, { dispatch, rejectWithValue, getState }) => {
    const { techleadingDeleteDialog: { id } } = getState().techleading;

    try {
      const { status, data } = await axios.delete(`/user_teachleads/${id}`);

      if (status === httpStatusCodes.NO_CONTENT) {
        dispatch(fetchTechleading());
        return data;
      }

      return rejectWithValue(data.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const techleadingSlice = createSlice({
  name: 'techleading',
  initialState,
  reducers: {
    resetState: () => initialState,
    setQuery: (state, action) => {
      state.query = { ...state.query, ...action.payload };
    },
    setQueryFilter: (state, action) => {
      state.query.filters = { ...state.query.filters, ...action.payload };
    },
    setStatus: (state, action) => {
      state.statuses = [...action.payload];
    },
    setTableFilters: (state, action) => {
      state.tableFilters = { ...state.tableFilters, ...action.payload };
    },
    setModalData: (state, action) => {
      state[action.payload.key] = { ...state[action.payload.key], ...action.payload };
    },
    setValidateFormField: (state, action) => {
      const fieldError = validateTeachleadingForm(action.payload.fieldName, action.payload.fieldData);

      state.formErrors = {
        ...state.formErrors,
        [action.payload.fieldName]: fieldError,
      };
      state.formFields = {
        ...state.formFields,
        [action.payload.fieldName]: action.payload.fieldData,
      };
    },
    setSort: (state, action) => {
      state.query.sort = action.payload;
    },
    resetModal: (state) => {
      state.techleadingModal = initialState.techleadingModal;
      state.techleadingDeleteDialog = initialState.techleadingDeleteDialog;
      state.formFields = initialState.formFields;
      state.formErrors = initialState.formErrors;
    },
    clearFormFields: (state) => {
      state.formFields = initialState.formFields;
      state.formErrors = initialState.formErrors;
    },
    setEditById: (state, action) => {
      const findIndex = state.data.findIndex((item) => item.id === action.payload.id);

      if (findIndex >= 0) {
        state.data[findIndex].isEdit = action.payload.isEdit;
      }
    },
    setApprovedById: (state, action) => {
      const findIndex = state.data.findIndex((item) => item.id === action.payload.id);

      if (findIndex >= 0) {
        state.data[findIndex].updateApprovedAmount = action.payload.updateApprovedAmount;
        state.data[findIndex].isError = !action.payload?.updateApprovedAmount
          || (action.payload?.updateApprovedAmount && !isDouble(action.payload.updateApprovedAmount));
      }
    },
    setTableHeadData: (state, action) => {
      state.tableHeadData = action.payload.tableHeadData;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTechleading.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchTechleading.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload.data;
        state.meta = action.payload.meta;
        state.techleadingModal = initialState.techleadingModal;
      })
      .addCase(fetchTechleading.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
    builder
      .addCase(createTechleading.pending, (state) => {
        state.isSubmit = true;
      })
      .addCase(createTechleading.fulfilled, (state) => {
        state.isSubmit = false;
        state.formFields = initialState.formFields;
        state.formErrors = initialState.formErrors;
      })
      .addCase(createTechleading.rejected, (state, action) => {
        if (action.payload.isFormValidation) {
          state.formErrors = action.payload.errors;
        }
        state.isSubmit = false;
      });
    builder
      .addCase(approveTechleading.pending, (state, action) => {
        state.isSubmit = true;
        state.arrayOfApproved = [...state.arrayOfApproved, action.meta.arg.id];
      })
      .addCase(approveTechleading.fulfilled, (state, action) => {
        const findIndex = state.data.findIndex(({ id }) => id === action.payload.id);

        if (findIndex >= 0) {
          state.data[findIndex] = {
            ...action.payload,
            updateApprovedAmount: action.payload.approved_amount,
            rate: getSchemaParams(action.payload),
            isEdit: false,
          };
        }

        state.arrayOfApproved = state.arrayOfApproved.filter((id) => id !== action.meta.arg.id);
        state.teachleadingApproveModal = initialState.teachleadingApproveModal;
        state.formFields = initialState.formFields;
        state.formErrors = initialState.formErrors;
        state.isSubmit = false;
      })
      .addCase(approveTechleading.rejected, (state, action) => {
        if (action.payload.isFormValidation) {
          state.formErrors = action.payload.errors;
        }
        state.arrayOfApproved = state.arrayOfApproved.filter((id) => id !== action.meta.arg.id);
        state.isSubmit = false;
      });
    builder
      .addCase(deleteTechleading.pending, (state) => {
        state.isDeleteSubmit = true;
      })
      .addCase(deleteTechleading.fulfilled, (state) => {
        state.isDeleteSubmit = false;
        state.techleadingDeleteDialog = initialState.techleadingDeleteDialog;
      })
      .addCase(deleteTechleading.rejected, (state) => {
        state.isSubmit = false;
      });
  },
});

export const {
  resetState,
  setQuery,
  setQueryFilter,
  setSort,
  setTableFilters,
  setModalData,
  setStatus,
  resetModal,
  setValidateFormField,
  setEditById,
  setApprovedById
} = techleadingSlice.actions;

export default techleadingSlice.reducer;
