import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../services/axios';
import { LIST_SIZE_PER_PAGE } from '../../constants/values';
import { validateAddTicket, validateErrors } from '../../utils/validators';
import { sendFiles } from '../../utils/sendFiles';
import { PRE_SALE_PROJECT } from '../../constants/common';

export const initialState = {
  data: [],
  links: {},
  meta: {},
  activeTicketsTabIsOpened: true,
  isSubmit: false,
  isLoading: false,
  currentTicketId: '',
  currentTicketKey: '',
  projectName: '',
  queryParams: {
    filters: {
      id: '',
      key: '',
      show_inactive: false,
      search: '',
      status: ['open'],
      internal: true,
      not_internal: true,
    },
    with: [],
    sort: '-key',
    page: null,
    per_page: LIST_SIZE_PER_PAGE,
  },
  modalProps: {
    modalId: 'addTicket',
    isModalOpen: false,
  },
  ticketFields: {
    summary: '',
    description: '',
    images: [],
  },
  ticketsInfo: {
    activeTicketsCount: 0,
    closedTicketsCount: 0,
  },
  ticketErrors: {},
};

export const fetchTickets = createAsyncThunk(
  'projectTickets/fetchTickets',
  async (_, { getState, rejectWithValue }) => {
    try {
      const { queryParams, projectName } = getState().projectTickets;

      const getFiltersData = () => {
        const areHydraAndJiraFiltersActive = queryParams.filters.internal && queryParams.filters.not_internal;
        const isPreSaleProject = projectName && projectName !== 'undefined' && projectName === PRE_SALE_PROJECT;

        const filtersData = {
          ...queryParams.filters,
          internal: !areHydraAndJiraFiltersActive && queryParams.filters.internal,
          not_internal: !areHydraAndJiraFiltersActive && queryParams.filters.not_internal,
        };

        if (isPreSaleProject) {
          filtersData.search_content = queryParams.filters.search;
          filtersData.search = null;
        }

        return filtersData;
      };

      const { data } = await axios.get(
        '/project_tickets',
        {
          params: {
            ...queryParams,
            filters: getFiltersData(),
          }
        }
      );
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const addTicket = createAsyncThunk(
  'projectTickets/addTicket',
  async (projectId, { getState, rejectWithValue, dispatch }) => {
    try {
      const {
        projectTickets: { ticketFields },
      } = getState();

      const allFormErrors = validateErrors(ticketFields, validateAddTicket);
      if (allFormErrors) {
        return rejectWithValue({ isFormValidation: true, errors: allFormErrors });
      }

      const { summary, description, images } = ticketFields;
      let mediaData = [];
      if (images.length > 0) {
        mediaData = await sendFiles(images.map(({ file }) => file));

        if (mediaData.error) {
          return rejectWithValue(mediaData);
        }

        mediaData = mediaData.data.filter((item) => item);
      }

      const { data } = await axios.post(
        '/project_tickets',
        {
          project_id: projectId,
          summary,
          description_json: description,
          file_ids: mediaData
        },
      );

      await dispatch(fetchTickets());
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const closeOrReopenTicket = createAsyncThunk(
  'projectTickets/closeOrReopenTicket',
  async (status, { rejectWithValue, getState, dispatch }) => {
    try {
      const { projectTickets: { currentTicketId, } } = getState();

      const { data } = await axios.put(
        `/project_tickets/${currentTicketId}`,
        { status, }
      );
      await dispatch(fetchTickets());
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const projectTicketsSlice = createSlice({
  name: 'projectTickets',
  initialState,
  reducers: {
    resetState: () => initialState,

    setQueryParamsFilters: (state, action) => {
      state.queryParams.filters = { ...state.queryParams.filters, ...action.payload, };
      state.queryParams.page = initialState.queryParams.page;
    },

    setQueryParams: (state, action) => {
      state.queryParams = { ...state.queryParams, ...action.payload };
    },

    setQueryParamsTicketsType: (state, action) => {
      const { key, value } = action.payload;
      state.queryParams.filters[key] = value;
      const { internal, not_internal: notInternal } = state.queryParams.filters;
      if (!internal && !notInternal) state.queryParams.filters[key === 'internal' ? 'not_internal' : 'internal'] = true;
      state.queryParams.page = initialState.queryParams.page;
    },

    toggleModal: (state, action) => {
      state.modalProps.isModalOpen = action.payload;
      state.modalProps.modalId = 'addTicket';

      if (!action.payload) {
        state.ticketErrors = initialState.ticketErrors;
        state.ticketFields = initialState.ticketFields;
      }
    },

    toggleCloseOrReopenTicketModal: (state, action) => {
      state.modalProps.isModalOpen = action.payload.isOpen;
      state.modalProps.modalId = action.payload.isOpen ? action.payload.modalId : initialState.modalProps.modalId;
      state.currentTicketId = action.payload.isOpen ? action.payload.itemId : initialState.currentTicketId;
      state.currentTicketKey = action.payload.isOpen ? action.payload.key : initialState.currentTicketId;
    },

    ticketSetFieldData: (state, action) => {
      state.ticketErrors = initialState.ticketErrors;
      state.ticketFields[action.payload.fieldName] = action.payload.fieldData;
    },

    setPage: (state, action) => {
      state.queryParams.page = action.payload;
    },

    setProject: (state, action) => {
      state.projectName = action.payload.projectName;
    },

    toggleActiveTab: (state, action) => {
      state.activeTicketsTabIsOpened = action.payload;
      state.queryParams.filters.status = action.payload ? ['open'] : ['closed'];
      state.meta = initialState.meta;
      state.queryParams.page = initialState.queryParams.page;
    },

    setTicketsInfo: (state, action) => {
      state.ticketsInfo.activeTicketsCount = action.payload.activeTicketsCount;
      state.ticketsInfo.closedTicketsCount = action.payload.closedTicketsCount;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTickets.pending, (state) => {
        state.data = initialState.data;
        state.isLoading = true;
      })
      .addCase(fetchTickets.fulfilled, (state, action) => {
        const { total } = action.payload.meta;
        const openCount = action.payload.meta.open_count;
        const closedCount = action.payload.meta.closed_count;
        state.data = action.payload.data;
        state.links = action.payload.links;
        state.meta = action.payload.meta;
        state.isLoading = false;
        state.ticketsInfo.activeTicketsCount = state.activeTicketsTabIsOpened && total !== openCount
          ? total
          : openCount;
        state.ticketsInfo.closedTicketsCount = !state.activeTicketsTabIsOpened && total !== closedCount
          ? total
          : closedCount;
      })
      .addCase(fetchTickets.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });

    builder
      .addCase(addTicket.pending, (state) => {
        state.isSubmit = true;
        state.ticketErrors = initialState.ticketErrors;
      })
      .addCase(addTicket.fulfilled, (state) => {
        state.isSubmit = false;
      })
      .addCase(addTicket.rejected, (state, action) => {
        state.isSubmit = false;
        if (action.payload?.isFormValidation) {
          state.ticketErrors = action.payload.errors;
        }
      });

    builder
      .addCase(closeOrReopenTicket.pending, (state) => {
        state.isSubmit = true;
      })
      .addCase(closeOrReopenTicket.fulfilled, (state) => {
        state.isSubmit = false;
        state.modalProps.isModalOpen = false;
        state.currentTicketId = initialState.currentTicketId;
        state.currentTicketKey = initialState.currentTicketKey;
      })
      .addCase(closeOrReopenTicket.rejected, (state) => {
        state.isSubmit = false;
      });
  }
});

export const {
  resetState,
  setQueryParamsFilters,
  setQueryParams,
  setQueryParamsTicketsType,
  toggleModal,
  ticketSetFieldData,
  setPage,
  toggleCloseOrReopenTicketModal,
  toggleActiveTab,
  setTicketsInfo,
  setProject,
} = projectTicketsSlice.actions;

export default projectTicketsSlice.reducer;
