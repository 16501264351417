import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../services/axios';
import { isEmptyObject, isObject, setTableChecked } from '../../utils/helpers';
import { removeValue } from '../../utils/arrayHelpers';
import { getApproveWorklogs, parseReviewData } from '../../utils/review';
import { LIST_SIZE_PER_PAGE } from '../../constants/values';
import { APPROVED_TIME, SORT_BY_DATE } from '../../constants/common';

export const initialState = {
  isLoading: true,
  error: undefined,
  data: [],
  meta: {},
  query: {
    filters: {
      start_date: undefined,
      end_date: undefined,
      employee_without_pm: false,
      status: ['new', 'changed'],
      show_own: true,
      project: { id: [] },
      user: { search: '' },
      ticket: { search: '' },
    },
    sort: `-${SORT_BY_DATE}`,
    page: null,
    per_page: LIST_SIZE_PER_PAGE
  },
  selectedProjects: [],
  statuses: ['new', 'changed'],
  checkedArray: [],
  lastChecked: null,
  approvedArray: [],
  attentionModal: {
    modalId: 'attentionModal',
    isModalOpen: false,
  },
  resolveModal: {
    modalId: 'resolveModal',
    isModalOpen: false,
    resolveWorklog: null,
  },
  datepicker: undefined,
  tableSettings: {
    isShowCheckbox: true,
    isInsideModal: false,
  },
};

export const fetchReviewAsync = createAsyncThunk(
  'review/fetchReview',
  async ({ isApprove = false }, { rejectWithValue, getState, signal }) => {
    const { query, data: prevData } = getState().review;

    try {
      const { data, status } = await axios.post('/review', query, { signal });

      return status === 200 ? {
        ...data,
        data: parseReviewData(data.data, isApprove ? prevData : undefined),
      } : rejectWithValue(data.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const approveSingleWorklogAsync = createAsyncThunk(
  'review/approveSingleWorklog',
  async ({ worklog }, { rejectWithValue }) => {
    try {
      const body = {
        type: worklog.type,
        approved_time: worklog.approved_time,
        billed_time: worklog.billed_time,
      };

      if (worklog.project.type === 'client') {
        body.milestone_item_id = worklog?.milestone_item?.id || null;
      }

      const { data, status } = await axios.put(`/worklogs/${worklog.id}/approve`, body);

      return status === 200 ? data : rejectWithValue(data.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const approveBatchWorklogsAsync = createAsyncThunk(
  'review/approveBatchWorklogs',
  async (arg, { rejectWithValue, getState }) => {
    try {
      const { data: reviewData, checkedArray } = getState().review;
      const worklogs = getApproveWorklogs(reviewData, checkedArray);

      const { data, status } = await axios.post('/worklogs/approve_batch', { worklogs });

      if (status === 200 && isObject(data.errors) && !isEmptyObject(data.errors)) {
        return rejectWithValue({ response: { data } });
      }

      return status === 200 ? data : rejectWithValue(data.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const reviewSlice = createSlice({
  name: 'review',
  initialState,
  reducers: {
    resetState: () => initialState,
    resetQueryFilter: (state) => {
      state.query.filters = initialState.query.filters;
    },
    setQueryFilter: (state, action) => {
      state.query.filters = { ...state.query.filters, ...action.payload };
      state.checkedArray = [];
    },
    setQuery: (state, action) => {
      state.query = { ...state.query, ...action.payload };
      state.checkedArray = [];
    },
    setData: (state, action) => {
      return ({ ...state, ...action.payload });
    },
    checkedAll: (state, action) => {
      state.checkedArray = action.payload.isChecked ? state.data.map(({ id }) => id) : [];
    },
    setEdit: (state, action) => {
      const findIndex = state.data.findIndex(({ id }) => id === action.payload.worklog.id);
      state.data[findIndex].status = 'new';
    },
    updateCheckedArray: (state, { payload }) => {
      const { checkedItems, checkedItem } = setTableChecked({
        data: state.data.map(({ id }) => id),
        isShift: payload.isShift,
        checked: payload.isChecked,
        checkedItem: payload.checkedId,
        lastChecked: state.lastChecked,
        tableChecked: state.checkedArray
      });

      state.checkedArray = checkedItems;
      state.lastChecked = checkedItem;
    },
    changeWorklogType: (state, { payload }) => {
      const findWorklogIndex = state.data.findIndex(({ id }) => id === payload.worklogId);

      state.data[findWorklogIndex].type = payload.type;
    },
    changeWorklogTime: (state, { payload }) => {
      const findWorklogIndex = state.data.findIndex(({ id }) => id === payload.worklogId);
      const worklog = state.data[findWorklogIndex];
      const isBilledTimeError = worklog.errors.includes('billedTimeError');
      const isBilledMoreError = worklog.errors.includes('billedMoreError');

      if (payload.filed === APPROVED_TIME) {
        if (payload.value !== worklog.billed_time && !isBilledTimeError) {
          state.data[findWorklogIndex].errors = [...state.data[findWorklogIndex].errors, 'billedTimeError'];
        } else if (payload.value === worklog.billed_time) {
          state.data[findWorklogIndex].errors = state.data[findWorklogIndex].errors
            .filter((error) => error !== 'billedTimeError');
        }
      } else {
        if (payload.value > worklog.reported_time && !isBilledMoreError) {
          state.data[findWorklogIndex].errors = [...state.data[findWorklogIndex].errors, 'billedMoreError'];
        } else if (payload.value <= worklog.reported_time) {
          state.data[findWorklogIndex].errors = state.data[findWorklogIndex].errors
            .filter((error) => error !== 'billedMoreError');
        }

        if (payload.value !== worklog.approved_time && !isBilledTimeError) {
          state.data[findWorklogIndex].errors = [...state.data[findWorklogIndex].errors, 'billedTimeError'];
        } else if (payload.value === worklog.approved_time) {
          state.data[findWorklogIndex].errors = state.data[findWorklogIndex].errors
            .filter((error) => error !== 'billedTimeError');
        }
      }

      state.data[findWorklogIndex][payload.filed] = payload.value;
    },
    setWorklogItem: (state, { payload }) => {
      const findWorklogIndex = state.data.findIndex(({ id }) => id === payload.worklogId);

      state.data[findWorklogIndex].errors = removeValue(state.data[findWorklogIndex].errors, 'itemIdError');
      state.data[findWorklogIndex].milestone_item = payload.milestone_item;
      state.itemDropdown = { id: null, isOpen: false, style: {} };
    },
    openResolveModal: (state, action) => {
      state.resolveModal.isModalOpen = true;
      state.resolveModal.resolveWorklog = action.payload.worklog;
    },
    closeResolveModal: (state) => {
      state.resolveModal.isModalOpen = false;
      state.resolveModal.resolveWorklog = null;
    },
    openAttentionModal: (state) => {
      state.resolveModal.isModalOpen = false;
      state.attentionModal.isModalOpen = true;
    },
    closeAttentionModal: (state) => {
      state.resolveModal.resolveWorklog = null;
      state.attentionModal.isModalOpen = false;
    },
    setDatepickerData: (state, { payload }) => {
      state.datepicker = payload;
    },
    resetDatepicker: (state) => {
      state.datepicker = initialState.datepicker;
    },
    setTableSettings: (state, action) => {
      state.tableSettings = { ...state.tableSettings, ...action.payload };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchReviewAsync.pending, (state, action) => {
        state.error = undefined;
        state.isLoading = !action.meta.arg.isApprove;
      })
      .addCase(fetchReviewAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload.data;
        state.meta = action.payload.meta;
      })
      .addCase(fetchReviewAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(approveSingleWorklogAsync.pending, (state, { meta }) => {
        const { worklog } = meta.arg;

        state.approvedArray = [...state.approvedArray, worklog.id];
      })
      .addCase(approveSingleWorklogAsync.fulfilled, (state, { meta }) => {
        const { worklog } = meta.arg;

        state.approvedArray = state.approvedArray.filter((approvedId) => approvedId !== worklog.id);
        state.checkedArray = state.checkedArray.filter((checkedId) => checkedId !== worklog.id);
        state.data = state.data.filter(({ id }) => id !== worklog.id);
        state.attentionModal.isModalOpen = false;
        state.resolveModal.isModalOpen = false;
        state.resolveModal.resolveWorklog = null;
      })
      .addCase(approveSingleWorklogAsync.rejected, (state, { meta }) => {
        const { worklog } = meta.arg;

        state.approvedArray = state.approvedArray.filter((approvedId) => approvedId !== worklog.id);
      })
      .addCase(approveBatchWorklogsAsync.pending, (state) => {
        state.approvedArray = getApproveWorklogs(state.data, state.checkedArray).map(({ id }) => id);
      })
      .addCase(approveBatchWorklogsAsync.fulfilled, (state, action) => {
        state.approvedArray = [];
        state.checkedArray = state.checkedArray
          .filter((item) => !action.payload.worklogs.some(({ id }) => id === item));
        state.data = state.data.filter((item) => !action.payload.worklogs.some(({ id }) => id === item.id));
      })
      .addCase(approveBatchWorklogsAsync.rejected, (state) => {
        state.approvedArray = [];
      });
  },
});

export const {
  resetQueryFilter,
  openResolveModal,
  setQueryFilter,
  setQuery,
  setData,
  resetState,
  updateCheckedArray,
  changeWorklogType,
  changeWorklogTime,
  setWorklogItem,
  closeResolveModal,
  openAttentionModal,
  closeAttentionModal,
  setDatepickerData,
  resetDatepicker,
  checkedAll,
  setEdit,
  setTableSettings
} = reviewSlice.actions;

export default reviewSlice.reducer;
