import {
  WS_CONNECT,
  WS_CONNECTED,
  WS_CONNECTING,
  WS_DISCONNECT,
  WS_DISCONNECTED,
} from './types';

export const wsConnect = (profileId) => ({ type: WS_CONNECT, profileId });

export const wsConnecting = () => ({ type: WS_CONNECTING });

export const wsConnected = () => ({ type: WS_CONNECTED });

export const wsDisconnect = () => ({ type: WS_DISCONNECT });

export const wsDisconnected = () => ({ type: WS_DISCONNECTED });
