import { DATEPICKER_SET_HOLIDAYS, DATEPICKER_SET_ABORT } from './types';

const initialState = {
  holidays: [],
  abortProps: {
    abortHolidays: {},
  },
};

export default function datepicker(state = initialState, action) {
  switch (action.type) {
    case DATEPICKER_SET_ABORT:
      return {
        ...state,
        abortProps: {
          ...state.abortProps,
          [action.key]: action.value,
        },
      };
    case DATEPICKER_SET_HOLIDAYS:
      return {
        ...state,
        holidays: action.holidays,
      };
    default:
      return state;
  }
}
