import { CURRENCY_SIGNS } from '../constants/common';
import {
  MILESTONE_FP_HIDE_HOURS_RATES,
  MILESTONE_FP_SHOW_HOURS_RATES,
  MILESTONE_DEDICATED_TEAM_TYPE,
} from '../constants/types';
import {
  MILESTONES_STATUS_AUTO_ACCEPTED,
  MILESTONES_STATUS_CLIENT_ACCEPTED,
  MILESTONES_STATUS_IN_PROGRESS
} from '../constants/status';
import { SECONDS_IN_HOUR, WORKS_HOURS_IN_WEEK } from '../constants/values';
import { parseAttachedFiles } from './sendFiles';
import { toHoursAndMinutes, toSeconds } from './helpers';

export const multiplyDecimals = (num1, num2) => {
  const num1Str = num1.toString();
  const num2Str = num2.toString();

  const num1Decimals = (num1Str.split('.')[1] || '').length;
  const num2Decimals = (num2Str.split('.')[1] || '').length;

  const intNum1 = parseInt(num1Str.replace('.', ''), 10);
  const intNum2 = parseInt(num2Str.replace('.', ''), 10);

  const result = intNum1 * intNum2;

  const decimalPlaces = num1Decimals + num2Decimals;
  return result / 10 ** decimalPlaces;
};

export const autoCalcTotalPlannedApprovedTime = ({
  workhours,
  plannedApprovedTime = 0,
  timeType,
  isSupport = false,
  isHours = true,
}) => {
  let plannedApprovedTimePerType = 0;

  if (isSupport) {
    plannedApprovedTimePerType = workhours;
  } else if (timeType === 'per-milestone') {
    plannedApprovedTimePerType = isHours
      ? plannedApprovedTime / SECONDS_IN_HOUR
      : plannedApprovedTime;
  } else if (timeType === 'per-week') {
    plannedApprovedTimePerType = isHours
      ? multiplyDecimals((workhours / WORKS_HOURS_IN_WEEK), (plannedApprovedTime / SECONDS_IN_HOUR))
      : multiplyDecimals((workhours / WORKS_HOURS_IN_WEEK), plannedApprovedTime);
  }
  return plannedApprovedTimePerType;
};

const autoCalcPlannedBilledTime = ({
  workhours,
  billibility = 100,
  plannedApprovedTime = 0,
  timeType,
  isSupport = false,
  isHours = true,
}) => {
  const plannedApprovedTimePerType = autoCalcTotalPlannedApprovedTime(
    {
      workhours, plannedApprovedTime, timeType, isSupport, isHours
    }
  );
  const plannedBilledTime = multiplyDecimals(plannedApprovedTimePerType, (billibility / 100));
  return isHours
    ? toHoursAndMinutes(multiplyDecimals(plannedBilledTime, SECONDS_IN_HOUR))
    : plannedBilledTime;
};

export const formatCurrencyAmount = (currency, item, field) => {
  const amount = item[field];

  return amount
    ? (
      <p key={[currency, amount, field].join()}>
        {`${CURRENCY_SIGNS[currency]}${amount.toLocaleString('en-US', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        })}`}
      </p>
    )
    : <p>-</p>;
};

export const formatTimeAmount = (currency, item, field) => {
  const time = item[field];

  return time
    ? <p key={[currency, time, field].join()}>{toHoursAndMinutes(toSeconds(time))}</p>
    : <p>-</p>;
};

export const isAcceptableMilestone = (status, type) => status === MILESTONES_STATUS_IN_PROGRESS
  && type !== MILESTONE_DEDICATED_TEAM_TYPE;

export const isReAcceptableMilestone = (status) => status === MILESTONES_STATUS_CLIENT_ACCEPTED;

export const isNoteReAcceptableMilestone = (status, type) => !(status === MILESTONES_STATUS_CLIENT_ACCEPTED
  && type === MILESTONE_DEDICATED_TEAM_TYPE);

export const getMilestoneStatus = (status, type) => (status === MILESTONES_STATUS_CLIENT_ACCEPTED
  && type === MILESTONE_DEDICATED_TEAM_TYPE ? MILESTONES_STATUS_AUTO_ACCEPTED : status);

export const isFixedMilestoneType = (type) => type === MILESTONE_FP_SHOW_HOURS_RATES
  || type === MILESTONE_FP_HIDE_HOURS_RATES;

export const parseMilestoneItemData = (data) => {
  const parsData = data.client_acceptance_files
    ? data.client_acceptance_files.map((file) => parseAttachedFiles(file)) : [];

  return { ...data, client_acceptance_files: parsData };
};

export default autoCalcPlannedBilledTime;
