export const NOTIFICATIONS_TABS = {
  NEW: 'new',
  READ: 'read'
};

export const NOTIFICATIONS_CHIPS = {
  ALL: 'all',
  VACATION: 'vacation',
  SUBSCRIPTION: 'subscription',
  FINANCE: 'finance',
  MANAGEMENT: 'management',
  OTHER: 'other',
};

export const NOTIFICATIONS_TYPES = {
  VACATION_REQUEST: 'VacationRequest',
  VACATION_RESULT: 'VacationResult',
  VACATION_REMINDER: 'VacationReminder',
  USER_VACATION_REMINDER: 'UserVacationReminder',
  NEW_SUBSCRIPTION: 'NewSubscription',
  RENEWAL_SUBSCRIPTION: 'RenewalSubscription',
  RESULT_SUBSCRIPTION: 'ResultSubscription',
  MANAGER_PROJECT_DOCUMENTATION_REMINDER: 'ManagerProjectDocumentationReminder',
  PROJECT_STATUS_INACTIVE: 'ProjectStatusInactive',
  RECALCULATE_SALARIES: 'RecalculateSalaries',
  SEND_SALARIES_TO_EMAIL: 'SendSalariesToEmail',
  SEND_SALARIES_TO_XERO: 'SendSalariesToXero',
  DEALLOCATE_SALARIES_XERO_OVERPAYMENTS: 'DeallocateSalariesXeroOverpayments',
  ASSET_REMINDER: 'AssetReminder',
  USER_CHANGED_MENTORS: 'UserChangedMentors',
  USER_EVENT_REMINDER: 'UserEventReminder',
  EXPENSE_REPORT: 'ExpenseReport',
  XERO_INVOICES_CREATED: 'XeroInvoicesCreated',
  BUILD_REPORTS_STATS: 'BuildReportStats',
  CHANGED_INVOICE_PAYMENT_METHOD: 'ChangedInvoicePaymentMethod',
};

export const NOTIFICATIONS_TYPES_LINK = {
  [NOTIFICATIONS_TYPES.VACATION_REQUEST]: '/vacation/requests',
  [NOTIFICATIONS_TYPES.VACATION_RESULT]: '/vacation/history',
  [NOTIFICATIONS_TYPES.VACATION_REMINDER]: '/vacation',
  [NOTIFICATIONS_TYPES.USER_VACATION_REMINDER]: '/vacation',
  [NOTIFICATIONS_TYPES.NEW_SUBSCRIPTION]: '/subscriptions',
  [NOTIFICATIONS_TYPES.RENEWAL_SUBSCRIPTION]: '/subscriptions',
  [NOTIFICATIONS_TYPES.RESULT_SUBSCRIPTION]: '/subscriptions',
  [NOTIFICATIONS_TYPES.MANAGER_PROJECT_DOCUMENTATION_REMINDER]: '/projects',
  [NOTIFICATIONS_TYPES.PROJECT_STATUS_INACTIVE]: '/projects',
  [NOTIFICATIONS_TYPES.RECALCULATE_SALARIES]: '/salary/anomalies-report',
  [NOTIFICATIONS_TYPES.SEND_SALARIES_TO_EMAIL]: '/salary/anomalies-report',
  [NOTIFICATIONS_TYPES.SEND_SALARIES_TO_XERO]: '/salary/anomalies-report',
  [NOTIFICATIONS_TYPES.DEALLOCATE_SALARIES_XERO_OVERPAYMENTS]: '/salary/anomalies-report',
  [NOTIFICATIONS_TYPES.ASSET_REMINDER]: '/systems/assets',
  [NOTIFICATIONS_TYPES.USER_CHANGED_MENTORS]: '/employees',
  [NOTIFICATIONS_TYPES.USER_EVENT_REMINDER]: '/employees',
  [NOTIFICATIONS_TYPES.EXPENSE_REPORT]: '/reports/expense-reports',
  [NOTIFICATIONS_TYPES.XERO_INVOICES_CREATED]: '/invoices',
  [NOTIFICATIONS_TYPES.BUILD_REPORTS_STATS]: '',
  [NOTIFICATIONS_TYPES.CHANGED_INVOICE_PAYMENT_METHOD]: '/invoices'
};
