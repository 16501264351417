import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import Image from 'next/image';
import styles from './sass/Avatar.module.scss';

const Avatar = ({
  image = undefined,
  alt = 'avatar',
  title = undefined,
  width = 24,
  height = 24,
}) => {
  const [src, setSrc] = useState(image);

  useEffect(() => {
    if (image) {
      setSrc(image);
    }
  }, [image]);

  return (
    <Image
      src={src || '/images/avatar.svg'}
      alt={alt}
      title={title}
      width={width}
      height={height}
      className={styles.avatar}
      onError={() => setSrc('/images/avatar.svg')}
      style={{
        maxWidth: '100%',
        height: 'auto'
      }}
    />
  );
};

Avatar.propTypes = {
  image: PropTypes.string,
  alt: PropTypes.string,
  title: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default Avatar;
