import { batch, shallowEqual } from 'react-redux';
import { useTranslation } from 'next-i18next';
import { format } from 'date-fns';
import { Fragment, useCallback } from 'react';
import NotificationsListItem from './NotificationsListItem';
import LoadingWrapper from '../../../components/LoadingWrapper/LoadingWrapper';
import NotificationsEmpty from './NotificationsEmpty';
import NotificationsBottom from './NotificationsBottom';
import NotificationsDateSeparator from './NotificationsDateSeparator';
import BallPulse from '../../../components/Loaders/BallPulse';
import useInfiniteScroll from '../../../hooks/useInfiniteScroll';
import { useDispatch, useSelector } from '../../../hooks/reduxToolkit';
import { NOTIFICATIONS_TABS } from '../../../constants/notifications';
import { fetchNotificationsAsync, setQueryParams } from '../../../toolkitStore/notificationsModal/slice';
import { LOADER_COLOR_BLUE } from '../../../constants/common';
import styles from '../sass/NotificationsList.module.scss';

const NotificationsList = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation('common');

  const {
    isLoading,
    isLoadingScroll,
    notificationsData,
    activeTab,
    hasMore,
    meta
  } = useSelector(({ notificationsModal }) => ({
    isLoading: notificationsModal.isLoading,
    isLoadingScroll: notificationsModal.isLoadingScroll,
    notificationsData: notificationsModal.notificationsData,
    activeTab: notificationsModal.activeTab,
    hasMore: notificationsModal.hasMore,
    meta: notificationsModal.meta,
  }), shallowEqual);

  const currentPage = meta?.current_page || 0;

  const isNextMonth = (currenCreatedAt, prevCreatedAt = currenCreatedAt) => {
    return (
      activeTab === NOTIFICATIONS_TABS.READ
      && new Date(currenCreatedAt).getMonth() + 1 !== new Date(prevCreatedAt).getMonth() + 1
    );
  };

  const isShowBottom = notificationsData?.length > 0 && activeTab === NOTIFICATIONS_TABS.NEW;

  const loadMore = useCallback(() => {
    batch(() => {
      dispatch(setQueryParams({ page: currentPage + 1 }));
      dispatch(fetchNotificationsAsync());
    });
  }, [dispatch, currentPage]);

  const lastElementRef = useInfiniteScroll({
    loadMore,
    hasMore,
    isLoading: isLoadingScroll,
  });

  return (
    <LoadingWrapper
      isLoading={isLoading && currentPage === 1}
      hasData={notificationsData?.length > 0}
      classes={{
        wrapper: styles.list,
        emptyList: notificationsData?.length > 0 ? '' : styles.emptyList,
        loader: styles.loader,
      }}
      emptyComponent={(
        <NotificationsEmpty
          emptyDescribe={t('youDontHave')}
          isShowLink={false}
        />
      )}
    >
      <>
        <div className={styles.notifications}>
          {notificationsData?.map((item, index, arr) => (
            <Fragment key={item.id}>
              {isNextMonth(item.created_at, arr[index - 1]?.created_at) && (
                <NotificationsDateSeparator date={format(new Date(item.created_at), 'MMMM yyyy').toUpperCase()} />
              )}
              <NotificationsListItem
                key={item.id}
                item={item}
              />
            </Fragment>
          ))}
          <div ref={lastElementRef} style={{ height: 1 }} />
        </div>
        {isLoadingScroll && <BallPulse className={`${styles.loaderScroll || ''}`} color={LOADER_COLOR_BLUE} />}
        {isShowBottom && <NotificationsBottom /> }
      </>
    </LoadingWrapper>
  );
};

export default NotificationsList;
