export const DEFAULT_BONUS_CATEGORY_NAMES = [
  'Department', 'Delivery: HoD', 'Delivery: Project', 'Delivery: Techleading Old', 'Annual', 'Delivery: Techleading'
];
export const BONUS_CATEGORY_NAMES_REQ_CLIENT = ['Sales', 'Sales External', 'Sales AM'];
export const BONUS_CATEGORY_NAMES_OPT_CLIENT = ['Client', 'Sales Other'];
export const BONUS_CATEGORY_NAMES_REQ_PROJECT = [
  'Delivery: Project', 'Delivery: Techleading', 'Delivery: Techleading Old', 'Delivery: Upsale'
];
export const BONUS_CATEGORY_NAMES_REQ_GPNL_USERS = ['Delivery: Techleading', 'Delivery: Techleading Old'];
export const BONUS_CATEGORY_NAMES_OPT_DEPARTMENT = ['Department', 'Delivery: HoD'];
export const BONUS_CATEGORY_NAMES_OPT_GPNL_MONTH = [
  'Sales', 'Sales External', 'Sales Other', 'Sales AM', 'Delivery: Techleading Old', 'Delivery: Techleading',
  'Delivery: Project', 'Delivery: HoD', 'Delivery: Upsale'
];
