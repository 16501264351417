import { shallowEqual } from 'react-redux';
import IconButton from '../../../components/Button/IconButton';
import { toggleSidebarMobileAction } from '../../../store/sidebar/actions';
import { useDispatch, useSelector } from '../../../hooks/redux';
import styles from '../sass/HeaderNavigation.module.scss';

const HeaderNavigation = () => {
  const dispatch = useDispatch();
  const isMobileOpen = useSelector(({ sidebar }) => sidebar.isMobileOpen, shallowEqual);

  return (
    <div className={styles.navigation}>
      <IconButton
        variant="ghost"
        icon={(
          <div className={`${styles.icon} ${isMobileOpen ? styles.open : ''}`}>
            <span />
          </div>
        )}
        aria-label="navigation"
        onClick={() => {
          dispatch(toggleSidebarMobileAction(!isMobileOpen));
          if (isMobileOpen) {
            document.body.style = undefined;
          } else {
            document.body.style.overflow = 'hidden';
            document.body.style.paddingRight = '15px';
          }
        }}
        ariaLabel="navigation"
      />
    </div>
  );
};

export default HeaderNavigation;
