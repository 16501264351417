import { shallowEqual } from 'react-redux';
import { useCallback } from 'react';
import { toggleSidebarMobileAction } from '../store/sidebar/actions';
import { useDispatch, useSelector } from './redux';
import useMediaQuery from './useMediaQuery';

const useMenuClose = () => {
  const dispatch = useDispatch();
  const isMobile = useMediaQuery('(max-width: 767px)');
  const isMobileOpen = useSelector(({ sidebar }) => sidebar.isMobileOpen, shallowEqual);

  return useCallback(() => {
    if (isMobile && isMobileOpen) {
      dispatch(toggleSidebarMobileAction(false));
      document.body.style = undefined;
    }
  }, [isMobile, isMobileOpen, dispatch]);
};

export default useMenuClose;
