export const SALES_MANAGERS_FETCH_HISTORY = 'SALES_MANAGERS_FETCH_HISTORY';
export const SALES_MANAGERS_TOGGLE_MODAL = 'SALES_MANAGERS_TOGGLE_MODAL';
export const SALES_MANAGERS_TOGGLE_AND_SET_WARNING_MODAL = 'SALES_MANAGERS_TOGGLE_AND_SET_WARNING_MODAL';
export const SALES_MANAGERS_SET_MANAGER_DATA = 'SALES_MANAGERS_SET_MANAGER_DATA';
export const SALES_MANAGERS_SET_MODAL_DATA = 'SALES_MANAGERS_SET_MODAL_DATA';
export const SALES_MANAGERS_SET_MODAL_ERROR = 'SALES_MANAGERS_SET_MODAL_ERROR';
export const SALES_MANAGERS_SET_TOOLTIP = 'SALES_MANAGERS';
export const SALES_MANAGERS_SET_PAGE = 'SALES_MANAGERS_SET_PAGE';
export const SALES_MANAGERS_FETCH_DATA = 'SALES_MANAGERS_FETCH_DATA';
export const SALES_MANAGERS_RESET_MODAL = 'SALES_MANAGERS_RESET_MODAL';
