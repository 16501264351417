const generalSettings = {
  modify(key, fn) {
    this.set(key, fn(this.get(key)));
  },
  appendItemToArray(key, item) {
    this.modify(key, (storage = []) => [...storage, item]);
  },
  removeItemFromArray(key, item, filterKey) {
    this.modify(key, (storage = []) => {
      let tempStorage = [...storage];

      if (filterKey) {
        tempStorage = tempStorage.filter((s) => s[filterKey] !== item[filterKey]);
      } else {
        tempStorage = tempStorage.filter((s) => s !== item);
      }

      return tempStorage;
    });
  },
};

export const localStorageHelper = {
  clear() {
    localStorage.clear();
  },
  get(key) {
    const stored = localStorage.getItem(key);
    return stored === null ? undefined : JSON.parse(stored);
  },
  set(key, value) {
    localStorage.setItem(key, JSON.stringify(value));
  },
  remove(key) {
    localStorage.removeItem(key);
  },
  ...generalSettings,
};

export const sessionStorageHelper = {
  clear() {
    sessionStorage.clear();
  },
  get(key) {
    const stored = sessionStorage.getItem(key);
    return stored === null ? undefined : JSON.parse(stored);
  },
  set(key, value) {
    sessionStorage.setItem(key, JSON.stringify(value));
  },
  remove(key) {
    sessionStorage.removeItem(key);
  },
  ...generalSettings,
};
