// noinspection JSNonASCIINames

export const COMMENT_STATUSES = {
  VERY_GOOD: 'very-good',
  GOOD: 'good',
  WARNING: 'warning',
  BAD: 'bad',
  VERY_BAD: 'very-bad',
};

export const CLIENT_COMMENTS_EMOJI = [
  { status: COMMENT_STATUSES.GOOD, emoji: '✅', ariaLabel: 'Check Mark' },
  { status: COMMENT_STATUSES.WARNING, emoji: '⚠️', ariaLabel: 'Warning' },
  { status: COMMENT_STATUSES.BAD, emoji: '🛑', ariaLabel: 'Stop Sign' },
];

export const EMPLOYEE_COMMENT_EMOJI = [
  { status: COMMENT_STATUSES.VERY_GOOD, emoji: '🔥' },
  { status: COMMENT_STATUSES.GOOD, emoji: '😊' },
  { status: COMMENT_STATUSES.WARNING, emoji: '🤔' },
  { status: COMMENT_STATUSES.BAD, emoji: '😣' },
  { status: COMMENT_STATUSES.VERY_BAD, emoji: '😡' },
];

export const EMPLOYEE_WORK_STATUS_EMOJI = {
  vacation: '🌴',
  sickness: '🤒',
  dayOff: '🌚',
};
