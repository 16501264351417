import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../services/axios';
import { dotsInsideString, getAsyncMatchers, trimStringByCharacter } from '../../utils/helpers';
import { SORT_BY_NAME } from '../../constants/common';

const {
  isPendingAction,
  isFulfilledAction,
  isRejectedAction,
} = getAsyncMatchers('search');

export const initialState = {
  isPending: false,
  error: undefined,
  users: [],
  projects: [],
  tickets: [],
  technologies: [],
  qualities: [],
  departments: [],
  clients: [],
  clientCompanies: [],
  paymentMethods: [],
  clientProducts: [],
  salaryCorrectionTypes: [],
  assetsCategories: [],
  assets: [],
  positions: []
};

export const searchUsers = createAsyncThunk(
  'search/searchUsers',
  async (arg = { filters: {}, or_filters: {}, sort_selected: {} }, { rejectWithValue }) => {
    try {
      const { data, status } = await axios.post('/users/search', { ...arg });

      return status === 200 ? { key: 'users', data: data.data } : rejectWithValue(data);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const searchProjects = createAsyncThunk(
  'search/searchProjects',
  async (arg = { filters: {}, sort_selected: {} }, { rejectWithValue }) => {
    try {
      const { data, status } = await axios.post('/projects/search', { ...arg });

      return status === 200 ? { key: 'projects', data: data.data } : rejectWithValue(data);
    } catch ({ response: { data } }) {
      return rejectWithValue(data);
    }
  }
);

export const searchTickets = createAsyncThunk(
  'search/searchTickets',
  async (arg = { filters: {}, sort_selected: {} }, { rejectWithValue }) => {
    try {
      const { data, status } = await axios.get('/project_tickets/search', { params: arg });

      return status === 200 ? { key: 'tickets', data: data.data } : rejectWithValue(data);
    } catch ({ response: { data } }) {
      return rejectWithValue(data);
    }
  }
);

export const fetchTechnologiesAsync = createAsyncThunk(
  'search/fetchTechnologies',
  async (arg = { filters: {}, with: ['full_path'], sort: '' }, { rejectWithValue }) => {
    try {
      const { data, status } = await axios.get('/technologies', { params: arg });

      const newData = data.map((item) => ({
        ...item,
        skillWay: dotsInsideString(trimStringByCharacter(item.full_path, '/'), 20, 15, 8)
      }));

      return status === 200 ? { key: 'technologies', data: newData } : rejectWithValue(data);
    } catch ({ response: { data } }) {
      return rejectWithValue(data);
    }
  }
);

export const fetchQualitiesAsync = createAsyncThunk(
  'search/fetchQualities',
  async (arg = { filters: {}, sort_selected: {} }, { rejectWithValue }) => {
    try {
      const { data, status } = await axios.get('/cv_qualities', { params: arg });

      return status === 200 ? { key: 'qualities', data } : rejectWithValue(data);
    } catch ({ response: { data } }) {
      return rejectWithValue(data);
    }
  }
);

export const searchDepartments = createAsyncThunk(
  'search/searchDepartments',
  async (arg = { filters: {}, sort_selected: {} }, { rejectWithValue }) => {
    try {
      const params = {
        filters: { activity: true, ...arg.filters },
        sort: SORT_BY_NAME,
        sort_selected: arg.sort_selected,
        with: arg.with
      };
      const { data, status } = await axios.get('/departments', { params });

      return status === 200 ? { key: 'departments', data: data.data } : rejectWithValue(data);
    } catch ({ response: { data } }) {
      return rejectWithValue(data);
    }
  }
);

export const searchClients = createAsyncThunk(
  'search/searchClients',
  async (arg = { filters: {}, sort_selected: {} }, { rejectWithValue }) => {
    try {
      const { data, status } = await axios.post('/clients/search', {
        filters: { show_all: true, ...arg.filters },
        sort_selected: arg.sort_selected,
      });

      return status === 200 ? { key: 'clients', data: data.data } : rejectWithValue(data);
    } catch ({ response: { data } }) {
      return rejectWithValue(data);
    }
  }
);

export const searchClientCompanies = createAsyncThunk(
  'search/searchClientCompanies',
  async ({ filters = {}, sortSelected = {} }, { rejectWithValue }) => {
    try {
      const { data, status } = await axios.post('/client_companies/search', { filters, sort_selected: sortSelected });

      return status === 200 ? { key: 'clientCompanies', data: data.data } : rejectWithValue(data);
    } catch ({ response: { data } }) {
      return rejectWithValue(data);
    }
  }
);

export const searchClientProducts = createAsyncThunk(
  'search/searchClientProduct',
  async (arg = { filters: {}, sort_selected: {} }, { rejectWithValue }) => {
    try {
      const { data, status } = await axios.get('/client_products', {
        params: { sort: 'name', filters: arg.filters, sort_selected: arg.sort_selected }
      });

      return status === 200 ? { key: 'clientProducts', data: data.data } : rejectWithValue(data);
    } catch ({ response: { data } }) {
      return rejectWithValue(data);
    }
  }
);

export const searchSalaryCorrectionTypes = createAsyncThunk(
  'search/searchSalaryCorrectionTypes',
  async (arg = { filters: {}, sortSelected: {} }, { rejectWithValue }) => {
    try {
      const { data, status } = await axios.get(
        '/salary_correction_types',
        { params: { sort: 'name', filters: arg.filters, sort_selected: arg.sortSelected } },
      );

      return status === 200 ? { key: 'salaryCorrectionTypes', data: data.data } : rejectWithValue(data);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const searchAssetsCategories = createAsyncThunk(
  'search/searchAssetsCategories',
  async (arg = { filters: {}, sortSelected: {} }, { rejectWithValue }) => {
    try {
      const { data, status } = await axios.get(
        '/assets_categories',
        { params: { sort: 'name', filters: arg.filters, sort_selected: arg.sortSelected } },
      );

      return status === 200 ? { key: 'assetsCategories', data: data.data } : rejectWithValue(data);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const searchAssets = createAsyncThunk(
  'search/searchAssets',
  async (arg = { type: '', filters: {} }, { rejectWithValue }) => {
    try {
      const { data, status } = await axios.get('/assets/search', { params: arg.filters });

      const newData = data.data.reduce((acc, item) => (item[arg.type] ? [...acc, item[arg.type]] : acc), []);
      const uniqueData = Array.from(new Set(newData));

      return status === 200 ? { key: 'assets', data: uniqueData } : rejectWithValue(data);
    } catch ({ response: { data } }) {
      return rejectWithValue(data);
    }
  }
);

export const searchPaymentMethods = createAsyncThunk(
  'search/searchPaymentMethods',
  async (arg = { filters: {}, sort_selected: {} }, { rejectWithValue }) => {
    try {
      const { data, status } = await axios.get('/payment_methods/search', { params: arg });

      return status === 200 ? { key: 'paymentMethods', data } : rejectWithValue(data);
    } catch ({ response: { data } }) {
      return rejectWithValue(data);
    }
  }
);

export const searchPositions = createAsyncThunk(
  'search/searchPositions',
  async (arg = { filters: {}, sort_selected: {}, sort: 'name' }, { rejectWithValue }) => {
    try {
      const { data, status } = await axios.get('/positions', { params: arg });

      return status === 200 ? { key: 'positions', data: data.data } : rejectWithValue(data);
    } catch ({ response: { data } }) {
      return rejectWithValue(data);
    }
  }
);

export const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    clearSearch: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(isPendingAction, (state) => {
        state.isPending = true;
      })
      .addMatcher(isFulfilledAction, (state, { payload }) => {
        state.isPending = false;
        state[payload.key] = payload.data;
      })
      .addMatcher(isRejectedAction, (state) => {
        state.isPending = false;
      });
  },
});

export const { clearSearch } = searchSlice.actions;

export default searchSlice.reducer;
