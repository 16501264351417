import PropTypes from 'prop-types';
import styles from './sass/FormLabel.module.scss';

const FormLabel = ({
  id = undefined,
  label,
  className = '',
  icon = null,
  isRequired = false,
  baseline = true,
  color = undefined,
  prefix = null,
  fullWidth = false
}) => (
  <div className={`
    ${styles.label} 
    ${fullWidth ? styles.fullWidth : ''}
    ${baseline ? styles.baseline : ''} 
    ${className} ${color ? styles[color] : ''}
  `}
  >
    {icon && (
      <div className={styles.imgWrapper}>
        {icon}
      </div>
    )}
    <label htmlFor={id}>
      {label}
      {isRequired && <span className={styles.required}>*</span>}
    </label>
    {prefix}
  </div>
);

FormLabel.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string.isRequired,
  icon: PropTypes.node,
  prefix: PropTypes.node,
  isRequired: PropTypes.bool,
  baseline: PropTypes.bool,
  className: PropTypes.string,
  color: PropTypes.oneOf(['black']),
  fullWidth: PropTypes.bool,
};

export default FormLabel;
