import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../services/axios';
import { VACATION_DELETE } from '../../constants/types';
import { MAX_FETCH_DATA_PER_PAGE } from '../../constants/values';

const date = new Date().getFullYear();

export const initialState = {
  isLoading: true,
  history: undefined,
  isRequestSubmit: [],
  numberManagersToShow: 0,
  vacationYears: 0,
  moreProps: {
    isOpen: false,
    id: '',
    style: {}
  },
  deleteModalProps: {
    isModalOpen: false,
    modalId: 'deleteVacation',
    isLoading: false,
    currentRequest: {},
  },
  filter: {
    from: date,
    to: date + 1,
  }
};

export const fetchRequestsHistory = createAsyncThunk(
  'vacation-history/history',
  async ({ profileId }, { rejectWithValue, getState }) => {
    const { vacationYears } = getState().vacationHistory;

    const params = {
      page: 1,
      per_page: MAX_FETCH_DATA_PER_PAGE,
      with: ['confirmations.user'],
      filters: {
        worklogs: { vacation_year: vacationYears },
        user: { id: [profileId] },
      }
    };

    try {
      const { data, status } = await axios.get('/vacations', { params });

      return status === 200 ? data.data : rejectWithValue(data.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteRequest = createAsyncThunk(
  'vacation-history/request',
  async (arg, { rejectWithValue }) => {
    try {
      const { data, status } = await axios.delete(`/vacations/${arg}`);

      return status === 204 ? data.data : rejectWithValue(data.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const vacationHistory = createSlice({
  name: 'vacationHistory',
  initialState,
  reducers: {
    resetState: () => initialState,
    setNumberManagersToShow: (state, action) => {
      state.numberManagersToShow = action.payload;
    },
    toggleMore: (state, action) => {
      state.moreProps = action.payload.style ? action.payload : initialState.moreProps;
    },
    toggleDeleteModal: (state, action) => {
      state.deleteModalProps.isModalOpen = action.payload;
    },
    setDeleteModalInfo: (state, action) => {
      state.deleteModalProps.currentRequest = action.payload;
    },
    setVacationYears: (state, action) => {
      state.vacationYears = action.payload.years;
      if (action.payload.startDate) {
        const year = new Date(action.payload.startDate).getFullYear();
        state.filter = {
          from: year + action.payload.years - 1,
          to: year + action.payload.years
        };
      }
    },
    setFilterValue: (state, action) => {
      state.filter = {
        from: action.payload.isIncrement ? state.filter.from + 1 : state.filter.from - 1,
        to: action.payload.isIncrement ? state.filter.to + 1 : state.filter.to - 1,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchRequestsHistory.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchRequestsHistory.fulfilled, (state, action) => {
        state.isLoading = false;
        state.history = action.payload;
      })
      .addCase(fetchRequestsHistory.rejected, (state) => {
        state.isLoading = false;
      });
    builder
      .addCase(deleteRequest.pending, (state, action) => {
        state.deleteModalProps.isLoading = true;
        state.isRequestSubmit.push({ id: action.meta.arg, type: VACATION_DELETE });
      })
      .addCase(deleteRequest.fulfilled, (state, action) => {
        state.isRequestSubmit = state.isRequestSubmit.filter(({ id }) => id !== action.meta.arg);
        state.history = state.history.filter(({ id }) => id !== action.meta.arg);
        state.deleteModalProps.isModalOpen = false;
        state.deleteModalProps.isLoading = false;
      });
  },
});

export const {
  setNumberManagersToShow,
  toggleMore,
  toggleDeleteModal,
  setDeleteModalInfo,
  setFilterValue,
  resetState,
  setVacationYears,
} = vacationHistory.actions;

export default vacationHistory.reducer;
