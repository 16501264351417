import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../services/axios';

export const initialState = {
  isLoading: false,
  data: [],
  meta: {},
  link: {},
  error: undefined,
  query: {
    filters: {
      subject_type: 'Invoice',
      subject_id: '',
    }
  }
};

export const fetchInvoiceHistoryAsync = createAsyncThunk(
  'invoiceHistory/fetchInvoiceHistory',
  async (arg, { rejectWithValue, getState }) => {
    const { query } = getState().invoiceHistory;

    try {
      const { data, status } = await axios.get('/activities', { params: query });

      return status === 200 ? data : rejectWithValue(data.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const invoiceHistorySlice = createSlice({
  name: 'invoiceHistory',
  initialState,
  reducers: {
    setQueryFilters: (state, action) => {
      state.query.filters = { ...state.query.filters, ...action.payload };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchInvoiceHistoryAsync.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchInvoiceHistoryAsync.fulfilled, (state, action) => {
        const { data, meta, links } = action.payload;

        state.isLoading = false;
        state.data = data.filter((item) => item.subject_type === 'Invoice');
        state.meta = meta;
        state.link = links;
      })
      .addCase(fetchInvoiceHistoryAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  }
});

export const { setQueryFilters } = invoiceHistorySlice.actions;

export default invoiceHistorySlice.reducer;
