import { useEffect } from 'react';

const useOnKeydown = (ref, key, handler) => {
  useEffect(() => {
    const listenerKeydown = (event) => {
      if (event.key === key) {
        handler(event);
      }
    };

    document.addEventListener('keydown', listenerKeydown);

    return () => {
      document.removeEventListener('keydown', listenerKeydown);
    };
  }, [ref, handler]);
};

export default useOnKeydown;
