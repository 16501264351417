import { shallowEqual } from 'react-redux';
import { useTranslation } from 'next-i18next';
import Alert from '../Icons/Alert';
import Modal from '../Modal/Modal';
import { deleteReferenceAsync, setDeleteModalData } from '../../toolkitStore/helpCenter/slice';
import { useDispatch, useSelector } from '../../hooks/reduxToolkit';
import styles from './sass/HelpCenterDeleteModal.module.scss';

const HelpCenterDeleteModal = () => {
  const { t } = useTranslation('common');
  const dispatch = useDispatch();
  const { isDeleting, modalProps } = useSelector(({ helpCenter }) => ({
    isDeleting: helpCenter.isDeleting,
    modalProps: helpCenter.helpCenterDeleteModal,
  }), shallowEqual);

  const handleDelete = () => {
    dispatch(deleteReferenceAsync(modalProps.deleteId));
  };

  return (
    <Modal
      id="helpCenterDeleteModal"
      bodyClassName={styles.body}
      modalProps={modalProps}
      buttonTitle={t('delete')}
      buttonAction={handleDelete}
      closeModal={() => dispatch(setDeleteModalData({ isModalOpen: false }))}
      isSubmit={isDeleting}
      buttonActionRed
    >
      <div className={styles.title}>
        <Alert />
        {' '}
        {t('deleteReference')}
      </div>
      <span className={styles.contentWr}>
        {`${t('areYouSure')}`}
        {' '}
        <strong>{modalProps.deleteTitle}</strong>
        {' '}
        {t('fromThisPage')}
        ?
      </span>
    </Modal>
  );
};

export default HelpCenterDeleteModal;
