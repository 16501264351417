import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../services/axios';
import { validateErrors, validateSubscriptionFrom } from '../../utils/validators';
import httpRequestMethods from '../../constants/httpRequestMethods';
import * as httpStatusCodes from '../../constants/httpStatusCodes';
import {
  APPROVE_SUBSCRIPTION,
  DEPARTMENT_TYPE, INDIVIDUAL_TYPE, REJECT_SUBSCRIPTION, REVIEW_SUBSCRIPTION, SUBSCRIPTIONS_TAB, TERM_ONE_TIME
} from '../../constants/subscriptions';
import { LIST_SIZE_PER_PAGE } from '../../constants/values';
import { formatStringDate, getSubscriptionsCount, parseToMonthInt } from '../../utils/helpers';
import {
  STATUS_APPROVED, STATUS_PAID, STATUS_PENDING, STATUS_REJECTED, SUBSCRIPTIONS_STATUSES
} from '../../constants/status';
import { getNewImages, sendFiles } from '../../utils/sendFiles';

const date = new Date();
const fromMonth = parseToMonthInt(date.getFullYear(), 1);
const toMonth = parseToMonthInt(date.getFullYear(), 12);

export const initialState = {
  isLoading: false,
  isMonthlyLoading: false,
  isSubmit: false,
  isDeleteSubmit: false,
  isSearchPending: false,
  countPendingWithoutMy: 0,
  data: [],
  meta: {
    department: {},
    individual: {},
  },
  requestData: [],
  needToPayMeta: {},
  expensesData: {
    subscriptions: [],
  },
  statuses: [STATUS_PAID, STATUS_PENDING, STATUS_APPROVED, STATUS_REJECTED],
  query: {
    filters: {
      name: '',
      department: {
        id: [],
      },
      user: {
        id: [],
      },
      type: {
        type: DEPARTMENT_TYPE,
      },
      status: SUBSCRIPTIONS_STATUSES,
      show_only_expired: false,
      show_own: false,
      month: null,
    },
    with: ['department', 'user', 'reviewer'],
    sort: 'status',
    page: null,
    per_page: LIST_SIZE_PER_PAGE,
  },
  activeTab: SUBSCRIPTIONS_TAB,
  tableFilters: {
    nameInputValue: '',
    isDepartmentOpen: false,
    departmentSearchValue: '',
    employeeSearchValue: '',
    departmentIds: [],
    selectedUsers: [],
  },
  needToPayQuery: {
    filters: {
      name: '',
      department: {
        id: [],
      },
      type: {
        type: DEPARTMENT_TYPE,
      },
      status: [STATUS_APPROVED],
    },
    sort: 'status',
    with: ['department', 'user'],
    page: null,
    per_page: LIST_SIZE_PER_PAGE,
  },
  individualQuery: {
    filters: {
      name: '',
      department: {
        id: [],
      },
      user: {
        id: [],
        search: '',
      },
      type: {
        type: INDIVIDUAL_TYPE,
      },
      status: SUBSCRIPTIONS_STATUSES,
      show_own: false,
      show_for_chief_position: true,
      month: null,
    },
    with: ['department', 'user', 'reviewer'],
    sort: 'status',
    page: null,
    per_page: LIST_SIZE_PER_PAGE,
    month: null,
  },
  individualFilters: {
    selectedUsers: [],
    employeeInputValue: '',
    nameInputValue: '',
    isDepartmentOpen: false,
    departmentSearchValue: '',
    departmentIds: [],
    isEmployeeOpen: false,
    employeeSearchValue: '',
    employeeIds: [],
  },
  subscriptionModal: {
    modalId: 'subscriptionModal',
    isModalOpen: false,
  },
  subscriptionDeleteDialog: {
    modalId: 'subscriptionDeleteDialog',
    isModalOpen: false,
    subscriptionId: '',
    subscriptionName: '',
    isReject: false,
  },
  subscriptionModalDetails: {
    modalId: 'subscriptionModalDetails',
    isModalOpen: false,
  },
  formFields: {
    subscriptionId: '',
    subscriptionName: '',
    planName: '',
    type: INDIVIDUAL_TYPE,
    amount: '',
    approvedAmount: '',
    currency: '',
    currencyRate: '',
    terms: TERM_ONE_TIME,
    isTermsOpen: false,
    isDepartmentOpen: false,
    department: {},
    account: '',
    url: '',
    comment: '',
    images: [],
    isReview: false,
    user: {},
    date,
    rejectComment: '',
  },
  formErrors: {},
  deleteModalId: null,
  datepicker: date,
  monthsPeriod: {
    from: fromMonth,
    to: toMonth,
    startMonth: fromMonth,
    endMonth: toMonth,
  },
  chartData: {
    isOpen: false,
    chartDepartment: {},
    department: {},
  },
  monthlyReport: {
    expandedRowId: null,
    isOpen: false,
  },
  badges: {
    departmentPendingCount: 0,
    departmentApprovedCount: 0,
    individualPendingCount: 0,
    individualApprovedCount: 0,
  },
};

export const fetchSubscriptions = createAsyncThunk(
  'subscriptions/fetchSubscriptions',
  async (arg, { rejectWithValue, getState }) => {
    try {
      const {
        subscriptions: { query },
      } = getState();

      const { data, status } = await axios.get('/subscriptions', { params: query });

      return status === 200 ? data : rejectWithValue(data.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchSubscriptionsPending = createAsyncThunk(
  'subscriptions/fetchSubscriptionsPending',
  async (arg, { rejectWithValue, getState }) => {
    try {
      const {
        subscriptions: {
          needToPayQuery, individualQuery
        },
      } = getState();

      const params = arg.type === DEPARTMENT_TYPE ? needToPayQuery : individualQuery;

      const { data, status } = await axios.get('/subscriptions', { params });

      return status === 200 ? data : rejectWithValue(data.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const createOrEditSubscriptions = createAsyncThunk(
  'subscriptions/createOrEditSubscriptions',
  async ({ id }, { dispatch, rejectWithValue, getState }) => {
    try {
      const {
        formFields, activeTab
      } = getState().subscriptions;

      const errors = validateErrors(
        formFields,
        validateSubscriptionFrom,
        {
          isForDepartment: formFields.type === DEPARTMENT_TYPE,
          isReview: formFields.action === REVIEW_SUBSCRIPTION,
          isApprove: formFields.action === APPROVE_SUBSCRIPTION,
          isUSD: formFields.currency === 'USD'
        }
      );

      if (errors) {
        return rejectWithValue({ isFormValidation: true, errors });
      }

      let method = httpRequestMethods.POST;
      let url = '/subscriptions';
      let newFiles = formFields.images;
      let prevMediaIds = [];

      if (id) {
        method = httpRequestMethods.PUT;
        url = `/subscriptions/${id}`;
        prevMediaIds = formFields.images.filter(({ file }) => !(file instanceof File && file))
          .map(({ file }) => file.id);
      } else {
        newFiles = await getNewImages(newFiles);
      }

      const body = {
        name: formFields.subscriptionName,
        plan: formFields.planName,
        amount: formFields.amount,
        comment: formFields.comment,
        type: formFields.type,
        currency: formFields.currency,
        terms: formFields.terms,
      };

      if (formFields.type === INDIVIDUAL_TYPE) {
        body.terms = TERM_ONE_TIME;
        body.date = formatStringDate(formFields.date, 'yyyy-MM-dd');

        if (newFiles.length > 0) {
          const files = newFiles.filter(({ file }) => file instanceof File && file).map(({ file }) => file);
          let mediaData = {};

          if (files.length > 0) {
            mediaData = await sendFiles(files);

            if (mediaData.error) {
              return rejectWithValue(mediaData);
            }

            body.file_ids = [...prevMediaIds, ...mediaData.data];
          }
        } else {
          body.file_ids = [];
        }
      }

      if (formFields.type === DEPARTMENT_TYPE) {
        body.account = formFields.account;
        body.url = formFields.url;
        body.department_id = formFields.department.id;
      }

      const { data, status } = await axios({
        method,
        url,
        data: body
      });

      if (status === httpStatusCodes.OK || status === httpStatusCodes.CREATED) {
        if (activeTab === SUBSCRIPTIONS_TAB) {
          await dispatch(fetchSubscriptions());
        } else {
          await dispatch(fetchSubscriptionsPending({ type: activeTab }));
        }

        return data;
      }

      return rejectWithValue(data.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const reviewSubscriptions = createAsyncThunk(
  'subscriptions/reviewSubscriptions',
  async ({ action }, { dispatch, rejectWithValue, getState }) => {
    try {
      const {
        subscriptions: {
          formFields, subscriptionDeleteDialog, activeTab
        }
      } = getState();

      const body = {
        status: STATUS_REJECTED
      };

      let id = subscriptionDeleteDialog.subscriptionId;

      if (action !== REJECT_SUBSCRIPTION) {
        const errors = validateErrors(
          formFields,
          validateSubscriptionFrom,
          {
            isForDepartment: formFields.type === DEPARTMENT_TYPE,
            isReview: formFields.action === REVIEW_SUBSCRIPTION,
            isApprove: formFields.action === APPROVE_SUBSCRIPTION,
            isUSD: formFields.currency === 'USD'
          }
        );

        if (errors) {
          return rejectWithValue({ isFormValidation: true, errors });
        }

        id = formFields.subscriptionId;
        body.approved_amount = formFields.approvedAmount;

        if (action === APPROVE_SUBSCRIPTION) {
          body.status = STATUS_APPROVED;
        }

        if (action === REVIEW_SUBSCRIPTION) {
          body.status = STATUS_PAID;
          body.payment_date = formatStringDate(formFields.date, 'yyyy-MM-dd');
          body.currency_rate = formFields.currencyRate;
        }
      } else {
        const errors = validateErrors(formFields, validateSubscriptionFrom, {
          isReject: true,
        });

        if (errors) {
          return rejectWithValue({ isFormValidation: true, errors });
        }

        body.rejected_comment = formFields.rejectComment;
      }

      const { data, status } = await axios.post(
        `/subscriptions/${id}/review`,
        body
      );

      if (status === httpStatusCodes.OK || status === httpStatusCodes.CREATED) {
        if (activeTab === SUBSCRIPTIONS_TAB) {
          dispatch(fetchSubscriptions());
        } else {
          dispatch(fetchSubscriptionsPending({ type: formFields.type }));
        }

        return data;
      }

      return rejectWithValue(data.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteSubscriptions = createAsyncThunk(
  'subscriptions/deleteSubscriptions',
  async ({ id }, { dispatch, rejectWithValue, getState }) => {
    try {
      const {
        subscriptions: { subscriptionDeleteDialog: { type } },
      } = getState();

      const { status, data } = await axios.delete(`/subscriptions/${id}`);

      if (status === httpStatusCodes.NO_CONTENT) {
        if (type === INDIVIDUAL_TYPE) {
          dispatch(fetchSubscriptionsPending({ type }));
        } else {
          dispatch(fetchSubscriptions());
        }
        return data;
      }

      return rejectWithValue(data.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchExpenses = createAsyncThunk(
  'subscriptions/fetchExpenses',
  async (arg, { rejectWithValue, getState }) => {
    try {
      const { query, chartData: { department }, monthsPeriod } = getState().subscriptions;
      const params = {
        filters: {
          start_month: monthsPeriod.startMonth,
          end_month: monthsPeriod.endMonth,
        },
      };

      if (department && department.id) {
        params.filters.department = { id: [department.id] };
      }

      if (arg?.sort) {
        params.sort = query.sort;
      }

      const { data, status } = await axios.get('/subscriptions/report', { params });

      return status === 200 ? data : rejectWithValue(data.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const subscriptionsSlice = createSlice({
  name: 'subscriptions',
  initialState,
  reducers: {
    resetState: () => initialState,
    setQuery: (state, action) => {
      const { key, ...rest } = action.payload;

      state[key] = { ...state[key], ...rest };
    },
    setQueryFilter: (state, action) => {
      const { key, ...rest } = action.payload;

      state[key].filters = { ...state[key].filters, ...rest };
      state[key].page = null;
    },
    setPage: (state, action) => {
      state.query.page = action.payload;
    },
    setTabs: (state, action) => {
      state.activeTab = action.payload;
    },
    setSort: (state, action) => {
      state.query.sort = action.payload;
      state.needToPayQuery.sort = action.payload;
      state.individualQuery.sort = action.payload;
    },
    setTableFilters: (state, action) => {
      state.tableFilters = { ...state.tableFilters, ...action.payload };
    },
    resetAllFilters: (state) => {
      state.query = initialState.query;
      state.individualQuery = initialState.individualQuery;
      state.tableFilters = initialState.tableFilters;
      state.individualFilters = initialState.individualFilters;
    },
    setIndividualFilters: (state, action) => {
      state.individualFilters = { ...state.individualFilters, ...action.payload };
    },
    setValidateFormField: (state, action) => {
      const fieldError = validateSubscriptionFrom(
        action.payload.fieldName,
        action.payload.fieldData,
        action.dependencies
      );

      state.formErrors = {
        ...state.formErrors,
        [action.payload.fieldName]: fieldError,
      };
      state.formFields = {
        ...state.formFields,
        [action.payload.fieldName]: action.payload.fieldData,
      };
    },
    setFormErrors: (state, action) => {
      state.formErrors = action.payload;
    },
    setFormFields: (state, action) => {
      state.formFields = { ...state.formFields, ...action.payload };
    },
    setModalData: (state, action) => {
      state[action.payload.key] = { ...state[action.payload.key], ...action.payload.data };
    },
    checkCountWithoutMy: (state, action) => {
      const { requestData } = state;
      state.countPendingWithoutMy = getSubscriptionsCount(requestData, action.payload.userId);
    },
    resetModal: (state) => {
      state.subscriptionModal = initialState.subscriptionModal;
      state.subscriptionModalDetails = initialState.subscriptionModalDetails;
      state.formErrors = initialState.formErrors;
      state.rejectFormErrors = initialState.rejectFormErrors;
      state.formFields = initialState.formFields;
      state.subscriptionDeleteDialog = initialState.subscriptionDeleteDialog;
    },
    setDatepicker: (state, action) => {
      state.datepicker = action.payload;
    },
    resetDatepicker: (state) => {
      state.datepicker = initialState.datepicker;
    },
    setMonthPeriodData: (state, action) => {
      state.monthsPeriod = { ...state.monthsPeriod, ...action.payload };
      if (!action.payload.from) {
        state.monthsPeriod.from = fromMonth;
      }
      if (!action.payload.to) {
        state.monthsPeriod.to = toMonth;
      }
    },
    setChartData: (state, action) => {
      state.chartData = { ...state.chartData, ...action.payload };
    },
    setMonthlyReport: (state, action) => {
      state.monthlyReport = { ...state.monthlyReport, ...action.payload };
    },
    resetMonthPeriod: (state) => {
      state.monthsPeriod = initialState.monthsPeriod;
    },
    setData: (state, action) => {
      return ({ ...state, ...action.payload });
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSubscriptions.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchSubscriptions.fulfilled, (state, action) => {
        const {
          department: {
            count_approved: departmentApprovedCount,
            count_pending: departmentPendingCount
          },
          individual: {
            count_approved: individualApprovedCount,
            count_pending: individualPendingCount
          }
        } = action.payload.meta;

        state.isLoading = false;
        state.data = action.payload.data;
        state.meta = action.payload.meta;
        state.badges = {
          departmentPendingCount,
          departmentApprovedCount,
          individualPendingCount,
          individualApprovedCount
        };
      })
      .addCase(fetchSubscriptions.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
    builder
      .addCase(fetchSubscriptionsPending.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchSubscriptionsPending.fulfilled, (state, action) => {
        const {
          department: {
            count_approved: departmentApprovedCount,
            count_pending: departmentPendingCount
          },
          individual: {
            count_approved: individualApprovedCount,
            count_pending: individualPendingCount
          }
        } = action.payload.meta;

        state.isLoading = false;
        state.requestData = action.payload.data;
        state.needToPayMeta = action.payload.meta;
        state.badges = {
          departmentPendingCount,
          departmentApprovedCount,
          individualPendingCount,
          individualApprovedCount
        };
      })
      .addCase(fetchSubscriptionsPending.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
    builder
      .addCase(createOrEditSubscriptions.pending, (state) => {
        state.isSubmit = true;
      })
      .addCase(createOrEditSubscriptions.fulfilled, (state) => {
        state.isSubmit = false;
        state.subscriptionModal = initialState.subscriptionModal;
        state.formFields = initialState.formFields;
        state.formErrors = initialState.formErrors;
      })
      .addCase(createOrEditSubscriptions.rejected, (state, action) => {
        if (action.payload.isFormValidation) {
          state.formErrors = action.payload.errors;
        }
        state.isSubmit = false;
      });
    builder
      .addCase(reviewSubscriptions.pending, (state) => {
        state.isSubmit = true;
        state.isDeleteSubmit = true;
      })
      .addCase(reviewSubscriptions.fulfilled, (state) => {
        state.isSubmit = false;
        state.isDeleteSubmit = false;
        state.subscriptionModal = initialState.subscriptionModal;
        state.subscriptionDeleteDialog = initialState.subscriptionDeleteDialog;
        state.formFields = initialState.formFields;
        state.formErrors = initialState.formErrors;
      })
      .addCase(reviewSubscriptions.rejected, (state, action) => {
        if (action.payload.isFormValidation) {
          state.formErrors = action.payload.errors;
        }
        state.isSubmit = false;
        state.isDeleteSubmit = false;
      });
    builder
      .addCase(deleteSubscriptions.pending, (state) => {
        state.isDeleteSubmit = true;
      })
      .addCase(deleteSubscriptions.fulfilled, (state) => {
        state.isDeleteSubmit = false;
        state.deleteModalId = null;
        state.subscriptionDeleteDialog.isModalOpen = false;
      })
      .addCase(deleteSubscriptions.rejected, (state) => {
        state.isDeleteSubmit = false;
      });
    builder
      .addCase(fetchExpenses.pending, (state, action) => {
        if (action.meta.arg?.sort) {
          state.isMonthlyLoading = true;
        } else {
          state.isLoading = true;
        }
      })
      .addCase(fetchExpenses.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isMonthlyLoading = false;
        state.expensesData = action.payload;
      })
      .addCase(fetchExpenses.rejected, (state, action) => {
        state.isLoading = false;
        state.isMonthlyLoading = false;
        state.error = action.payload;
      });
  },
});

export const {
  resetState,
  setQueryFilter,
  setQuery,
  setPage,
  setTabs,
  setSort,
  setTableFilters,
  setIndividualFilters,
  setFormErrors,
  setFormFields,
  setValidateFormField,
  setModalData,
  setData,
  resetModal,
  setDatepicker,
  resetDatepicker,
  setChartData,
  setMonthlyReport,
  setMonthPeriodData,
  resetMonthPeriod,
  resetAllFilters,
  checkCountWithoutMy,
} = subscriptionsSlice.actions;

export default subscriptionsSlice.reducer;
