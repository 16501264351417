import {
  INVOICE_EDIT_FETCH_DATA, INVOICE_EDIT_SET_DATA, INVOICE_EDIT_FETCH_CATEGORIES, INVOICE_EDIT_SET_MODAL_DATA,
  INVOICE_EDIT_SET_LOG_ERROR, INVOICE_EDIT_CLEAR
} from './types';

export const initialState = {
  invoice: null,
  invoiceName: '',
  invoiceComment: '',
  items: [],
  categories: [],
  newRows: [],
  editInvoiceModal: {
    isModalOpen: false,
    modalId: 'editInvoiceModal',
    isMerge: false,
  },
  modalErrors: {}
};

export default function invoiceEdit(state = initialState, action) {
  switch (action.type) {
    case INVOICE_EDIT_CLEAR:
      return initialState;
    case INVOICE_EDIT_SET_MODAL_DATA:
      return {
        ...state,
        editInvoiceModal: {
          ...state.editInvoiceModal,
          ...action.data
        },
      };
    case INVOICE_EDIT_FETCH_CATEGORIES:
      return {
        ...state,
        categories: action.categories,
      };
    case INVOICE_EDIT_FETCH_DATA:
      return {
        ...state,
        invoice: action.invoice,
        invoiceName: action.invoiceName,
        invoiceComment: action.invoiceComment,
        items: action.items,
      };
    case INVOICE_EDIT_SET_DATA:
      return {
        ...state,
        ...action.data
      };
    case INVOICE_EDIT_SET_LOG_ERROR:
      return {
        ...state,
        modalErrors: action.modalErrors,
      };
    default:
      return state;
  }
}
