export const SALARY_FETCH_DATA = 'SALARY_FETCH_DATA';
export const SALARY_SET_PAGE = 'SALARY_SET_PAGE';
export const SALARY_SET_ABORT = 'SALARY_SET_ABORT';
export const SALARY_SET_QUERY_PARAMS = 'SALARY_SET_QUERY_PARAMS';
export const SALARY_SET_PAGE_FILTER_DATA = 'SALARY_SET_PAGE_FILTER_DATA';
export const SALARY_SET_SORT = 'SALARY_SET_SORT';
export const SALARY_RESET_STATE = 'SALARY_RESET_STATE';
export const SALARY_SET_TABLE_CHECKED = 'SALARY_SET_TABLE_CHECKED';
export const SALARY_RESET_MODAL = 'SALARY_RESET_MODAL';
export const SALARY_SET_MODAL_DATA = 'SALARY_SET_MODAL_DATA';
export const SALARY_FETCH_ENUM = 'SALARY_FETCH_ENUM';
export const SALARY_FETCH_NUMBER_DEBTORS = 'SALARY_FETCH_NUMBER_DEBTORS';
export const SALARY_DATA_UPDATE = 'SALARY_DATA_UPDATE';
export const SALARY_SET_TIMESHEET = 'SALARY_SET_TIMESHEET';
export const SALARY_SET_EXPANDED_ROW = 'SALARY_SET_EXPANDED_ROW';
export const SALARY_SET_IS_PENDING = 'SALARY_SET_IS_PENDING';
export const SALARY_SET_EXPANDED = 'SALARY_SET_EXPANDED';
export const SALARY_SET_TABLE_HEAD_INPUT = 'SALARY_SET_TABLE_HEAD_INPUT';
export const SALARY_SET_SUBMIT = 'SALARY_SET_SUBMIT';
export const SALARY_FETCH_META = 'SALARY_FETCH_META';
export const SALARY_RESET_QWERY_PARAMS = 'SALARY_RESET_QWERY_PARAMS';
export const SALARY_SET_IS_LOADING = 'SALARY_SET_IS_LOADING';
