import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../services/axios';
import { getPeriodInvoice } from '../../utils/invoices';
import { getParseComment, parseBillingStatistic } from '../../utils/helpers';
import { SORT_BY_START_DATE } from '../../constants/common';

export const initialState = {
  isLoading: true,
  isInvoiceLoading: true,
  isLoadingComments: false,
  isLoadingReports: false,
  isLoadingPipedrive: false,
  isSubmit: false,
  error: undefined,
  clientStatistic: {
    billing: [],
  },
  invoiceData: [],
  commentsData: [],
  reportsData: [],
  reportsQuestions: {},
  notesData: [],
  meta: {},
  query: {
    filters: {
      show_own: false,
      start_date: '',
      end_date: '',
      client: { id: [], name: '' },
      project: { id: '', name: '' },
      manager: { name: '' },
    },
    with: ['project', 'manager'],
    sort: `-${SORT_BY_START_DATE}`,
    page: null,
  },
  invoiceCommentModal: {
    modalId: 'invoiceCommentModal',
    isModalOpen: false,
    projectId: '',
    startDate: '',
    endDate: '',
    invoiceComments: [],
    clientCompanyId: '',
    paymentMethodId: '',
    preferredBillingPeriod: '',
  },
};

export const fetchClientStatistic = createAsyncThunk(
  'clientFeed/fetchClientStatistic',
  async (arg, { rejectWithValue, getState }) => {
    try {
      const { clientFeed: { query: { filters } } } = getState();
      const { data, status } = await axios.get(`clients/${filters.client.id}/statistic`);

      const newData = { ...data, billing: parseBillingStatistic(data.billing) };

      return status === 200 ? newData : rejectWithValue(data.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchClientInvoicesAsync = createAsyncThunk(
  'clientFeed/fetchClientInvoicesAsync',
  async (arg, { rejectWithValue, getState }) => {
    try {
      const { clientFeed: { query } } = getState();
      const { data, status } = await axios.get('/recommended_invoices', { params: query });

      return status === 200 ? data : rejectWithValue(data.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchCommentsAsync = createAsyncThunk(
  'clientFeed/fetchCommentsAsync',
  async ({ params }, { rejectWithValue }) => {
    try {
      const { data, status } = await axios.get('model_comments', { params });

      return status === 200 ? data : rejectWithValue(data.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchClientHrmReportsAsync = createAsyncThunk(
  'clientFeed/fetchClientHrmReportsAsync',
  async (arg, { rejectWithValue, getState }) => {
    try {
      const { clientFeed: { query: { filters } } } = getState();

      const { data, status } = await axios.get(`/clients/${filters.client.id}/hrm_reports`);
      return status === 200 ? data : rejectWithValue(data.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchClientPipedriveNotesAsync = createAsyncThunk(
  'clientFeed/fetchClientPipedriveNotesAsync',
  async (arg, { rejectWithValue, getState }) => {
    try {
      const { clientFeed: { query: { filters } } } = getState();

      const {
        data: { notes, users, message },
        status
      } = await axios.get(`/clients/${filters.client.id}/pipedrive_notes`);

      const notesData = notes.map((item) => {
        const { user, deal } = item;

        const dealTitle = deal.title.split(':')[1];
        const userNote = users.find(({ email }) => email === user.email) || { ...user, id: user.id.toString() };

        return { ...item, deal: { ...deal, title: dealTitle }, user: userNote };
      });

      return status === 200 ? notesData : rejectWithValue(message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const clientFeedSlice = createSlice({
  name: 'clientFeed',
  initialState,
  reducers: {
    resetState: () => initialState,
    setQueryFilter: (state, action) => {
      state.query.filters = { ...state.query.filters, ...action.payload };
    },
    setQuery: (state, action) => {
      state.query = { ...state.query, ...action.payload };
    },
    setModalData: (state, action) => {
      state.invoiceCommentModal = { ...state.invoiceCommentModal, ...action.payload };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchClientStatistic.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchClientStatistic.fulfilled, (state, action) => {
        state.isLoading = false;
        state.clientStatistic = action.payload;
        state.meta = action.payload.meta;
      })
      .addCase(fetchClientStatistic.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
    builder
      .addCase(fetchClientInvoicesAsync.pending, (state) => {
        state.isInvoiceLoading = true;
      })
      .addCase(fetchClientInvoicesAsync.fulfilled, (state, action) => {
        const data = action.payload.data.map((item) => ({
          ...item,
          period: getPeriodInvoice(item.start_date, item.end_date)
        }));

        state.isInvoiceLoading = false;
        state.invoiceData = data;
        state.meta = action.payload.meta;
      })
      .addCase(fetchClientInvoicesAsync.rejected, (state, action) => {
        state.isInvoiceLoading = false;
        state.error = action.payload;
      });
    builder
      .addCase(fetchCommentsAsync.pending, (state) => {
        state.isLoadingComments = true;
      })
      .addCase(fetchCommentsAsync.fulfilled, (state, action) => {
        if (action.payload?.length > 0) {
          const commentableType = action.payload[0].commentable_type;

          if (commentableType === 'Client' || commentableType === 'Project') {
            state.commentsData = action.payload?.map((item) => {
              const images = item.files?.map((file) => ({ file, src: file.original_url, name: file.name })) || [];

              return { ...item, images };
            });
          } else {
            state.invoiceCommentModal.invoiceComments = action.payload;
          }
        }

        state.isLoadingComments = false;
        state.meta = action.payload.meta;
      })
      .addCase(fetchCommentsAsync.rejected, (state, action) => {
        state.isLoadingComments = false;
        state.error = action.payload;
      });
    builder
      .addCase(fetchClientHrmReportsAsync.pending, (state) => {
        state.isLoadingReports = true;
      })
      .addCase(fetchClientHrmReportsAsync.fulfilled, (state, action) => {
        const {
          users, projects, hrm_report: hrmReport, hrm_questions: hrmQuestions
        } = action.payload;

        const data = hrmReport.map((item) => {
          const project = projects.find(({ id }) => id === item.project_id);
          const user = users.find(({ id }) => id === item.employee_id);

          const comments = item.comments.map((comment) => {
            const userSubComments = users.find(({ id }) => id === comment.employee_id);

            return {
              ...comment, user: userSubComments, project, comment: <span>{getParseComment(comment)}</span>
            };
          });

          return {
            ...item, project, user, comments
          };
        });

        state.isLoadingReports = false;
        state.reportsData = data || [];
        state.reportsQuestions = hrmQuestions || {};
        state.meta = action.payload.meta;
      })
      .addCase(fetchClientHrmReportsAsync.rejected, (state, action) => {
        state.isLoadingReports = false;
        state.error = action.payload;
      });
    builder
      .addCase(fetchClientPipedriveNotesAsync.pending, (state) => {
        state.isLoadingPipedrive = true;
      })
      .addCase(fetchClientPipedriveNotesAsync.fulfilled, (state, action) => {
        state.isLoadingPipedrive = false;
        state.notesData = action.payload || [];
        state.meta = action.payload.meta;
      })
      .addCase(fetchClientPipedriveNotesAsync.rejected, (state, action) => {
        state.isLoadingPipedrive = false;
        state.error = action.payload;
      });
  },
});

export const {
  resetState,
  setQueryFilter,
  setQuery,
  setModalData
} = clientFeedSlice.actions;

export default clientFeedSlice.reducer;
