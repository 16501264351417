import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../services/axios';
import { groupBy, sortBy } from '../../utils/arrayHelpers';

export const initialState = {
  isLoading: true,
  isSubmit: false,
  error: undefined,
  permissions: [],
  query: {
    filters: {
      guard_name: '',
      name: '',
      group: '',
      role: {
        id: [],
      },
      user: {
        search: '',
      }
    },
  },
  expandedGroup: null,
  modalProps: {
    modalId: 'permissionsModal',
    isModalOpen: false,
    permissionId: null,
  },
  fields: {
    name: '',
    guardName: '',
    description: '',
  },
  isTypeOpen: false,
};

export const fetchPermissionsAsync = createAsyncThunk(
  'systemsPermissions/fetchPermissions',
  async (arg, { getState, rejectWithValue }) => {
    try {
      const { query } = getState().systemsPermissions;
      const { data, status } = await axios.get('/permissions', { params: query });

      return status === 200 ? groupBy(sortBy(data, 'group'), 'group') : rejectWithValue(data.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const editPermissionAsync = createAsyncThunk(
  'systemsPermissions/editPermission',
  async ({ permissionId }, { getState, rejectWithValue }) => {
    try {
      const { fields } = getState().systemsPermissions;
      const { data, status } = await axios.put(`/permissions/${permissionId}`, { description: fields.description });

      return status === 200 ? data : rejectWithValue(data.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const systemsPermissionsSlice = createSlice({
  name: 'systemsPermissions',
  initialState,
  reducers: {
    resetState: () => initialState,
    setQueryFilter: (state, action) => {
      state.query.filters = { ...state.query.filters, ...action.payload };
    },
    setQuery: (state, action) => {
      state.query = { ...state.query, ...action.payload };
    },
    setModalData: (state, action) => {
      state.modalProps = { ...state.modalProps, ...action.payload };
    },
    setFieldsData: (state, action) => {
      state.fields = { ...state.fields, ...action.payload };
    },
    setData: (state, action) => {
      return ({ ...state, ...action.payload });
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPermissionsAsync.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchPermissionsAsync.fulfilled, (state, action) => {
        state.permissions = action.payload;
        state.isLoading = false;
      })
      .addCase(fetchPermissionsAsync.rejected, (state, action) => {
        state.error = action.payload;
        state.isLoading = false;
      })
      .addCase(editPermissionAsync.pending, (state) => {
        state.isSubmit = true;
      })
      .addCase(editPermissionAsync.fulfilled, (state, action) => {
        const findPermissionIndex = state.permissions[action.payload.group]
          .findIndex(({ id }) => id === action.payload.id);

        state.permissions[action.payload.group][findPermissionIndex] = {
          ...state.permissions[action.payload.group][findPermissionIndex],
          ...action.payload
        };
        state.isSubmit = false;
        state.modalProps = initialState.modalProps;
        state.fields = initialState.fields;
      })
      .addCase(editPermissionAsync.rejected, (state, action) => {
        state.error = action.payload;
        state.isSubmit = false;
      });
  },
});

export const {
  resetState,
  setQueryFilter,
  setQuery,
  setData,
  setModalData,
  setFieldsData,
} = systemsPermissionsSlice.actions;

export default systemsPermissionsSlice.reducer;
